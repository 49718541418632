import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IColor, IProductColorRequest } from '../models/models'

export class AddColorCodeApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/color/insertColorCode',
      requesterInfo,
    })
  }

  public async addColor(request: IColor): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
