import React, { useEffect, useMemo } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { Link, useNavigate } from 'react-router-dom'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import IPButton from '../../../../../components/IPButton'
import { css } from '@emotion/css'
import IPModal from '../../../../../components/IPModal'
import { useCustomReducer } from '../../../../../base/customHooks'
import { IInsatallments, IParamPosInsatallment, IParamPosInsatallmentHistory } from '../models/models'
import { ListParamPosInsatallmentApi } from '../services/listParamPosInsatallmentApi'
import Swal from 'sweetalert2'
import { DeleteByIdParamPosInsatallmentApi } from '../services/deleteByIdParamPosInsatallmentApi'
import { ListParamPosInsatallmentHistoryApi } from '../services/listParamPosInsatallmentHistoryApi'
import { DeleteParamPosInsatallmentApi } from '../services/deleteParamPosInsatallmentApi'
import Table from '../../../../../components/Table'

interface IParamposListState {
  date: string
  paramPosInsatallmentList: IParamPosInsatallment[]
  paramPosInsatallment: IInsatallments[]
  showDetail: boolean
  showHistoryDetail: boolean
  paramPosInsatallmentHistoryList: IParamPosInsatallmentHistory[]
  guid: string
}
function ParamposList(props: BaseComponentWithContextProps) {
  let navigate = useNavigate()
  const t = translate(Translations)

  const getInitialState = (): IParamposListState => {
    return {
      date: new Date().toLocaleString('tr-TR'),
      paramPosInsatallmentList: [],
      paramPosInsatallment: [],
      showDetail: false,
      showHistoryDetail: false,
      paramPosInsatallmentHistoryList: [],
      guid: ""
    }
  }

  useEffect(() => {
    listParamPosInsatallment();
  }, [])

  const initialState = getInitialState()
  const [state, setState] = useCustomReducer<IParamposListState>(initialState)

  const listParamPosInsatallment = async () => {
    props.showLoading();
    const listParamPosInsatallmentApi: ListParamPosInsatallmentApi = new ListParamPosInsatallmentApi({})

    listParamPosInsatallmentApi
      .listParamPosInsatallment()
      .then((res: any) => {
        props.hideLoading();
        setState({ paramPosInsatallmentList: res })
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading();
      })
  }

  const deleteByIdParamPosInsatallment = async (id: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      props.showLoading();
      const deleteByIdParamPosInsatallmentApi: DeleteByIdParamPosInsatallmentApi = new DeleteByIdParamPosInsatallmentApi({})

      deleteByIdParamPosInsatallmentApi
        .deleteByIdParamPosInsatallment(id)
        .then((res: any) => {
          props.hideLoading();
          Swal.fire({
            icon: 'success',
            title: res,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          const paramPosInsatallment: IInsatallments[] = state.paramPosInsatallment.filter((ins) => { return ins.Id !== id })
          let paramPosInsatallmentList: IParamPosInsatallment[] = [];
          if (paramPosInsatallment.length > 0) {
            state.paramPosInsatallmentList.map((list) => {
              if (list.Guid === state.guid)
                list.Insatallments = paramPosInsatallment;
              paramPosInsatallmentList.push(list)
            })
          } else {
            paramPosInsatallmentList = state.paramPosInsatallmentList.filter((fil) => { return fil.Guid !== state.guid })
            setState({ showDetail: false })
          }
          setState({ paramPosInsatallmentList, paramPosInsatallment })
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading();
        })
    })
  }

  const listParamPosInsatallmentHistory = async (guid: string) => {
    props.showLoading();
    const listParamPosInsatallmentHistoryApi: ListParamPosInsatallmentHistoryApi = new ListParamPosInsatallmentHistoryApi({})

    listParamPosInsatallmentHistoryApi
      .listParamPosInsatallmentHistory(guid)
      .then((res: IParamPosInsatallmentHistory[]) => {
        props.hideLoading();
        setState({ showHistoryDetail: true, paramPosInsatallmentHistoryList: res })
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading();
      })
  }
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
      },
      {
        Header: 'Banka',
        accessor: 'col2',
      },
      {
        Header: 'Detay',
        accessor: 'col3',
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'col4',
      },
      {
        Header: 'Bitiş Tarihi',
        accessor: 'col5',
      },
      {
        Header: 'Değişiklikler',
        accessor: 'col6',
      },
      {
        Header: 'İşlemler',
        accessor: 'col7',
      },
    ],
    []
  )

  const deleteParamPosInsatallment = async (guid: string) => {
    Swal.fire({
      icon: 'question',
      title: 'Silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        props.showLoading();
        const deleteParamPosInsatallmentApi: DeleteParamPosInsatallmentApi = new DeleteParamPosInsatallmentApi({})
        deleteParamPosInsatallmentApi
          .deleteParamPosInsatallment(guid)
          .then((res: any) => {
            props.hideLoading();
            Swal.fire({
              icon: 'success',
              title: res,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            const paramPosInsatallmentList = state.paramPosInsatallmentList.filter((fil) => { return fil.Guid !== guid })
            setState({ paramPosInsatallmentList })
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading();
          })
      }
    })
  }

  const tableData = useMemo(() =>
    state.paramPosInsatallmentList.map((ins, index) => {
      const data = {
        col1: index + 1,
        col2: ins.Insatallments[0].BankName,
        col3: (
          <IPButton
            text='Detay'
            onClick={() => {
              setState({ showDetail: true, paramPosInsatallment: ins.Insatallments, guid: ins.Guid })
            }}
            type='button'
            style={{ padding: '0px', width: '120px', height: '30px', color: 'white', borderColor: 'red' }}
            className='btn btn-danger'
          ></IPButton>
        ),
        col4: new Date(ins.StartDate).toLocaleString('tr-TR'),
        col5: new Date(ins.EndDate).toLocaleString('tr-TR'),
        col6: (
          <IPButton
            text='Tarihçe'
            onClick={() => {
              listParamPosInsatallmentHistory(ins.Guid)
            }}
            type='button'
            style={{ padding: '0px', width: '120px', height: '30px', color: 'white', borderColor: 'red' }}
            className='btn btn-danger'
          ></IPButton>
        ),
        col7: (
          <>
            <a
              data-action-type='edit'
              role='button'
              className={css`
                cursor: pointer;
              `}
              data-toggle='tooltip'
              data-original-title={t('pages.mySpecial.userUpdate.edit')}
              onClick={() => {
                navigate('/admin/BankAndInstallementOperation/Parampos/Parampos', {
                  state: { installementData: ins, flag: 'update' },
                })
              }}
            >
              <i
                className={`${css`
                  font-size: 1.2rem;
                  color: green;
                `} icon-edit`}
              ></i>
            </a>
            <a
              onClick={() => deleteParamPosInsatallment(ins.Guid)}
              data-action-type='delete'
              role='button'
              className={css`
                cursor: pointer;
              `}
              data-toggle='tooltip'
              data-original-title={t('pages.mySpecial.userUpdate.delete')}
            >
              <i
                className={`${css`
                  font-size: 1.2rem;
                  color: red;
                `} icon-trash-2`}
              ></i>
            </a>
          </>
        ),
      }
      return data
    })
    , [state.paramPosInsatallmentList])

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <div className='col-12 col-md-9 mb-2'>
              <span className='h4'>Parampos Taksit Listeleme</span>
            </div>
            <div className='col-12 col-md-2 mb-2'>
              <div className='buttonsuz'>
                <Link to='/admin/BankAndInstallementOperation/Parampos/Parampos'>
                  <IPButton
                    text={t('pages.admin.bankAndInstallementOperation.bankCrud.bankUpdateDel.add')}
                    className='btn btn-primary'
                    style={{ height: '45px', marginLeft: '15px' }}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-lg-12'>
                <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4' style={{ padding: '10px' }}>
                  <div className='row'>
                    <div className='col-sm-12 pr-4' style={{ overflowX: 'auto' }}>
                      <Table columns={columns} data={tableData} currentPage={0} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <IPModal
        show={state.showHistoryDetail}
        onClose={() => setState({ showHistoryDetail: false })}
        hideConfirm={true}
        width={1200}
        title='Parampos Taksit Tarihçe'
      >
        <table
          id='datatable'
          className='table table-bordered table-hover dataTable'
          role='grid'
          aria-describedby='datatable_info'
        >
          <thead className='tableodd'>
            <tr role='row'>
              <th>#</th>
              <th>Değişiklik Tarihi</th>
              <th>Başlangıç Tarihi</th>
              <th>Bitiş Tarihi</th>
              <th>Taksit</th>
              <th>Vade</th>
              <th>Kullanıcı</th>
              <th>İşlem Detayı</th>
            </tr>
          </thead>
          <tbody>
            {
              state.paramPosInsatallmentHistoryList && state.paramPosInsatallmentHistoryList.length > 0 && state.paramPosInsatallmentHistoryList
                .map((history, index) => (
                  <tr role='row' className='odd'>
                    <td>{index + 1}</td>
                    <td>{new Date(history.CreatedDate).toLocaleString('tr-TR')}</td>
                    <td>{new Date(history.StartDate).toLocaleString('tr-TR')}</td>
                    <td>{new Date(history.EndDate).toLocaleString('tr-TR')}</td>
                    <td>{history.Installment}</td>
                    <td>{history.InterestRate}</td>
                    <td>{history.UserName}</td>
                    <td>{history.ProcessInformation}</td>
                  </tr>
                ))
            }
          </tbody>
        </table>
      </IPModal>
      <IPModal
        show={state.showDetail}
        onClose={() => setState({ showDetail: false })}
        hideConfirm={true}
        width={1000}
        title='Banka Taksit Detay'
      >
        <table
          id='datatable'
          className='table table-bordered table-hover dataTable'
          role='grid'
          aria-describedby='datatable_info'
        >
          <thead className='tableodd'>
            <tr role='row'>
              <th>Taksit</th>
              <th>Vade(max)</th>
              <th>İşlem</th>
            </tr>
          </thead>
          <tbody>
            {
              state.paramPosInsatallment && state.paramPosInsatallment.length > 0 && state.paramPosInsatallment.map((installment, index) => (
                <tr role='row' className='odd' key={installment.Id}>
                  <td>{installment.Installment}</td>
                  <td>{installment.InterestRate}</td>
                  <td>
                    <a onClick={() => { deleteByIdParamPosInsatallment(installment.Id) }}>
                      <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red', cursor: 'pointer' }} ></i>
                    </a>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </IPModal>
    </div>
  )
}
export default componentWithContext(ParamposList)
