import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
export class OrderPermApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/cardNumberPerm',
      requesterInfo,
    })
  }

  public async checkOrderPerm(): Promise<boolean> {
    return await this.getAsync<boolean>()
  }
}
