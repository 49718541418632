import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useClickOutsideEvent } from '../../../base/customHooks/useClickOutsideEvent'
import useDebounce from '../../../base/customHooks/useDebounce'
import { useAppDispatch } from '../../../redux/app/hooks'
import { setSearchHistory } from '../../../redux/slices/searchHistory/searchHistorySlice'
import { IProductSearchResponse } from '../models/response/getProductListResponseModel'
import { SearchRequest, SearchProductsApi } from '../services/searchProductsByCategoriesAndCompaniesAllDataApi'

interface ISearchInputProps {
  result?: any[]
  onChange?: (text: string) => void
  onOpen?: () => void
  onClose?: () => void
  onClick?: (text: string) => void
  isSearchActive?: boolean
  setSearchMessage: React.Dispatch<React.SetStateAction<string>>
  children?: React.ReactNode
  handleSearchCompleted?: (result: any) => void
  searchValue?: string
  selectedCategories: number[]
  selectedCompanies: number[]
}

const SEARCH_MIN_CHAR_COUNT: number = 2

function SearchInput(props: ISearchInputProps) {
  const {
    selectedCategories,
    selectedCompanies,
    setSearchMessage,
    handleSearchCompleted,
    searchValue,
    onChange,
    onClose,
  } = props

  let searchInputRef: any = useRef() //TO DO
  const navigate: any = useNavigate()
  const searchFormRef = useRef<any>()

  const [clickOut] = useClickOutsideEvent(searchFormRef)
  // const isClickedEnter = useKeyPress('Enter');
  const [isClickedEnterOnInput, setIsClickedEnterOnInput] = useState(false)

  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 1000)
  const [results, setResults] = useState<IProductSearchResponse[]>([])

  const dispatch = useAppDispatch()

  const searchByCategoriesAndCompaniesApi: SearchProductsApi = new SearchProductsApi({})

  useEffect(() => {
    if (clickOut) {
      searchInputRef.current.value = ''
      setSearchTerm('')
      onChange && onChange('')

      onClose && onClose()
      setResults([])
      handleSearchCompleted && handleSearchCompleted([])
    } else {
      // props.onOpen && props.onOpen()
    }
  }, [clickOut])

  useEffect(() => {
    if (isClickedEnterOnInput && searchTerm && searchTerm.length >= SEARCH_MIN_CHAR_COUNT && results) {
      setIsClickedEnterOnInput(false)
      onClose && onClose()
      searchInputRef.current.blur()

      let navigateRoute: string = `/products?searchKey=${searchTerm.trim()}`

      if (selectedCategories && selectedCategories.length > 0) {
        navigateRoute = `${navigateRoute}&categoryId=${encodeURIComponent(selectedCategories.toString())}`
      }

      if (selectedCompanies && selectedCompanies.length > 0) {
        navigateRoute = `${navigateRoute}&companyId=${encodeURIComponent(selectedCompanies.toString())}`
      }

      navigate(navigateRoute, {
        state: {
          debouncedSearchTerm: searchTerm,
          selectedCategories: selectedCategories,
          selectedCompanies: selectedCompanies,
        },
      })
    } else {
      setIsClickedEnterOnInput(false)
    }
  }, [isClickedEnterOnInput])

  const fetchSearchResults = useCallback(async () => {
    setSearchMessage && setSearchMessage('Aranıyor...')
    dispatch(setSearchHistory(debouncedSearchTerm)) // Arama geçmişine ekleme

    const req: SearchRequest = {
      searchKey: debouncedSearchTerm,
      Categories: selectedCategories.map(String),
      Companies: selectedCompanies.map(String),
      Result: 0,
      Take: 5,
      skip: 0,
      MenuTitle: '',
      HasStock: false,
      orderString: '',
      orderType: null,
    }

    try {
      const result: any = await searchByCategoriesAndCompaniesApi.searchProductsByCategoriesAndCompanies(req)
      if (result.length === 0) {
        setSearchMessage && setSearchMessage('Sonuç bulunamadı.')
      } else {
        setSearchMessage && setSearchMessage('')
      }
      setResults(result)
      handleSearchCompleted && handleSearchCompleted(result)
    } catch (err: any) {
      setSearchMessage && setSearchMessage(err.description ?? 'Beklenmedik bir hatayla karşılaşıldı.')
    }
  }, [debouncedSearchTerm, selectedCategories, selectedCompanies])

  useEffect(() => {
    if (searchValue && searchValue !== searchTerm) {
      setSearchTerm(searchValue)
    }

    if (!debouncedSearchTerm || debouncedSearchTerm.length < SEARCH_MIN_CHAR_COUNT) {
      setResults([])
      setSearchMessage && setSearchMessage('')
      handleSearchCompleted && handleSearchCompleted([])
      return
    }

    fetchSearchResults()
  }, [debouncedSearchTerm, fetchSearchResults])

  const handleClick = () => {
    onClose && onClose()
    props.onClick && props.onClick(searchInputRef.current.value)
  }

  return (
    <div ref={searchFormRef}>
      <div
        id='widget-search-form-sidebar'
        onClick={() => {
          props.onOpen && props.onOpen()
        }}
      >
        <div className={`input-group align-items-center ${props.isSearchActive ? 'input-group-active' : ''}`}>
          <span className='btn mb-0 selects d-none d-lg-block' style={{ paddingBottom: '7px' }}>
            {' '}
            Kategoriler<i className='fas fa-chevron-down'></i>
          </span>
          <button
            className='btn mb-0 border-0 ml-0 m-t-5'
            style={{ color: '#C4C4C4', backgroundColor: 'transparent' }}
            type='button'
            onClick={() => handleClick()}
          >
            <i className='fa fa-search'></i>
          </button>
          <input
            type='text'
            id='inputId'
            autoComplete='off'
            aria-required='true'
            ref={searchInputRef}
            name='name'
            className={`form-control border-0 widget-search-form mt-2`}
            placeholder='Ara...'
            onFocus={e => {
              e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setIsClickedEnterOnInput(true)
              }
            }}
            onChange={e => {
              setSearchTerm(e.target.value)
              onChange && onChange(e.target.value)
              if (e.target.value.length < SEARCH_MIN_CHAR_COUNT) {
                setResults([])
                setSearchMessage && setSearchMessage('')
              }
            }}
          />
          <span className='btn mb-0 selects d-none d-lg-block' style={{ paddingBottom: '7px' }}>
            Şirket<i className='fas fa-chevron-down'></i>
          </span>
        </div>
      </div>
      <div className='w-100 position-absolute'>{props.children}</div>
    </div>
  )
}

export default SearchInput
