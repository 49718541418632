import React, { useState, useEffect } from 'react'
import IPButton from '../../../../components/IPButton'
import IPInput from '../../../../components/IPInput'
import Translations from '../../../../translations'
import { translate } from '../../../../base/localization'
import { GetCheckBinNumberlApi } from './services/getCheckBinNumberApi'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import Swal from 'sweetalert2'
import { getCompanyApi } from '../../components/api'
import { ICompany } from '../../components/models/models'
import IPSelectBox from '../../../../components/IPSelectBox'
import { useCustomReducer } from '../../../../base/customHooks'
import { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'
import { IBIN, IBINHistory, IBINRequest, ICardNumberRequest, ICardNumberResponse } from './models/models'
import IPModal from '../../../../components/IPModal'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { excelToJSON, excelToJSONWithName, exportToXLSX } from '../../../../base/utils/dataHelper'
import IPFile from '../../../../components/IPFile'
import { AddBinListApi } from './services/AddBinListApi'
import { GetWhiteDealerListApi } from '../../CreditCardPaymentCampaigns/Dealer/services/getWhiteDealerListApi'
import moment from 'moment'
import { GetBINHistoryApi } from './services/getBinHistory'
import { DeleteBinApi } from './services/deleteBinApi'
import { GetCheckBinNumberParamsApi } from './services/getCheckBinNumberParamsApi'
import { OrderPermApi } from './services/orderPermApi'
import { OrderDetailApi } from './services/orderDetailApi'

interface IBinState {
  filterBinDetail: IBIN[]
  orderDetail?: ICardNumberResponse
  binNumber: string
  orderBool?: boolean
  orderShow?: boolean
  orderDealer?: string
  orderNumber?: string
  companies: ICompany[]
  filterCompanies: ICompany[]
  banks: ISelectOption[]
  banksForExcel: ISelectOption[]
  bankOption?: ISelectOption
  orderCompanyOption?: ISelectOption
  selectedCompany?: ISelectOption,
  selectedStatus?: ISelectOption,
  selectedType?: ISelectOption,
  selectedInfo?: ISelectOption,
  binAdd: boolean,
  showHistory: boolean,
  historyData: IBINHistory[]
}

const t = translate(Translations)

function Index(props: BaseComponentWithContextProps) {
  const [searchParams, setSearchParams] = useSearchParams()

  const SSbinCompany = searchParams.get('Sirket')
  const SSbinStatus = searchParams.get('Durum')
  const SSbinType = searchParams.get('Tip')
  const SSbinInfo = searchParams.get('Info')
  const SSbinBank = searchParams.get('Banka')
  const SSbinNo = searchParams.get('BinNo')

  const filterCompanies = [
    { label: 'Index', value: "1000" },
    { label: 'Despec', value: "1200" },
    { label: 'Netex', value: "2300" },
    { label: 'Datagate', value: "1100" },
  ]

  const filterStatus = [
    { label: 'Aktif', value: 1 },
    { label: 'Pasif', value: 0 },
  ]

  const filterType = [
    { label: 'Bireysel Banka', value: 1 },
    { label: 'Bireysel Kredi', value: 2 },
    { label: 'Ticari Banka', value: 4 },
    { label: 'Ticari Kredi', value: 5 },
    { label: 'Ön Ödemeli', value: 3 },
    { label: 'Bilinmiyor', value: 0 },
  ]

  const filterInfo = [
    { label: 'VISA', value: 1 },
    { label: 'MasterCard', value: 2 },
    { label: 'TROY', value: 3 },
    { label: 'Co-Badge', value: 4 },
    { label: 'AMEX', value: 5 },
    { label: 'Bilinmiyor', value: 0 },
  ]

  const getInitialState = (): IBinState => {
    return {
      binNumber: SSbinNo && (SSbinNo != null || SSbinNo != "undefined") ? SSbinNo : "",
      companies: [],
      banks: [],
      banksForExcel: [],
      filterBinDetail: [],
      selectedCompany: SSbinCompany ? { label: filterCompanies.find(a => a.label == (SSbinCompany))?.label || "", value: filterCompanies.find(a => a.label == (SSbinCompany))?.value || 0 } : undefined,
      selectedStatus: SSbinStatus ? { label: filterStatus.find(a => a.label == (SSbinStatus))?.label || "", value: filterStatus.find(a => a.label == (SSbinStatus))?.value || 0 } : undefined,
      selectedType: SSbinType ? { label: filterType.find(a => a.label == (SSbinType))?.label || "", value: filterType.find(a => a.label == (SSbinType))?.value || 0 } : undefined,
      selectedInfo: SSbinInfo ? { label: filterInfo.find(a => a.label == (SSbinInfo))?.label || "", value: filterInfo.find(a => a.label == (SSbinInfo))?.value || 0 } : undefined,
      filterCompanies: [],
      binAdd: false,
      showHistory: false,
      historyData: [],
      orderShow: false,
      orderBool: false,
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IBinState>(intialState)

  useEffect(() => {
    if
      (state.banks && state.banks.length > 0 && state.companies && state.companies.length > 0 && state.binNumber !== null && (
        ((state.selectedCompany !== undefined && state.selectedCompany !== null) ||
          (state.selectedStatus !== undefined && state.selectedStatus !== null) ||
          (state.selectedType !== undefined && state.selectedType !== null) ||
          (state.selectedInfo !== undefined && state.selectedInfo !== null) ||
          (state.bankOption !== undefined && state.bankOption !== null)) || state.binNumber != '')
    ) {
      getBinDetail()
    }
  }, [state.banks, state.companies])


  useEffect(() => {
    getCompanies()
    getBanks()
    getOrderPerm()
  }, [])

  const getBinDetail = async () => {
    const queryParams = {
      BinNo: (state.binNumber !== "" && state.binNumber != null) ? state.binNumber : undefined,
      Banka: state.bankOption ? state.banks.find(a => a.label == state.bankOption?.label)?.label : undefined,
      Sirket: state.selectedCompany ? filterCompanies.find(a => a.label == (state.selectedCompany?.label))?.label : undefined,
      Tip: state.selectedType ? filterType.find(a => a.value == Number(state.selectedType?.value))?.label : undefined,
      Info: state.selectedInfo ? filterInfo.find(a => a.value == Number(state.selectedInfo?.value))?.label : undefined,
      Durum: state.selectedStatus ? filterStatus.find(a => a.value == Number(state.selectedStatus?.value))?.label : undefined,
    };

    const filteredQueryParams = Object.entries(queryParams)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const navigateRoute = `/admin/CreditCardOperation/BIN?${filteredQueryParams}`;
    navigate(navigateRoute)

    const req: IBINRequest = {
      Active: Number(filterStatus.find(a => a.value == Number(state.selectedStatus?.value))?.value),
      BankRef: Number(state.banks.find(a => a.label == state.bankOption?.label)?.value),
      BINNo: state.binNumber,
      Company: filterCompanies.find(a => a.label == (state.selectedCompany?.label))?.value.toString(),
      Flags: Number(filterInfo.find(a => a.value == Number(state.selectedInfo?.value))?.value),
      CardType: Number(filterType.find(a => a.value == Number(state.selectedType?.value))?.value)
    }


    props.showLoading();
    const getCheckBinNumberApi: GetCheckBinNumberParamsApi = new GetCheckBinNumberParamsApi({})
    //getCheckBinNumberApi.getCheckBinNumber(JSON.stringify(state.binNumber)).then((result: IBIN[]) => {
    getCheckBinNumberApi.getCheckBinNumberParams(req).then((result: IBIN[]) => {
      props.hideLoading()
      const data: ISelectOption[] = []
      setState({ filterBinDetail: result })
    }).catch((e) => {
      Swal.fire({
        icon: 'error',
        title: e.Message,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then(() => {
        props.hideLoading();
        setState({ filterBinDetail: [] })
      })
    })
  }

  const getBanks = async () => {
    try {
      const res = await new GetWhiteDealerListApi({}).getBanks()
      if (res) {
        const banks = res.map(x => ({ label: x.BankName, value: x.BankID }))
        if (!banks) return []
        return setState({
          banksForExcel: banks.filter(a => a.label != "Diğer Bankalar" && a.value != 32901),
          banks: banks.filter(a => a.label != "Diğer Bankalar" && a.value != 32901),
          bankOption: SSbinBank ? { label: banks.find(a => a.label == (SSbinBank))?.label || "", value: banks.find(a => a.label == (SSbinBank))?.value || 0 } : undefined,
        })
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  }

  const DownloadExcel = async () => {
    try {
      if (state.filterBinDetail) {
        const data: any[] = [];
        if (state.filterBinDetail.length > 0) {
          state.filterBinDetail.map((p) => {
            const rowData = {}

            rowData['Şirket'] = p.Company === "1000" ? 'Index' : p.Company === "1100" ? 'Datagate' : p.Company === "1200" ? 'Despec' : p.Company === "2300" ? 'Netex' : '';
            rowData['Banka Adı'] = p.BankName;
            rowData['Durum'] = p.Active === 1 ? "Aktif" : "Pasif";
            rowData['Kart Tipi'] = p.CardType === 1 ? "Bireysel Banka" : p.CardType === 2 ? "Bireysel Kredi" : p.CardType === 3 ? "Ön Ödemeli" : p.CardType === 4 ? "Ticari Banka" : p.CardType === 5 ? "Ticari Kredi" : p.CardType === 0 ? "Bilinmiyor" : ''
            rowData['Kart Türü'] = p.Flags === 1 ? "VISA" : p.Flags === 2 ? "MasterCard" : p.Flags === 3 ? "TROY" : p.Flags === 4 ? "Co-Badge" : p.Flags === 5 ? "AMEX" : p.Flags === 0 ? "Bilinmiyor" : ''
            rowData['BIN No'] = p.BINCode

            data.push(rowData);
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          });
          return;
        }
        exportToXLSX(data, 'BIN Numaraları')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const deleteBIN = async (id: number) => {
    Swal.fire({
      icon: 'question',
      title: "Bütün şirketlere ait BIN kaydı silinecektir. Devam etmek istediğinize emin misiniz?",
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then((result) => {
      if (result.isConfirmed) {
        props.showLoading()
        const deleteBinApi: DeleteBinApi = new DeleteBinApi({})
        deleteBinApi
          .deleteBin(id)
          .then(async result => {
            if (result) {
              Swal.fire({
                icon: 'success',
                title: result,
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then(async () => {
                getBinDetail()
              })
            }
            props.hideLoading()
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          })
      }
    })
  }

  const showHistory = async (id: number) => {
    props.showLoading()
    const binHistory: GetBINHistoryApi = new GetBINHistoryApi({})
    try {
      await binHistory
        .historyByID(id)
        .then(res => {
          setState({ showHistory: true, historyData: res })
        })
      props.hideLoading();
    } catch (err: any) {
      props.hideLoading();
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  }

  const getCompanies = async () => {
    const company: ICompany[] = await getCompanyApi()
    setState({ companies: company })
  }



  const navigate: any = useNavigate()

  const handleFiles = async (files: File[]) => {
    try {
      props.showLoading()
      const firsat: any = await excelToJSONWithName(files[0], 'Sheet1')
      const dt: IBIN[] = []

      firsat.forEach(code => {
        code.forEach(c => {
          dt.push({
            Id: 0,
            BINCode: c.BINNo.toString(),
            BankName: c.Banka,
            BankRef: Number(state.banksForExcel.find(a => a.label === c.Banka)?.value),
            Active: 1,
            CardType: Number(filterType.find(a => a.label === c.KartTipi)?.value),
            Flags: Number(filterInfo.find(a => a.label === c.KartTuru)?.value),
            Company: ''
          })
        })
      })

      const list: AddBinListApi = new AddBinListApi({})
      await list.AddBinNumber(dt).then(res => {
        props.hideLoading();
        if (res) {
          Swal.fire({
            icon: 'success',
            title: res,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      })
    } catch (err: any) {
      props.hideLoading();
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }

  }

  const getOrderPerm = async () => {
    try {
      const res = await new OrderPermApi({}).checkOrderPerm()
      if (res) {
        setState({ orderBool: res })
      }
    } catch (err: any) {
      console.log(err)
    }
  }

  const getOrderDetail = async () => {
    if (!state.orderNumber || !state.orderDealer || !state.orderCompanyOption)
      return Swal.fire({
        icon: 'error',
        title: "Bütün alanları doldurunuz",
        showConfirmButton: true,
        allowOutsideClick: false,
      });

    const req: ICardNumberRequest = {
      orderNumber: state.orderNumber,
      dealer: state.orderDealer,
      company: state.orderCompanyOption.value.toString()
    }
    try {
      const res = await new OrderDetailApi({}).getOrderDetail(req)
      if (res) {
        setState({ orderDetail: res })
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span className='h4'>{t('pages.admin.CreditCardOperation.BIN.header')}</span>
            <div className='form-label col-lg-2' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className='buttonsuz' style={{ marginRight: '10px' }}>
                <a className='campaignExcelBtn' href='/assetWeb/docs/binnumber-example.xlsx' target='_blank'>
                  Örnek Excel
                </a>
              </div>
              <div className='buttonsuz' style={{ marginRight: '10px' }}>
                <IPFile
                  id='BIN'
                  multiple={false}
                  handleFiles={e => handleFiles(e)}
                  removeAfterSelect={true}
                  className='productCodeFileInput'
                  label=' Excel Yükle'
                  excelIcon
                />
              </div>
              <div className='buttonsuz'>
                <IPButton
                  type='button'
                  className='productCodeFileInput'
                  text={'Ekle'}
                  onClick={() => {
                    navigate(`../admin/CreditCardOperation/AddBIN`)
                  }}
                />
              </div>
            </div>
          </div>
          {
            state.orderBool &&
            <div className='col-lg-2'>
              <br></br>
              <div className='buttonsuz'>
                <IPButton
                  type='button'
                  className='btn btn-success'
                  text={'Kart Bilgileri Sorgulama'}
                  onClick={() => {
                    setState({ orderShow: !state.orderShow })
                  }}
                />
              </div>
              <br></br>
            </div>
          }
          {
            state.orderBool && state.orderShow &&
            <div className='card-body'>
              <div className='row'>
                <div className='col-lg-3'>
                  <label htmlFor='OrderDealer' className='form-label'>
                    Bayi Kodu
                  </label>
                  <IPInput type='text'
                    className='form-control'
                    placeholder='Bayi Kodu'
                    id='OrderDealer'
                    value={state.orderDealer}
                    onChange={e => {
                      setState({ orderDealer: e.target.value })
                    }}
                  />
                </div>
                <div className='col-lg-3'>
                  <label htmlFor='OrderNumber' className='form-label'>
                    İşlem Numarası
                  </label>
                  <IPInput type='text'
                    className='form-control'
                    placeholder='İşlem Numarası'
                    id='OrderNumber'
                    value={state.orderNumber}
                    onChange={e => {
                      setState({ orderNumber: e.target.value })
                    }}
                  />
                </div>
                <div className='col-lg-3'>
                  <label htmlFor='OrderCompany' className='form-label'>
                    Şirket
                  </label>
                  <IPSelectBox
                    isClearable
                    id='OrderCompany'
                    name='OrderCompany'
                    placeholder='Şirket'
                    options={filterCompanies}
                    value={state.orderCompanyOption}
                    onChangeSingle={(option) => {
                      setState({ orderCompanyOption: option })
                    }}
                  />
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='location' className='form-label'>
                    <br />
                    <div className='buttonsuz'>
                      <IPButton
                        type='button'
                        className='btn btn-success'
                        text={'Sorgula'}
                        onClick={getOrderDetail}
                      />
                    </div>
                  </label>
                </div>
              </div>
              <br></br>
              {!state.orderDetail?.errorMessage && (
                <>
                  <h5>Kart Numarası: {state.orderDetail?.cardNumber}</h5>
                  <h5>SKT: {state.orderDetail?.cardExpiryDateYear} / {state.orderDetail?.cardExpiryDateMonth} {state.orderDetail?.cardExpiryDate && "-"} {state.orderDetail?.cardExpiryDate}</h5>
                </>
              )}
              {state.orderDetail?.errorMessage && <h5>{state.orderDetail?.errorMessage}</h5>}
              <hr></hr>
            </div>
          }
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row'>
                <div className='col-lg-3'>
                  <label htmlFor='bayikodu' className='form-label'>
                    {t('pages.admin.CreditCardOperation.BIN.bankaname')}
                  </label>
                  <IPSelectBox
                    isClearable
                    id='Banks'
                    name='Banks'
                    options={state.banks}
                    value={state.bankOption}
                    onChangeSingle={(option) => {
                      setState({ bankOption: option })
                    }}
                  />
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='company' className='form-label'>
                    Şirket
                  </label>
                  <IPSelectBox
                    isClearable
                    id='Şirket'
                    name='Şirket'
                    options={filterCompanies}
                    value={state.selectedCompany}
                    onChangeSingle={(option) => {
                      setState({ selectedCompany: option })
                    }
                    }
                  />
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='status' className='form-label'>
                    Durum
                  </label>
                  <IPSelectBox
                    isClearable
                    id='Status'
                    name='Status'
                    options={filterStatus}
                    value={state.selectedStatus}
                    onChangeSingle={(option) => {
                      setState({ selectedStatus: option })
                    }
                    }
                  />
                </div>
                <div className='col-lg-3'>
                  <label htmlFor='CardType' className='form-label'>
                    Kart Tipi
                  </label>
                  <IPSelectBox
                    isClearable
                    id='CardType'
                    name='CardType'
                    options={filterType}
                    value={state.selectedType}
                    onChangeSingle={(option) => {
                      setState({ selectedType: option })
                    }
                    }
                  />
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='CardInfo' className='form-label'>
                    Kart Türü
                  </label>
                  <IPSelectBox
                    isClearable
                    id='CardInfo'
                    name='CardInfo'
                    options={filterInfo}
                    value={state.selectedInfo}
                    onChangeSingle={(option) => {
                      setState({ selectedInfo: option })
                    }
                    }
                  />
                </div>
                <div className='col-lg-4'>
                  <label htmlFor='bayikodu' className='form-label'>
                    {t('pages.admin.CreditCardOperation.BIN.bınno')}
                  </label>
                  <div className='binno'>
                    <IPInput type='text'
                      className='form-control'
                      id='binno'
                      value={state.binNumber}
                      onChange={e => {
                        setState({ binNumber: e.target.value })
                      }}
                    />
                  </div>
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='location' className='form-label'>
                    <br />
                    <div className='buttonsuz'>
                      <IPButton
                        type='button'
                        className='btn btn-success'
                        text={t('pages.admin.CreditCardOperation.BIN.strain')}
                        onClick={getBinDetail}
                      />
                    </div>
                  </label>
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='location' className='form-label'>
                    <br />
                    <div className='buttonsuz'>
                      <IPButton
                        type='button'
                        className='btn btn-success'
                        text={'Excele Kaydet'}
                        onClick={DownloadExcel}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </form>
            <br />
            <div className='row'>
              <div className='col-lg-12'>
                <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                  <div className='row'>
                    <div className='col-sm-12'>

                      {state.filterBinDetail && state.filterBinDetail.length > 0 && <>
                        <b>{state.filterBinDetail.length} adet BIN numarası listeleniyor.</b>
                        <table
                          id='datatable'
                          className='table table-bordered table-hover dataTable'
                          role='grid'
                          aria-describedby='datatable_info'
                        >
                          <thead>
                            <tr role='row' className='odd'>
                              <th>Şirket</th>
                              <th>{t('pages.admin.CreditCardOperation.BIN.bank')}</th>
                              <th>{t('pages.admin.CreditCardOperation.BIN.binno')}</th>
                              <th>{t('pages.admin.CreditCardOperation.BIN.status')}</th>
                              <th>Kart Tipi</th>
                              <th>Kart Türü</th>
                              <th>İşlemler</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              state.filterBinDetail.map((bin, index) => (
                                <tr role='row' className='odd' key={index}>
                                  <td>{bin.Company && state.companies.find(com => { return +com.Code === +bin.Company })?.Name}</td>
                                  <td>{bin.BankName}</td>
                                  <td>{bin.BINCode}</td>
                                  <td>{filterStatus.find(a => a.value == bin.Active)?.label}</td>
                                  <td>{filterType.find(a => a.value == bin.CardType)?.label}</td>
                                  <td>{filterInfo.find(a => a.value == bin.Flags)?.label}</td>
                                  <td>

                                    <a
                                      data-action-type='delete'
                                      role='button'
                                      style={{ cursor: 'pointer' }}
                                      data-toggle='tooltip'
                                      data-original-title={t('pages.mySpecial.userUpdate.delete')}
                                      onClick={() => deleteBIN(bin.Id)}
                                    >
                                      <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
                                    </a>
                                    <a
                                      data-action-type='edit'
                                      role='button'
                                      style={{ cursor: 'pointer', marginLeft: '20px' }}
                                      data-toggle='tooltip'
                                      data-original-title={t('pages.mySpecial.userUpdate.edit')}
                                      onClick={() => navigate(`../admin/CreditCardOperation/AddBIN`, { state: { binData: bin, url: window.location.href } })}
                                    >
                                      <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
                                    </a>
                                    <a style={{ cursor: 'pointer', color: 'blue', marginLeft: '15px' }} onClick={() => showHistory(bin.Id)}> <i className='icon-clock' style={{ fontSize: '1.2rem', color: 'blue' }}></i></a>

                                  </td>
                                </tr>
                              ))
                            }

                          </tbody>
                        </table>
                      </>
                      }
                    </div>
                  </div>
                </div>
                <IPModal
                  show={state.showHistory}
                  onClose={() => setState({ showHistory: false, historyData: [] })}
                  hideConfirm={true}
                  width={1200}
                  title='Tarihçe'
                >
                  <table className='table'>
                    <thead>
                      <tr role='row' className='odd'>
                        <th>Tarih</th>
                        <th>BIN</th>
                        <th>Kart Tipi</th>
                        <th>Kart Türü</th>
                        <th>Banka</th>
                        <th>Durum</th>
                        <th>Silindi</th>
                        <th>Bayi Kodu</th>
                        <th>Kullanıcı Adı</th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        state.historyData && state.historyData.length > 0 && state.historyData.map((his) => (
                          <tr role='row' className='odd' key={his.Id}>
                            <td>{his.UpdateTime && moment(his.UpdateTime).format('DD.MM.YYYY HH:mm:ss')}</td>
                            <td>{his.Bincode}</td>
                            <td>{filterType.find(a => a.value == his.CardType)?.label}</td>
                            <td>{filterInfo.find(a => a.value == his.Flags)?.label}</td>
                            <td>{state.banks.find(a => a.value == his.BankRef)?.label}</td>
                            <td>{filterStatus.find(a => a.value == his.Active)?.label}</td>
                            <td>{his.Deleted === 1 ? "Evet" : "Hayır"}</td>
                            <td>{his.UserDealer && his.UserDealer.replace('0000', '')}</td>
                            <td>{his.UserName}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </IPModal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default componentWithContext(Index)