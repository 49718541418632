import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { FCVObject } from '../../../ProductOperation/CampaignBundleCrud/services/InsertFcvService'

export class DeletePaymentTerms extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerPaymentOperation/deletePaymentTerms',
      requesterInfo,
    })
  }

  public async deletePaymentTerms(request: number): Promise<boolean> {
    return await this.postAsync<boolean>(request)
  }
}
