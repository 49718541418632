import { useFormik } from 'formik'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { useCustomReducer } from '../../../../../base/customHooks/customReducer'
import { translate } from '../../../../../base/localization'
import {
  checkImageSize,
  excelToJSON,
  formatBannerImage,
  getDateByTimezoneOffset,
  getUploadFileRequest,
  handleImageUpload,
} from '../../../../../base/utils/dataHelper'
import IPButton from '../../../../../components/IPButton'
import IPCheckbox from '../../../../../components/IPCheckBox'
import IPDatePicker from '../../../../../components/IPDatePicker/IPDatePicker'
import IPFile from '../../../../../components/IPFile'
import IPImage from '../../../../../components/IPImage'
import IPInput from '../../../../../components/IPInput'
import IPModal from '../../../../../components/IPModal'
import IPSelectBox, { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import Translations from '../../../../../translations'
import { ICompany } from '../../../../ProductList/models/commonModels'
import { getCompanyApi, getDealerApi, getSubTypeApi } from '../../../components/api'
import { CommonPostResponse, IDealer } from '../../../components/models/models'
import CampaignSelect from '../../DealerChannel/components/DealerChannelSelect'
import { IBannerCategory } from '../models/requestModels'
import { GetBannerCategoriesApi } from '../services/getBannerCategoriesApi'
import { GetBannerResetApi } from '../services/getBannerResetApi'
import { PostBannerApi } from '../services/postBannerApi'
import { PostBannerCategory } from '../services/postBannerCategoryApi'
import { UpdateBannerApi } from '../services/updateBannerApi'
import './AddBanner.scss'
import validationSchema from './Validation'

enum CariListType {
  CariCode,
  CariType,
  CariAltType,
}
interface IListBannerState {
  selectedDealerList?: CariListType
  dealerLists?: IDealer[]
  incomingDataFromNav?: any
  Location?: string
  productLink: boolean
  productDetail: boolean
  typeOther: boolean
  typeSelect: ISelectOption | null
  productCodeOption: ISelectOption[]
  videoType: boolean
  subTypes: string[]
  dealerCodeOptions?: []
  dealerTypeOptions?: []
  showIndexGroup: boolean
  indexGroupChecked: boolean
  bannerCategory: ISelectOption[]
  IsDealerListInclude: boolean
  IsDealerSubTypeInclude: boolean
}

function AddBanner(props: BaseComponentWithContextProps) {
  let navigate = useNavigate()
  const t = translate(Translations)
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [bannerCategory, setBannerCategory] = useState<IBannerCategory[]>([])
  const [tmpBannerCategory, setTmpBannerCategory] = useState<IBannerCategory[]>([])
  const routeLocation = useLocation()
  const [tmpImage, setTmpImage] = useState('')
  const inputElBanner = useRef<any>(null)
  const inputElMobil = useRef<any>(null)
  const [tmpMobilImage, setTmpMobilImage] = useState('')
  const [showCategoryUpdate, setShowCategoryUpdate] = useState<boolean>(false)

  const getInitialState = (): IListBannerState => {
    return {
      dealerLists: [],
      productLink: true,
      typeOther: true,
      typeSelect: {
        value: '1',
        label: 'Link Yok',
      },
      productCodeOption: [],
      videoType: true,
      productDetail: true,
      subTypes: [],
      selectedDealerList: CariListType.CariCode,
      showIndexGroup: true,
      indexGroupChecked: false,
      bannerCategory: [],
      IsDealerListInclude: true,
      IsDealerSubTypeInclude: true,
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListBannerState>(intialState)

  const getSubType = async () => {
    const subType: string[] = await getSubTypeApi()
    if (subType) setState({ subTypes: subType })
  }

  const getBannerCategory = async () => {
    const data: ISelectOption[] = []
    props.showLoading()
    const getBannerCategoriesApi: GetBannerCategoriesApi = new GetBannerCategoriesApi({})
    getBannerCategoriesApi.getBannerCategories().then(res => {
      if (res) {
        setBannerCategory(res)
        res.map(r => {
          return data.push({ label: r.Description, value: r.Id })
        })
        setState({ bannerCategory: data })
      }
      props.hideLoading()
    })
  }

  const getDealer = async () => {
    const dealer: IDealer[] = await getDealerApi()
    setState({ dealerLists: dealer })
  }

  const getCompanies = async () => {
    const company: any[] = await getCompanyApi()
    const data: ICompany[] = []
    data.push({
      Name: 'Index Grup',
      Image: '',
      IyzipayMerchantKey: '',
      Id: 0,
      Code: '',
    })
    company
      .filter(com => {
        return com.Name !== 'Index Group'
      })
      .map(co => {
        data.push(co)
      })
    setCompanies(data)
  }

  useEffect(() => {
    getDealer()
    getCompanies()
    getSubType()
    getBannerCategory()
  }, [])

  const deleteImage = async data => {
    if (data === 'Banner') setTmpImage('')
    if (data === 'Mobil') setTmpMobilImage('')
    if (data === 'BannerImage') setFieldValue('Image', '')
    if (data === 'MobilImage') setFieldValue('MobilImage', '')
  }

  // const TypeChange = async (data: ISelectOption) => {
  //   if (data) {
  //     if (data.value === '1')
  //       return setState({
  //         productLink: true,
  //         typeOther: true,
  //         typeSelect: data,
  //         videoType: true,
  //       })
  //     if (data.value === '7' || data.value === '8')
  //       return setState({
  //         productLink: true,
  //         typeOther: true,
  //         typeSelect: data,
  //         videoType: false,
  //       })
  //   }

  //   return setState({
  //     productLink: false,
  //     typeOther: false,
  //     typeSelect: data,
  //     videoType: true,
  //   })
  // }

  const TypeChange = async (data: ISelectOption | null) => {
    if (data) {
      if (data.value === '1')
        return setState({
          productLink: true,
          typeOther: true,
          typeSelect: data,
          videoType: true,
        })
      if (data.value === '7' || data.value === '8')
        return setState({
          productLink: true,
          typeOther: true,
          typeSelect: data,
          videoType: false,
        })
    } else {
      return setState({
        productLink: false,
        typeOther: false,
        typeSelect: null, // handle null case appropriately
        videoType: true,
      })
    }

    return setState({
      productLink: false,
      typeOther: false,
      typeSelect: data,
      videoType: true,
    })
  }

  const bannerReset = async (id: number) => {
    const getBannerResetApi: GetBannerResetApi = new GetBannerResetApi({})

    await getBannerResetApi
      .getBannerReset(id)
      .then(async result => {
        if (result) {
          Swal.fire({
            icon: 'success',
            title: 'Reklam/Kampanya Resetlendi',
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async () => {
            navigate('/admin/homeAndSiteOperation/banner/list')
          })
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const clearFileInput = () => {
    if (inputElBanner.current) {
      inputElBanner.current.value = ''
    }
  }
  const enumFunction = (takenState, takenEnum) => {
    return takenState === takenEnum ? 'active' : ''
  }

  const handleBannerImageUpload = async e => {
    if (!values.Location) {
      clearFileInput()
      return Swal.fire('Hata', 'Önce banner ın yerini seçiniz', 'error')
    }

    if (
      state.typeSelect &&
      state.typeSelect.value === '1' &&
      (e.target.files[0].type === 'video/mp4' || e.target.files[0].type === 'video/MP4')
    )
      if (
        (values.Location.value === '1' ||
          values.Location.value === '2' ||
          values.Location.value === '3' ||
          values.Location.value === '5' ||
          values.Location.value === '6' ||
          values.Location.value === '7' ||
          values.Location.value === '9' ||
          values.Location.value === '10') &&
        (e.target.files[0].type === 'video/mp4' || e.target.files[0].type === 'video/MP4')
      ) {
        clearFileInput()
        return Swal.fire('Hata', 'Mp4 ü anasayfa sağ veya modala yükleyebilirsiniz', 'error')
      }

    const fileList: any[] = await getUploadFileRequest(e)

    if (e.target.files[0].size > 5000000) {
      clearFileInput()
      return Swal.fire('Hata', 'Resim 5mb den küçük olmalıdır!', 'error')
    }

    if (
      !(
        e.target.files[0].type === 'image/jpeg' ||
        e.target.files[0].type === 'image/JPEG' ||
        e.target.files[0].type === 'image/png' ||
        e.target.files[0].type === 'image/PNG' ||
        e.target.files[0].type === 'image/GIF' ||
        e.target.files[0].type === 'image/gif' ||
        e.target.files[0].type === 'video/mp4' ||
        e.target.files[0].type === 'video/MP4'
      )
    ) {
      clearFileInput()
      return Swal.fire('Hata', 'Resim jpeg,png, gif veya mp4 olmalıdır!', 'error')
    }

    if (
      (e.target.files[0].type === 'video/mp4' || e.target.files[0].type === 'video/MP4') &&
      !(state.typeSelect && state.typeSelect.value === '9')
    ) {
      clearFileInput()
      return Swal.fire('Hata', " Mp4 formatında yükleme yaptığınız için bannerın tipini 'Video' seçmelisiniz", 'error')
    }

    let width = 0
    let heigth = 0
    if (
      values.Location.value === '1' ||
      values.Location.value === '2' ||
      values.Location.value === '3' ||
      values.Location.value === '4' ||
      values.Location.value === '5' ||
      values.Location.value === '6' ||
      values.Location.value === '7' ||
      values.Location.value === '9' ||
      values.Location.value === '10' ||
      values.Location.value === '11' ||
      values.Location.value === '16' ||
      values.Location.value === '17'
    ) {
      const dimension = formatBannerImage(Number(values.Location.value))
      width = dimension[0]
      heigth = dimension[1]
    }

    if (
      values.Location.value !== '8' &&
      values.Location.value !== '12' &&
      values.Location.value !== '13' &&
      values.Location.value !== '14' &&
      values.Location.value !== '15'
    ) {
      if (!(
        (e.target.files[0].type === 'video/mp4' || e.target.files[0].type === 'video/MP4'))
      ) {
        if (!(await checkImageSize(e, width, heigth))) {
          clearFileInput()
          return Swal.fire('Hata', `Resim boyutu ${width} * ${heigth} olmalıdır!!!`, 'error')
        }
      }

    }

    if (e && e.target && e.target.files && e.target.files[0]) {
      const base64Str = await handleImageUpload(e.target.files[0])
      setTmpImage(base64Str)
    }

    if (fileList && fileList.length > 0) {
      setFieldValue('File', fileList[0])
    }
  }

  const handleMobilBannerImageUpload = async (e: any) => {
    if (!values.Location) {
      clearFileInput()
      return Swal.fire('Hata', 'Önce banner ın yerini seçiniz', 'error')
    }

    if (
      state.typeSelect &&
      state.typeSelect.value === '1' &&
      (e.target.files[0].type === 'video/mp4' || e.target.files[0].type === 'video/MP4')
    )
      if (
        (values.Location.value === '1' ||
          values.Location.value === '2' ||
          values.Location.value === '3' ||
          values.Location.value === '5' ||
          values.Location.value === '6' ||
          values.Location.value === '7' ||
          values.Location.value === '9' ||
          values.Location.value === '10') &&
        (e.target.files[0].type === 'video/mp4' || e.target.files[0].type === 'video/MP4')
      ) {
        clearFileInput()
        return Swal.fire('Hata', 'Mp4 ü anasayfa sağ veya modala yükleyebilirsiniz', 'error')
      }

    const fileList: any[] = await getUploadFileRequest(e)

    if (e.target.files[0].size > 5000000) {
      clearFileInput()
      return Swal.fire('Hata', 'Resim 5mb den küçük olmalıdır!', 'error')
    }

    if (
      !(
        e.target.files[0].type === 'image/jpeg' ||
        e.target.files[0].type === 'image/JPEG' ||
        e.target.files[0].type === 'image/png' ||
        e.target.files[0].type === 'image/PNG' ||
        e.target.files[0].type === 'image/GIF' ||
        e.target.files[0].type === 'image/gif' ||
        e.target.files[0].type === 'video/mp4' ||
        e.target.files[0].type === 'video/MP4'
      )
    ) {
      clearFileInput()
      return Swal.fire('Hata', 'Resim jpeg,png, gif veya mp4 olmalıdır!', 'error')
    }

    if (
      (e.target.files[0].type === 'video/mp4' || e.target.files[0].type === 'video/MP4') &&
      !(state.typeSelect && state.typeSelect.value === '9')
    ) {
      clearFileInput()
      return Swal.fire('Hata', " Mp4 formatında yükleme yaptığınız için bannerın tipini 'Video' seçmelisiniz", 'error')
    }

    // let width = 0
    // let heigth = 0

    // if (
    //   values.Location.value === '1' ||
    //   values.Location.value === '2' ||
    //   values.Location.value === '3' ||
    //   values.Location.value === '4' ||
    //   values.Location.value === '5' ||
    //   values.Location.value === '6' ||
    //   values.Location.value === '7' ||
    //   values.Location.value === '9' ||
    //   values.Location.value === '10' ||
    //   values.Location.value === '11' ||
    //   values.Location.value === '16' ||
    //   values.Location.value === '17'
    // ) {
    //   const dimension = formatBannerImage(Number(values.Location.value))
    //   width = dimension[0]
    //   heigth = dimension[1]
    // }

    // if (
    //   values.Location.value != '8' &&
    //   values.Location.value != '12' &&
    //   values.Location.value != '13' &&
    //   values.Location.value != '14' &&
    //   values.Location.value != '15'
    // ) {
    //   if (!(await checkImageSize(e, width, heigth))) {
    //     clearFileInput()
    //     return Swal.fire('Hata', `Resim boyutu ${width} * ${heigth} olmalıdır!!!`, 'error')
    //   }
    // }

    if (e && e.target && e.target.files && e.target.files[0]) {
      const base64Str = await handleImageUpload(e.target.files[0])
      setTmpMobilImage(base64Str)
    }

    if (fileList && fileList.length > 0) {
      setFieldValue('FileMobil', fileList[0])
    }
  }
  const [productCode, setProductCode] = useState<ISelectOption[] | null>(null)

  const onCreateOption = (option, property, updateFunc, type: any) => {
    if (type === 'input') {
      updateFunc(prevState => {
        const newData = {
          value: option[option.length - 1].value,
          label: option[option.length - 1].label,
        }
        if (prevState && prevState.length > 0) {
          for (const state of prevState) {
            if (state.value === newData.value) return [...prevState]
            const prevData = values[property]
            setFieldValue(property, [...prevData, newData.value])
            return [...prevState, newData]
          }
        }
        setFieldValue(property, [newData.value])
        return [newData]
      })
    } else {
      updateFunc(prevState => {
        if (prevState && prevState.length > 0) {
          const prevData = values[property]
          const newData: ISelectOption[] = []
          const valueData: any[] = []
          option.map(o => {
            if (
              prevState.filter(pr => {
                return pr.value === o.value
              }).length > 0 &&
              newData.filter(ne => {
                return ne.value === o.value
              }).length > 0
            )
              return
            newData.push({
              value: o.value,
              label: o.label,
            })
            return valueData.push(o.value)
          })
          setFieldValue(property, [...prevData, valueData])
          const dt: ISelectOption[] = []
          prevState.map(pr => {
            return dt.push(pr)
          })
          newData.map(nd => {
            return dt.push(nd)
          })
          return dt
        } else {
          const newData: ISelectOption[] = []
          const valueData: any[] = []
          option.map(o => {
            newData.push({
              value: o.value,
              label: o.label,
            })
            return valueData.push(o.value)
          })
          setFieldValue(property, valueData)
          return newData
        }
      })
    }
  }
  //#region options
  const bannerLocationOptions = [
    {
      value: '1',
      label: t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.locationPixel', ['Login', '1920', '1080']),
    },
    {
      value: '2',
      label: t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.locationPixel', [
        'Ana Sayfa Sol Üst',
        '480',
        '240',
      ]),
    },
    {
      value: '3',
      label: t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.locationPixel', [
        'Ana Sayfa Sol Alt',
        '480',
        '240',
      ]),
    },
    {
      value: '4',
      label: t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.locationPixel', [
        'Ana Sayfa Sağ',
        '1400',
        '710',
      ]),
    },
    {
      value: '5',
      label: t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.locationPixel', [
        'Sayaç Ana Sayfa Üst',
        '1420',
        '140',
      ]),
    },
    {
      value: '6',
      label: t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.locationPixel', [
        'Sayaç Ana Sayfa Alt',
        '1420',
        '140',
      ]),
    },
    {
      value: '7',
      label: t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.locationPixel', ['Bana Özel', '1420', '140']),
    },
    {
      value: '8',
      label: 'Pop-up',
    },
    {
      value: '9',
      label: t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.locationPixel', ['Orta Banner', '352', '227']),
    },
    {
      value: '10',
      label: t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.locationPixel', ['Alt Banner', '690', '250']),
    },
    {
      value: '11',
      label: t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.locationPixel', ['Ürün Detay', '420', '63']),
    },
    {
      value: '12',
      label: 'Cari Ödeme',
    },
    {
      value: '13',
      label: 'Avantaj POS Ödeme',
    },
    {
      value: '14',
      label: 'Fırsat POS Ödeme',
    },
    {
      value: '15',
      label: 'FÇV2 ödeme',
    },
    {
      value: '16',
      label: t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.locationPixel', ['Sepet Ürün', '485', '180']),
    },
    {
      value: '17',
      label: t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.locationPixel', [
        'Sepet Ödeme Şekli',
        '1000',
        '110',
      ]),
    },
  ]

  const popupLocationOptions = [
    {
      value: 0,
      label: 'Pop-up',
    },
    {
      value: 1,
      label: 'Aç Box',
    },
    {
      value: 2,
      label: 'Pop-up ve Aç Box',
    },
  ]

  const bannerTypeOptions = [
    {
      value: '1',
      label: 'Link Yok',
    },
    {
      value: '2',
      label: 'Ürüne Link',
    },
    {
      value: '3',
      label: 'Liste Ekranı',
    },
    {
      value: '4',
      label: 'Aynı Pencere Link',
    },
    {
      value: '5',
      label: 'Ürün Alt Tip',
    },
    {
      value: '6',
      label: 'Yeni Pencere Link',
    },
    {
      value: '7',
      label: 'Youtube Video',
    },
    {
      value: '8',
      label: 'Vimeo Video',
    },
    {
      value: '9',
      label: 'Video',
    },
  ]

  const counterOptions = [
    {
      value: '1',
      label: t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.getSlider'),
    },
    {
      value: '2',
      label: t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.beAtTheBottom'),
    },
  ]
  //#endregion

  const initialValues = Object.assign({
    selectedDealerList: CariListType.CariCode,
    Title: '',
    Description: '',
    CompanyList: [],
    Order: 0,
    Category: null,
    Link: '',
    Active: false,
    Image: '',
    ImageUrl: '',
    MobilImage: '',
    StartDate: new Date(),
    EndDate: new Date(),
    CariTypeCode: null,
    CariSubTypeCode: null,
    Id: 0,
    Counter: 0,
    CounterLocation: null,
    Location: null,
    File: {
      Label: '',
      Content: '',
      Extension: '',
      FileType: '',
    },
    FileMobil: {
      Label: '',
      Content: '',
      Extension: '',
      FileType: '',
    },
    ProductCode: null,
    ProductAddress: '',
    BannerType: 1,
    VideoId: '',
    IsHomepage: false,
    PopupLocation: null,
    IsDealerSubTypeInclude: true,
    IsDealerListInclude: true,
  })

  const [startDate, setStartDate] = useState<any>(new Date())
  const [endDate, setEndDate] = useState<any>(new Date())

  const { handleSubmit, handleChange, setValues, setFieldValue, resetForm, values, errors, touched } = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async values => {
      if (!startDate) {
        Swal.fire({
          icon: 'error',
          title: 'Lütfen Baslangıç Tarihi Seçiniz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })

        return
      }

      if (!endDate) {
        Swal.fire({
          icon: 'error',
          title: 'Lütfen Bitiş Tarihi Seçiniz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })

        return
      }

      if ((endDate as Date).getTime() <= (startDate as Date).getTime()) {
        Swal.fire({
          icon: 'error',
          title: 'Bitiş tarihi başlangıç tarihinden küçük veya eşit olamaz!',
          showConfirmButton: true,
          allowOutsideClick: false,
        })

        return
      }

      const clnValues = _.clone(values)
      if (!clnValues.Location) {
        Swal.fire({
          icon: 'error',
          title: 'Banner ın yerini seçmelisiniz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }
      if (Number(state.typeSelect && state.typeSelect.value) === 8 && !clnValues.PopupLocation) {
        Swal.fire({
          icon: 'error',
          title: 'Pop-up ın yerini seçmelisiniz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }
      if (
        (Number(state.typeSelect && state.typeSelect.value) === 7 && clnValues.VideoId.length === 0) ||
        (Number(state.typeSelect && state.typeSelect.value) === 8 && clnValues.VideoId.length === 0)
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Lütfen video id girmelisiniz.',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }

      if (
        Number(state.typeSelect && state.typeSelect.value) !== 7 &&
        Number(state.typeSelect && state.typeSelect.value) !== 8 &&
        Number(state.typeSelect && state.typeSelect.value) !== 9 &&
        !tmpImage &&
        !clnValues.Image &&
        !tmpMobilImage &&
        !clnValues.MobilImage
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Lütfen resim veya video girmelisiniz.',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }

      clnValues.IsDealerListInclude = state.IsDealerListInclude

      clnValues.IsDealerSubTypeInclude = state.IsDealerSubTypeInclude

      clnValues.CariTypeCode = clnValues.CariTypeCode
        ? (clnValues.CariTypeCode.map((item: ISelectOption) => {
          return item.value
        }) as any)
        : []

      clnValues.CariSubTypeCode = clnValues.CariSubTypeCode
        ? (clnValues.CariSubTypeCode.map((item: ISelectOption) => item.value) as any)
        : []

      clnValues.CounterLocation = clnValues.CounterLocation ? Number(clnValues.CounterLocation.value) : 0
      clnValues.PopupLocation = clnValues.PopupLocation ? Number(clnValues.PopupLocation.value) : 0
      clnValues.Location = clnValues.Location ? Number(clnValues.Location.value) : 0
      if (
        (clnValues.Location === 5 && clnValues.CounterLocation === 0) ||
        (clnValues.Location === 6 && clnValues.CounterLocation === 0)
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Sayaç seçmelisiniz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }

      clnValues.Category = clnValues.Category ? Number(clnValues.Category.value) : 0
      if (clnValues.Location === 4 && clnValues.Category === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Kategori seçmelisiniz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }

      if (
        clnValues.Location?.value === '2' &&
        clnValues.Location?.value === '3' &&
        clnValues.Location?.value === '4' &&
        clnValues.Location?.value === '5' &&
        clnValues.Location?.value === '6' &&
        clnValues.Location?.value === '7' &&
        clnValues.Location?.value === '8' &&
        clnValues.Location?.value === '9' &&
        clnValues.Location?.value === '10' &&
        clnValues.Location?.value === '11' &&
        clnValues.Location?.value === '16' &&
        clnValues.Location?.value === '17' &&
        (!clnValues.CompanyList || clnValues.CompanyList.length === 0)
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Şirket seçmelisiniz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }

      if (
        clnValues.CompanyList &&
        clnValues.CompanyList.filter((i: ISelectOption) => {
          return Number(i.value) === 0
        }).length > 0
      )
        clnValues.IsHomepage = true
      clnValues.CompanyList = clnValues.CompanyList
        ? (clnValues.CompanyList.filter((i: ISelectOption) => {
          return Number(i.value) !== 0
        }).map((item: ISelectOption) => Number(item.value)) as any)
        : []
      clnValues.StartDate = getDateByTimezoneOffset(startDate) as any
      clnValues.EndDate = getDateByTimezoneOffset(endDate) as any
      clnValues.BannerType = state.typeSelect ? Number(state.typeSelect.value) : 1
      if (
        (clnValues.Location === 1 ||
          clnValues.Location === 2 ||
          clnValues.Location === 3 ||
          clnValues.Location === 5 ||
          clnValues.Location === 6 ||
          clnValues.Location === 7 ||
          clnValues.Location === 9 ||
          clnValues.Location === 10 ||
          clnValues.Location === 11 ||
          clnValues.Location === 16 ||
          clnValues.Location === 17) &&
        (clnValues.BannerType === 7 || clnValues.BannerType === 8)
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Banner Type ını Youtube veya Vimeo yu anasayfa Sağ ve pop up a yükleyebilirsiniz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }

      clnValues.ProductCode = productCode ? (productCode.map((item: ISelectOption) => item.value) as any) : []
      if (clnValues.Location === 1) clnValues.CariTypeCode = []
      if (clnValues.Location === 1) clnValues.CariSubTypeCode = []

      if (clnValues) {
        if ((routeLocation.state as any)?.flag === 'update') {
          const updateBanner: UpdateBannerApi = new UpdateBannerApi({})
          updateBanner
            .putBanner(clnValues)
            .then(async result => {
              if (result) {
                Swal.fire({
                  icon: 'success',
                  title: 'Banner Güncellendi',
                  showConfirmButton: true,
                  allowOutsideClick: false,
                }).then(async () => {
                  navigate('/admin/homeAndSiteOperation/banner/list')
                })
              }
            })
            .catch((err: any) => {
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            })
        } else {
          props.showLoading()
          const postBanner: PostBannerApi = new PostBannerApi({})
          postBanner
            .postBanner(clnValues)
            .then((result: CommonPostResponse) => {
              if (result) {
                props.hideLoading()
                Swal.fire({
                  icon: 'success',
                  title: 'Banner Eklendi',
                  showConfirmButton: true,
                  allowOutsideClick: false,
                }).then(async () => {
                  navigate('/admin/homeAndSiteOperation/banner/list')
                })
              }
            })
            .catch((err: any) => {
              props.hideLoading()
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            })
        }
      }
    },
  })

  useEffect(() => {
    if (values.CompanyList && values.CompanyList.length > 0) return setState({ showIndexGroup: false })
    return setState({ showIndexGroup: true, indexGroupChecked: false })
  }, [values.CompanyList])

  const setDealerCodes = (dealerCodes, fieldValue) => {
    let list: any = []
    let codeData: any = []
    if (fieldValue === 'CariTypeCode') {
      const codeData = dealerCodes.map(item => {
        let dealerCode = ''
        if (item.DealerCode.length > 6) {
          dealerCode = item.DealerCode
        } else {
          dealerCode = '0000' + item.DealerCode
        }

        return {
          label: String(item.DealerCode),
          value: String(dealerCode),
        }
      })

      let list: { label: string; value: string }[] = []

      codeData.forEach(codeItem => {
        if (!values.CariTypeCode?.some((item: any) => item.label === String(codeItem.label))) {
          list.push(codeItem)
        }
      })
      const newData = values.CariTypeCode ? [...values.CariTypeCode, ...list] : list
      const uniqueList = newData.filter((item, index, self) => {
        return index === self.findIndex(i => i.label === item.label)
      })
      setValues({
        ...values,
        CariTypeCode: uniqueList,
      })
    } else {
      dealerCodes.forEach(item => {
        const codes: any = state.subTypes?.find((sub: any) => {
          return sub === item.DealerCode
        })
        if (codes === undefined) codeData.push(item.DealerCode)
        else list.push({ label: codes, value: codes })
      })
    }
  }

  const handleFiles = async (files: File[], type: 'DealerCode' | 'DealerSubType') => {
    const dealerCodes: any = await excelToJSON(files[0])

    if (type === 'DealerCode') {
      setDealerCodes(dealerCodes[0], 'CariTypeCode')
      return
    }
    if (type === 'DealerSubType') {
      setDealerCodes(dealerCodes[0], 'CariSubTypeCode')
      return
    }
  }

  const handleFilesProductCode = async (files: File[]) => {
    const productCodes: any = await excelToJSON(files[0])
    const dt: ISelectOption[] = []
    productCodes.map(code => {
      code.map(c => {
        const label: string = String(c.ProductCode)
        if (dt.findIndex(d => d.label === label.replace(' ', '')) > -1) return
        return dt.push({ value: label.replace(' ', ''), label: label.replace(' ', '') })
      })
    })
    onCreateOption(dt, 'ProductCode', setProductCode, 'excel')
  }

  const locationChange = async (option: ISelectOption) => {
    if (tmpImage || tmpMobilImage || values.ImageUrl || values.Image) {
      if (
        option.value === '1' ||
        option.value === '2' ||
        option.value === '3' ||
        option.value === '4' ||
        option.value === '5' ||
        option.value === '6' ||
        option.value === '7' ||
        option.value === '9' ||
        option.value === '10' ||
        option.value === '11' ||
        option.value === '16' ||
        option.value === '17'
      ) {
        const prevDimension = formatBannerImage(Number(values.Location.value))
        const newDimension = formatBannerImage(Number(option.value))
        if (prevDimension[0] !== newDimension[0] || prevDimension[1] !== newDimension[1]) {
          Swal.fire({
            icon: 'question',
            title:
              'Resim boyutlarınız uyumlu değildir silinecektir. Banner ın yerini değiştirmek istediğinize emin misiniz?',
            showConfirmButton: true,
            allowOutsideClick: false,
            showDenyButton: true,
            confirmButtonText: 'Evet',
            denyButtonText: 'Hayır',
          }).then(async result => {
            if (result.isConfirmed) {
              setFieldValue('ImageUrl', '')
              setFieldValue('Image', '')
              setTmpImage('')
              setTmpMobilImage('')
              setFieldValue('Location', option)
              setFieldValue('Category', null)
              setFieldValue('CounterLocation', null)
              if (Number(option.value) === 1) setFieldValue('CompanyList', null)
              if (Number(option.value) === 11) setState({ productDetail: false })
              else setState({ productDetail: true })
              return
            }
          })
        }
      }
      setFieldValue('Location', option)
      setFieldValue('Category', null)
      setFieldValue('CounterLocation', null)
      if (Number(option.value) === 1) setFieldValue('CompanyList', null)
      if (Number(option.value) === 11) setState({ productDetail: false })
      else setState({ productDetail: true })
    } else {
      setFieldValue('Location', option)
      setFieldValue('Category', null)
      setFieldValue('CounterLocation', null)
      if (Number(option.value) === 1) setFieldValue('CompanyList', null)
      if (Number(option.value) === 11) setState({ productDetail: false })
      else setState({ productDetail: true })
    }
  }

  const [inputValue, setInputValue] = useState('')

  const handleInputChange = event => {
    setInputValue(event.target.value)
  }

  const handleAddValue = () => {
    if (inputValue.trim() !== '') {
      const words = inputValue.split(' ')
      let dealerList: { label: string; value: string }[] = []
      // Eğer values.CariTypeCode bir dizi değilse veya boşsa, boş bir dizi olarak başlat
      const currentCariTypeCode = Array.isArray(values.CariTypeCode) ? values.CariTypeCode : []
      words.forEach((word: any) => {
        if (!currentCariTypeCode.some((item: any) => item.label === word)) {
          const newItem = { label: word, value: '0000' + word }
          dealerList.push(newItem)
        }
      })
      setValues({
        ...values,
        CariTypeCode: [...currentCariTypeCode, ...dealerList],
      })
      setInputValue('')
    }
  }

  const handleInputKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAddValue()
    }
  }

  useEffect(() => {
    if (routeLocation.state && companies.length > 0 && state.bannerCategory.length > 0) {
      const data = _.clone((routeLocation.state as any)?.bannerData)
      // state.IsDealerListInclude = data.DealerCodesInclude
      // state.IsDealerSubTypeInclude = data.DealerSubTypesInclude
      setState({ IsDealerListInclude: data.DealerCodesInclude })
      setState({ IsDealerSubTypeInclude: data.DealerSubTypesInclude })

      setState({ incomingDataFromNav: data })
      state.incomingDataFromNav = data

      if (state.incomingDataFromNav) {
        if (data.CompanyList && data.CompanyList.length > 0) {
          const list = data.CompanyList.filter(com => {
            return com !== 0
          })
          const datas: any[] = []
          if (
            data.IsHomepage ||
            data.CompanyList.filter(com => {
              return com === 0
            }).length > 0
          )
            datas.push({ value: '0', label: 'Index Grup' })
          if (list.length > 0) {
            companies
              .filter((item: ICompany) => list && list.indexOf(item.Id) > -1)
              .map((com: ICompany) => {
                return datas.push({ value: `${com.Id}`, label: com.Name })
              })
          }
          if (datas.length > 0) state.incomingDataFromNav.CompanyList = datas
        }

        state.incomingDataFromNav.Location = bannerLocationOptions
          .filter((item: ISelectOption) => item.value === `${data.Location}`)
          .map((com: ISelectOption) => {
            return { value: `${com.value}`, label: com.label }
          })[0]

        state.incomingDataFromNav.BannerType = bannerTypeOptions
          .filter((item: ISelectOption) => item.value === `${data.BannerType}`)
          .map((com: ISelectOption) => {
            return { value: `${com.value}`, label: com.label }
          })[0]
        state.incomingDataFromNav.PopupLocation = popupLocationOptions.find(pop => {
          return pop.value === data.PopupLocation
        })
        state.incomingDataFromNav.CariTypeCode =
          data.CariTypeCode &&
          data.CariTypeCode.map(com => {
            return { value: `${com}`, label: com.replace('0000', '') }
          })
        state.incomingDataFromNav.CariSubTypeCode =
          data.CariSubTypeCode &&
          data.CariSubTypeCode.map(com => {
            return { value: `${com}`, label: com }
          })
        state.incomingDataFromNav.CounterLocation = counterOptions
          .filter((item: ISelectOption) => item.value === `${data.CounterLocation}`)
          .map((com: ISelectOption) => {
            return { value: `${com.value}`, label: com.label }
          })[0]
        state.Location = data.Location.value
        state.Location = data.Location.value
        state.typeSelect = data.BannerType
        state.incomingDataFromNav.Category = state.bannerCategory
          .filter((item: ISelectOption) => +item.value === +data.Category)
          .map((com: ISelectOption) => {
            return { value: `${com.value}`, label: com.label }
          })[0]
        setStartDate(state.incomingDataFromNav.StartDate ? new Date(state.incomingDataFromNav.StartDate) : null)
        setEndDate(state.incomingDataFromNav.EndDate ? new Date(state.incomingDataFromNav.EndDate) : null)
        if (data.ProductCode) {
          state.incomingDataFromNav.ProductCode = data.ProductCode.map((com: any) => {
            return { value: `${com}`, label: com }
          })
          setState({ productLink: false })
        }
        if ((routeLocation.state as any)?.flag === 'copy') state.incomingDataFromNav.Active = false
        if (data.BannerType === 2 || data.BannerType === 3)
          setState({ productLink: true, typeOther: true, videoType: false })
        if (data.ProductAddress) setState({ typeOther: true })
        setValues(state.incomingDataFromNav)

        setState({ incomingDataFromNav: state.incomingDataFromNav })
        setProductCode(state.incomingDataFromNav.ProductCode)
      }
    } else {
      resetForm()
    }
  }, [routeLocation.state, companies, state.bannerCategory])

  const isCategoryDisabled = () => {
    return values.Location?.value === '4' ? false : true
  }
  const isCariDisabled = () => {
    return values.Location ? (values.Location.value === '1' ? true : false) : true
  }

  const duplicateBanner = () => {
    if (!startDate) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen Baslangıç Tarihi Seçiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })

      return
    }

    if (!endDate) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen Bitiş Tarihi Seçiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })

      return
    }

    if ((endDate as Date).getTime() <= (startDate as Date).getTime()) {
      Swal.fire({
        icon: 'error',
        title: 'Bitiş tarihi başlangıç tarihinden küçük veya eşit olamaz!',
        showConfirmButton: true,
        allowOutsideClick: false,
      })

      return
    }

    const clnValues = _.clone(values)
    if (!clnValues.Location) {
      Swal.fire({
        icon: 'error',
        title: 'Banner ın yerini seçmelisiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (Number(state.typeSelect && state.typeSelect.value) === 8 && !clnValues.PopupLocation) {
      Swal.fire({
        icon: 'error',
        title: 'Pop-up ın yerini seçmelisiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    clnValues.CariTypeCode = clnValues.CariTypeCode
      ? (clnValues.CariTypeCode.map((item: ISelectOption) => item.label) as any)
      : []
    clnValues.CariSubTypeCode = clnValues.CariSubTypeCode
      ? (clnValues.CariSubTypeCode.map((item: ISelectOption) => item.label) as any)
      : []
    clnValues.CounterLocation = clnValues.CounterLocation ? Number(clnValues.CounterLocation.value) : 0
    clnValues.PopupLocation = clnValues.PopupLocation ? Number(clnValues.PopupLocation.value) : 0
    clnValues.Location = clnValues.Location ? Number(clnValues.Location.value) : 0
    if (
      (clnValues.Location === 5 && clnValues.CounterLocation === 0) ||
      (clnValues.Location === 6 && clnValues.CounterLocation === 0)
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Sayaç seçmelisiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    clnValues.Category = clnValues.Category ? Number(clnValues.Category.value) : 0
    clnValues.Active = false
    if (clnValues.Location === 4 && clnValues.Category === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Kategori seçmelisiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    clnValues.StartDate = getDateByTimezoneOffset(startDate) as any
    clnValues.EndDate = getDateByTimezoneOffset(endDate) as any
    clnValues.BannerType = state.typeSelect ? Number(state.typeSelect.value) : 1

    if (
      (clnValues.Location === 1 ||
        clnValues.Location === 2 ||
        clnValues.Location === 3 ||
        clnValues.Location === 5 ||
        clnValues.Location === 6 ||
        clnValues.Location === 7 ||
        clnValues.Location === 9 ||
        clnValues.Location === 10 ||
        clnValues.Location === 11 ||
        clnValues.Location === 16 ||
        clnValues.Location === 17) &&
      (clnValues.BannerType === 7 || clnValues.BannerType === 8)
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Banner Type ını Youtube veya Vimeo yu anasayfa Sağ ve pop up a yükleyebilirsiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    if (
      clnValues.Location?.value === '2' &&
      clnValues.Location?.value === '3' &&
      clnValues.Location?.value === '4' &&
      clnValues.Location?.value === '5' &&
      clnValues.Location?.value === '6' &&
      clnValues.Location?.value === '7' &&
      clnValues.Location?.value === '8' &&
      clnValues.Location?.value === '9' &&
      clnValues.Location?.value === '10' &&
      clnValues.Location?.value === '11' &&
      clnValues.Location?.value === '16' &&
      clnValues.Location?.value === '17' &&
      (!clnValues.CompanyList || clnValues.CompanyList.length === 0)
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Şirket seçmelisiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    if (
      clnValues.CompanyList &&
      clnValues.CompanyList.filter((i: ISelectOption) => {
        return Number(i.value) === 0
      }).length > 0
    )
      clnValues.IsHomepage = true
    clnValues.CompanyList = clnValues.CompanyList
      ? (clnValues.CompanyList.map((item: ISelectOption) => Number(item.value)) as any)
      : []
    clnValues.ProductCode = clnValues.ProductCode
      ? (clnValues.ProductCode.map((item: ISelectOption) => item.value) as any)
      : []
    if (clnValues.Location === 1) clnValues.CariTypeCode = []
    if (clnValues.Location === 1) clnValues.CariSubTypeCode = []
    clnValues.Id = 0
    props.showLoading()
    const postBanner: PostBannerApi = new PostBannerApi({})
    postBanner
      .postBanner(clnValues)
      .then((result: CommonPostResponse) => {
        if (result) {
          props.hideLoading()
          Swal.fire({
            icon: 'success',
            title: 'Banner Eklendi',
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async () => {
            navigate('/admin/homeAndSiteOperation/banner/list')
          })
        }
      })
      .catch((err: any) => {
        props.hideLoading()
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const isCounterDisabled = (): boolean => {
    return values.Location?.value === '5'
      ? false
      : values.Location?.value === '6'
        ? false
        : values.Location?.value === '7'
          ? false
          : true
  }

  const isCompanyDisabled = () => {
    if (
      values.Location?.value != '1' &&
      values.Location?.value != '12' &&
      values.Location?.value != '13' &&
      values.Location?.value != '14' &&
      values.Location?.value != '15'
    ) {
      if (values.IsHomepage) setFieldValue('IsHomepage', false)
      return false
    }
    if (!values.IsHomepage) setFieldValue('IsHomepage', true)
    return true
  }

  const postBannerCategory = async (id: number) => {
    const req = bannerCategory.find(x => x.Id === id)
    if (!req) return
    try {
      const res = await new PostBannerCategory({}).postBannerCategory(req)
      if (res) {
        Swal.fire('Başarılı', 'Mesaj', 'success')
        const bannerCategory = state.bannerCategory.map(x => {
          if (+x.value === id) {
            x.label = req.Description
          }
          return x
        })
        setState({ bannerCategory })
        setBannerCategory(prev => {
          const updatedState = prev.map(x => {
            if (x.Id === id) {
              x.Description = req.Description
            }
            return x
          })
          return updatedState
        })
      }
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
    }
  }

  const handleBannerCategory = (id: number, value: string) => {
    setBannerCategory(prev => {
      const updatedState = prev.map(x => {
        if (x.Id === id) {
          x.Description = value
        }
        return x
      })
      return updatedState
    })
  }

  const openBannerCategoryModal = () => {
    setShowCategoryUpdate(true)
  }

  const closeBannerCategoryModal = () => {
    setShowCategoryUpdate(false)
  }

  return (
    <div className='container addBanner'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>
              {(routeLocation.state as any)?.flag === 'update' ? (
                <h5>Banner Öğesi Güncelleme</h5>
              ) : (routeLocation.state as any)?.flag === 'copy' ? (
                <h5>Banner Öğesi Kopyalama</h5>
              ) : (
                t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.header')
              )}
            </span>
            <Link to='/admin/homeAndSiteOperation/banner/list'>
              <IPButton type='button' className='btn btn-primary' text='Listeye Dön' />
            </Link>
          </div>
          <div className='card-body'>
            <h6 style={{ color: 'red' }}>
              Dikkat! Indexgrup etiketli banner kullanımı durumunda, sadece belirli şirketleri görebilen bayiilerin
              görmediği ürünler için işlem yapılmaması gerekmektedir. Banner etiketlemede lütfen dikkate alınız.
            </h6>
            <form id='id1' className='formform' noValidate>
              <div className='etkin d-flex justify-content-end'>
                <label className='p-switch switch-color-success d-flex'>
                  <label className='floagit t-left mr-4'>
                    {t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.publish')}
                  </label>
                  <IPCheckbox name='Active' onChange={handleChange} checked={values.Active} />
                </label>
              </div>
              <div className='form-row'>
                <div className='form-group col-md-6'>
                  <label className='labeltext'>
                    {t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.bannerName')}
                  </label>
                  <IPInput
                    type='text'
                    id='Title'
                    className='form-control'
                    name='Title'
                    value={values.Title}
                    placeholder={t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.bannerName')}
                    onChange={handleChange}
                  />
                  <p className='validation-error' style={{ color: 'red' }}>
                    {' '}
                    {errors.Title && touched.Title ? errors.Title : null}
                  </p>
                </div>
                <div className='form-group col-md-6'>
                  <label className='labeltext'>
                    {t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.bannerDescription')}
                  </label>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='Description'
                    placeholder={t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.bannerDescription')}
                    onChange={handleChange}
                    value={values.Description}
                  />
                  <p className='validation-error' style={{ color: 'red' }}>
                    {' '}
                    {errors.Description && touched.Description ? errors.Description : null}
                  </p>
                </div>
              </div>
              <div className='form-row'>
                <div className='form-group col-md-3'>
                  <label className='labeltext'>
                    {t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.order')}
                  </label>
                  <IPInput
                    type='number'
                    className='form-control'
                    name='Order'
                    placeholder={t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.order')}
                    onChange={handleChange}
                    value={values.Order}
                  />
                </div>
                <div className='form-group col-md-3'>
                  <label className='labeltext'>
                    {t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.placeOfBanner')}
                  </label>
                  <IPSelectBox
                    options={bannerLocationOptions}
                    value={values.Location}
                    onChangeSingle={option => {
                      locationChange(option)
                    }}
                  />
                </div>
                {values.Location?.value === '8' ? (
                  <div className='form-group col-md-3'>
                    <label className='labeltext'>Pop-up ın Yeri</label>
                    <IPSelectBox
                      options={popupLocationOptions}
                      value={values.PopupLocation}
                      onChangeSingle={(newVal: ISelectOption) => {
                        setFieldValue('PopupLocation', newVal)
                      }}
                    />
                  </div>
                ) : (
                  <div className='form-group col-md-3'>
                    <label className='labeltext'>
                      {t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.category')}
                    </label>
                    <IPSelectBox
                      isDisabled={isCategoryDisabled()}
                      options={state.bannerCategory}
                      value={values.Category}
                      onChangeSingle={(newVal: ISelectOption) => {
                        setFieldValue('Category', newVal)
                      }}
                    />
                    <p className='validation-error'> {errors.Category && touched.Category ? errors.Category : null}</p>
                    {!isCategoryDisabled() && (
                      <Link to='' onClick={openBannerCategoryModal}>
                        Kategori Düzenle
                      </Link>
                    )}
                  </div>
                )}

                <div className='form-group col-md-3'>
                  <label className='labeltext'>
                    {t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.counter')}
                  </label>
                  <IPSelectBox
                    isDisabled={isCounterDisabled()}
                    options={counterOptions}
                    value={counterOptions
                      .filter((elem: any) => {
                        return values.CounterLocation && elem.value === values.CounterLocation.value
                      })
                      .map((elem2: any) => {
                        return { value: `${elem2.value}`, label: elem2.label }
                      })}
                    onChangeSingle={(newVal: ISelectOption) => {
                      setFieldValue('CounterLocation', newVal)
                    }}
                  />
                  <p className='validation-error'>
                    {' '}
                    {errors.CounterLocation && touched.CounterLocation ? errors.CounterLocation : null}
                  </p>
                </div>
                {/* <div className="form-group col-md-6">
                  <label className="labeltext">
                    {t(
                      "pages.admin.homeAndSiteOperation.bannersCrud.addBanner.typeCode"
                    )}
                  </label>
                  <IPSelectBox
                    isMulti
                    isDisabled={isCariDisabled()}
                    isCreatableSelect
                    isSearchable
                    closeMenuOnSelect={false}
                    name="CariTypeCode"
                    options={
                      state.dealerLists &&
                      state.dealerLists.map((com: IDealer) => {
                        return {
                          value: `${com.Id}`,
                          label: com.SapCode,
                        };
                      })
                    }
                    value={values.CariTypeCode}
                    onChangeMulti={(options: ISelectOption[]) => {
                      setFieldValue("CariTypeCode", options);
                    }}
                  />
                  <p style={{ color: "red" }}>
                    * Cari seçilmezse tüm carilerde geçerli olacak
                  </p>
                </div> */}

                <div className='form-group col-md-3'>
                  <label>Başlangıç Tarihi</label>
                  <IPDatePicker
                    selected={startDate}
                    showTimeSelect
                    dateFormat='dd.MM.yyyy HH:mm'
                    onChange={update => {
                      setStartDate(update)
                    }}
                  />
                </div>
                <div className='form-group col-md-3'>
                  <label>Bitiş Tarihi</label>
                  <IPDatePicker
                    selected={endDate}
                    showTimeSelect
                    dateFormat='dd.MM.yyyy HH:mm'
                    onChange={update => {
                      setEndDate(update)
                    }}
                  />
                </div>
                <div className='form-group col-md-2'>
                  <label className='labeltext'>Bannerın Tipi</label>
                  <IPSelectBox
                    isClearable
                    options={bannerTypeOptions}
                    value={state.typeSelect}
                    onChangeSingle={option => {
                      TypeChange(option)
                    }}
                  />
                </div>
                <div className='form-group col-md-4'>
                  <label className='labeltext'>Ürün Alt Tip / Liste veya Site Adresi</label>
                  <IPInput
                    type='text'
                    disabled={
                      (routeLocation.state as any)?.flag === 'copy'
                        ? Number(state.typeSelect && state.typeSelect.value) > 1
                          ? false
                          : state.typeOther
                        : state.typeOther
                    }
                    //disabled={true}
                    className='form-control'
                    name='ProductAddress'
                    placeholder='Ürün Alt Tip / Liste veya Site Adresi'
                    onChange={handleChange}
                    value={values.ProductAddress}
                  />
                </div>
                <div className='form-group col-md-4'>
                  <label htmlFor='SapCodeList' className='labeltext'>
                    Ürün/Stok Kodu
                  </label>
                  <IPSelectBox
                    isDisabled={state.productLink ? (state.productDetail ? true : false) : false}
                    isMulti
                    isClearable
                    isCreatableSelect
                    id='ProductCode'
                    name='ProductCode'
                    closeMenuOnSelect={false}
                    isSearchable
                    onChangeMulti={(options: ISelectOption[]) => {
                      setProductCode(options)
                    }}
                    onCreateOption={option => onCreateOption(option, 'ProductCode', setProductCode, 'input')}
                    options={state.productCodeOption}
                    value={productCode}
                  />
                  {!state.productLink && state.productDetail && (
                    <p style={{ color: 'red' }}>* Ürün kodu girilmezse tüm ürünlerde geçerli olacak</p>
                  )}
                </div>
                {!state.productLink && state.productDetail && (
                  <div className='form-group col-md-2' style={{ paddingTop: '35px' }}>
                    <IPFile
                      id='FileInputProductCode'
                      multiple={false}
                      handleFiles={e => handleFilesProductCode(e)}
                      removeAfterSelect={true}
                      className='productCodeFileInput'
                      label="Excel'den Yükle"
                    />
                    <a
                      style={{ color: 'red' }}
                      target={'_blank'}
                      href='/assetWeb/docs/productCode.xlsx'
                      className='infoMarker'
                    >
                      Örnek Excel
                    </a>
                  </div>
                )}
                <div className='form-group col-md-2'>
                  <label className='labeltext'>Video Id</label>
                  <IPInput
                    disabled={state.videoType}
                    type='text'
                    className='form-control'
                    name='VideoId'
                    placeholder='Video Id'
                    onChange={handleChange}
                    value={values.VideoId}
                  />
                </div>
                <div
                  className={!state.productLink && state.productDetail ? 'form-group col-md-4' : 'form-group col-md-6'}
                >
                  <label className='labeltext'>
                    {t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.company')}
                  </label>
                  <IPSelectBox
                    isDisabled={isCompanyDisabled()}
                    isMulti
                    isClearable
                    id='CompanyList'
                    name='CompanyList'
                    closeMenuOnSelect={false}
                    onChangeMulti={(options: ISelectOption[]) => {
                      setFieldValue('CompanyList', options)
                    }}
                    options={
                      companies &&
                      companies.map((item: ICompany) => ({
                        label: item.Name,
                        value: `${item.Id}`,
                      }))
                    }
                    value={values.CompanyList}
                  />

                  <p className='validation-error'>
                    {' '}
                    {errors.CompanyList && touched.CompanyList ? errors.CompanyList : null}
                  </p>
                </div>
                {!isCariDisabled() && (
                  <div className='formCard'>
                    <div className='cariFilterContainer'>
                      <div className='d-flex gap-6 flex-wrap'>
                        <div className='leftContainer w-50'>
                          <div>
                            <a
                              className='campaignExcelBtn'
                              href='/assetWeb/docs/dealer-codes-example.xlsx'
                              target='_blank'
                            >
                              Örnek Excel
                            </a>
                            <div className='listBtnGroup'>
                              <IPButton
                                disabled={isCariDisabled()}
                                className={`listCariBtn ${enumFunction(
                                  state.selectedDealerList,
                                  CariListType.CariCode
                                )}`}
                                text={`Cari Kodu Listesi ${values.CariTypeCode?.length > 0 ? '✅' : ''}`}
                                onClick={() =>
                                  setState({
                                    selectedDealerList: CariListType.CariCode,
                                  })
                                }
                              />
                              <IPButton
                                disabled={isCariDisabled()}
                                className={`listCariBtn ${enumFunction(
                                  state.selectedDealerList,
                                  CariListType.CariType
                                )}`}
                                text={`Cari Alt Tipi Listesi ${values.CariSubTypeCode?.length > 0 ? '✅' : ''}`}
                                onClick={() =>
                                  setState({
                                    selectedDealerList: CariListType.CariType,
                                  })
                                }
                              />
                            </div>
                          </div>

                          <div className='p-0 m-0'>
                            <div
                              className={`cariFilterTextAreaWrap ${enumFunction(
                                state.selectedDealerList,
                                CariListType.CariCode
                              )}`}
                            >
                              <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                                <IPFile
                                  disabled={isCariDisabled()}
                                  id='FileInputDealerCode'
                                  multiple={false}
                                  handleFiles={e => handleFiles(e, 'DealerCode')}
                                  removeAfterSelect={true}
                                  className='campaignFileInput'
                                  label="Excel'den Yükle"
                                />
                                <IPButton
                                  text={'Dahil'}
                                  className={`btnIncluded ${state.IsDealerListInclude ? 'active' : ''}`}
                                  onClick={() => {
                                    setState({ IsDealerListInclude: true })
                                    setFieldValue('IsDealerListInclude', true)
                                  }}
                                />
                                <IPButton
                                  text={'Hariç'}
                                  className={`btnExcept ${!state.IsDealerListInclude ? 'active' : ''}`}
                                  onClick={() => {
                                    setState({ IsDealerListInclude: false })
                                    setFieldValue('IsDealerListInclude', false)
                                  }}
                                />
                              </div>
                              <CampaignSelect
                                disabled={isCariDisabled()}
                                placeholder={'Cari Kod Listesi'}
                                height={180}
                                // options={
                                //   state.dealerLists &&
                                //   state.dealerLists.map((com: IDealer) => {
                                //     return {
                                //       value: `${com.SapCode}`,
                                //       label: com.SapCode.replace('0000', ''),
                                //     }
                                //   })
                                // }
                                value={values.CariTypeCode}
                                onChangeMulti={(options: ISelectOption[]) => {
                                  setFieldValue('CariTypeCode', options)
                                }}
                              />
                              <input
                                type='text'
                                placeholder='Cari kodu yazın'
                                value={inputValue}
                                onChange={handleInputChange}
                                onKeyPress={handleInputKeyPress}
                              />
                            </div>

                            <div
                              className={`cariFilterTextAreaWrap ${enumFunction(
                                state.selectedDealerList,
                                CariListType.CariType
                              )}`}
                            >
                              <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                                <div className='btns d-flex w-100'>
                                  <IPFile
                                    disabled={isCariDisabled()}
                                    id='FileInputDealerType'
                                    multiple={false}
                                    handleFiles={e => handleFiles(e, 'DealerSubType')}
                                    removeAfterSelect={true}
                                    className='campaignFileInput'
                                    label="Excel'den Yükle"
                                  />
                                  <IPButton
                                    text={'Dahil'}
                                    className={`btnIncluded ${state.IsDealerSubTypeInclude ? 'active' : ''}`}
                                    onClick={() => {
                                      setState({ IsDealerSubTypeInclude: true })
                                      setFieldValue('IsDealerSubTypeInclude', true)
                                    }}
                                  />

                                  <IPButton
                                    text={'Hariç'}
                                    className={`btnExcept ${!state.IsDealerSubTypeInclude ? 'active' : ''}`}
                                    onClick={() => {
                                      setState({ IsDealerSubTypeInclude: false })
                                      setFieldValue('IsDealerSubTypeInclude', false)
                                    }}
                                  />
                                </div>
                              </div>
                              <CampaignSelect
                                disabled={isCariDisabled()}
                                placeholder={'Cari Alt Tipi Listesi'}
                                height={180}
                                options={state.subTypes?.map((item: any) => {
                                  return { label: item, value: item }
                                })}
                                onChangeMulti={(options: ISelectOption[]) => setFieldValue('CariSubTypeCode', options)}
                                value={values.CariSubTypeCode}
                              />
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {tmpImage ? (
                  values.BannerTye === 7 || values.BannerTye === 8 || values.BannerTye === 9 ? (
                    <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize'>
                      <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                        <h5 style={{ color: '#adb5bd' }}>Video</h5>
                      </div>
                    </div>
                  ) : (
                    <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize'>
                      <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                        <div className='room '>
                          <div className='room-image text-center '>
                            <IPImage src={tmpImage} alt='' className='RecentVisits' />
                          </div>
                        </div>
                        <div className='product-description'>
                          <div className='product-title text-left'></div>
                          <div className='product-title text-left' style={{ padding: '10px' }}>
                            Adı:Banner Resim
                          </div>
                        </div>
                        <a
                          className='ml-2'
                          data-toggle='tooltip'
                          onClick={() => deleteImage('Banner')}
                          style={{
                            cursor: 'pointer',
                            color: 'red',
                            fontSize: '35px',
                          }}
                        >
                          <i className='icon-trash-2'></i>
                        </a>
                      </div>
                    </div>
                  )
                ) : values.Image ? (
                  values.BannerTye === 7 || values.BannerTye === 8 || values.BannerTye === 9 ? (
                    <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize'>
                      <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                        <h5 style={{ color: '#adb5bd' }}>Video</h5>
                      </div>
                    </div>
                  ) : (
                    <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize'>
                      <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                        <div className='room '>
                          <div className='room-image text-center '>
                            <IPImage src={values.ImageUrl} alt='' className='RecentVisits' />
                          </div>
                        </div>
                        <div className='product-description'>
                          <div className='product-title text-left'></div>
                          <div className='product-title text-left' style={{ padding: '10px', color: '#adb5bd' }}>
                            Adı:Banner Resim
                          </div>
                        </div>
                        <a
                          className='ml-2'
                          data-toggle='tooltip'
                          onClick={() => deleteImage('BannerImage')}
                          style={{
                            cursor: 'pointer',
                            color: 'red',
                            fontSize: '35px',
                          }}
                        >
                          <i className='icon-trash-2'></i>
                        </a>
                      </div>
                    </div>
                  )
                ) : (
                  <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize'>
                    <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                      <i
                        className='icon-plus'
                        style={{ fontSize: '150px', color: '#adb5bd' }}
                        onClick={() => inputElBanner.current.click()}
                      ></i>
                      <input
                        ref={inputElBanner}
                        type='file'
                        name='bannerImage'
                        className='form-control'
                        id='bannerImage'
                        style={{ display: 'none' }}
                        onInput={event => {
                          handleBannerImageUpload(event)
                        }}
                      />
                      <br />
                      <h5 style={{ color: '#adb5bd' }}>Banner Resim Ekle</h5>
                    </div>
                  </div>
                )}
                {tmpMobilImage ? (
                  values.BannerTye === 7 || values.BannerTye === 8 || values.BannerTye === 9 ? (
                    <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize'>
                      <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                        <h5 style={{ color: '#adb5bd' }}>Video</h5>
                      </div>
                    </div>
                  ) : (
                    <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize'>
                      <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                        <div className='room '>
                          <div className='room-image text-center '>
                            <IPImage src={tmpMobilImage} alt='' className='RecentVisits' />
                          </div>
                        </div>
                        <div className='product-description'>
                          <div className='product-title text-left'></div>
                          <div className='product-title text-left' style={{ padding: '10px', color: '#adb5bd' }}>
                            Adı:Mobil Resim
                          </div>
                        </div>
                        <a
                          className='ml-2'
                          data-toggle='tooltip'
                          onClick={() => deleteImage('Mobil')}
                          style={{
                            cursor: 'pointer',
                            color: 'red',
                            fontSize: '35px',
                          }}
                        >
                          <i className='icon-trash-2'></i>
                        </a>
                      </div>
                    </div>
                  )
                ) : values.MobilImage ? (
                  values.BannerTye === 7 || values.BannerTye === 8 || values.BannerTye === 9 ? (
                    <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize'>
                      <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                        <h5 style={{ color: '#adb5bd' }}>Video</h5>
                      </div>
                    </div>
                  ) : (
                    <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize'>
                      <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                        <div className='room '>
                          <div className='room-image text-center '>
                            <IPImage src={values.MobilImageUrl} alt='' className='RecentVisits' />
                          </div>
                        </div>
                        <div className='product-description'>
                          <div className='product-title text-left'></div>
                          <div className='product-title text-left' style={{ padding: '10px', color: '#adb5bd' }}>
                            Adı:Mobil Resim
                          </div>
                        </div>
                        <a
                          className='ml-2'
                          data-toggle='tooltip'
                          onClick={() => deleteImage('MobilImage')}
                          style={{
                            cursor: 'pointer',
                            color: 'red',
                            fontSize: '35px',
                          }}
                        >
                          <i className='icon-trash-2'></i>
                        </a>
                      </div>
                    </div>
                  )
                ) : (
                  <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize'>
                    <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                      <i
                        className='icon-plus'
                        style={{ fontSize: '150px', color: '#adb5bd' }}
                        onClick={() => inputElMobil.current.click()}
                      ></i>
                      <input
                        ref={inputElMobil}
                        type='file'
                        name='mobilImage'
                        className='form-control'
                        id='mobilImage'
                        style={{ display: 'none' }}
                        onChange={event => {
                          handleMobilBannerImageUpload(event)
                        }}
                      />
                      <br />
                      <h5 style={{ color: '#adb5bd' }}>Mobil Resim Ekle</h5>
                    </div>
                  </div>
                )}
              </div>
              <br />
              <div className='row'>
                <div className='btnstyle  col-md-3 m-l-10'>
                  <IPButton
                    type='button'
                    onClick={() => handleSubmit()}
                    className='btn btn-success float-right'
                    text={
                      (routeLocation.state as any)?.flag === 'update'
                        ? 'Güncelle'
                        : t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.add')
                    }
                  />
                </div>
                {state.Location === '8' && (routeLocation.state as any)?.flag === 'update' && (
                  <div className='btnstyle col-md-3 m-l-10'>
                    <IPButton
                      type='button'
                      onClick={() => bannerReset(values.Id)}
                      className='btn btn-success float-right'
                      text='Reklam/Kampanya Resetleme'
                    />
                  </div>
                )}
                {(routeLocation.state as any)?.flag === 'update' && (
                  <div className='btnstyle col-md-3 m-l-10'>
                    <IPButton
                      type='button'
                      onClick={() => duplicateBanner()}
                      className='btn btn-success float-right'
                      text='Kopyala'
                    />
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <IPModal
        show={showCategoryUpdate}
        onClose={closeBannerCategoryModal}
        hideConfirm={true}
        width={'auto'}
        title='Kategori Düzenle'
      >
        <table
          id='datatable'
          className='table table-bordered table-hover dataTable'
          role='grid'
          aria-describedby='datatable_info'
        >
          <thead className='tableodd'>
            <tr role='row'>
              <th>#</th>
              <th>Kategori</th>
              <th>Düzenle</th>
            </tr>
          </thead>
          <tbody>
            {bannerCategory.length > 0
              ? bannerCategory.map(ba => (
                <tr role='row' className='odd' key={ba.Id}>
                  <td>{ba.Id}</td>
                  <td>
                    <IPInput
                      type='text'
                      value={ba.Description}
                      onChange={e => handleBannerCategory(ba.Id, e.target.value)}
                    />
                  </td>
                  <td>
                    <i
                      className='icon-refresh-ccw'
                      style={{ color: '#333' }}
                      onClick={() => postBannerCategory(ba.Id)}
                    ></i>
                  </td>
                </tr>
              ))
              : ''}
          </tbody>
        </table>
      </IPModal>
    </div>
  )
}

export default componentWithContext(AddBanner)
