import { css } from '@emotion/css'
import { Fragment, useEffect, useMemo } from 'react'
import { useTable, usePagination, useSortBy } from 'react-table'

interface Props {
  columns: any
  data: any
  currentPage?: number
  defaultColumnOptions?: any
  headerName?: string
  columnCount?: number
  color?: string
  backgroundColor?: string
  textAlign?: boolean
  columnCountTotal?: number
  total?: string
  colorRow?: any[]
  onPageChange?: (newPage: number) => void // Yeni özellik
}

export default function Table({
  columns,
  data,
  currentPage,
  defaultColumnOptions,
  headerName,
  columnCount,
  color,
  backgroundColor,
  textAlign,
  columnCountTotal,
  total,
  colorRow,
  onPageChange
}: Props) {
  const defaultColumn = useMemo(
    () =>
      defaultColumnOptions
        ? defaultColumnOptions
        : {
          //width: 130,
        },
    []
  )


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // @ts-ignore
    page,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    pageCount,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      // @ts-ignore
      initialState: { pageIndex: currentPage ? currentPage : 0 },
    },
    useSortBy,
    usePagination
  )

  // useEffect(() => {
  //   const pageSize = sessionStorage.getItem('PageSize')
  //   if (!pageSize) return
  //   setPageSize(pageSize)
  // }, [])

  useEffect(() => {
    if (data.length === 0 || pageIndex >= Math.ceil(data.length / pageSize)) {
      gotoPage(0); // Geçersiz sayfa veya veri olmadığında ilk sayfaya dön
      if (onPageChange) onPageChange(0); // Kullanıcıya ilk sayfayı bildir
    }
  }, [data, pageIndex, pageSize, onPageChange]);


  const tableStyles = css`
    tbody {
      border-top: none !important;
    }
    td {
      padding: 0.3rem 9px !important;
    }
  `

  const actionStyles = css`
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
    @media (max-width: 724px) {
      flex-direction: column;
    }
  `

  if (data.length === 0) {
    return <>Veri bulunamadı.</>
  }

  return (
    <>
      <div
        className={`table-responsive ${css`
          transform: rotateX(180deg);

          ::-webkit-scrollbar {
            height: 6px;
          }
        `}`}
      >
        <table
          className={`table ${css`
            width: 100%;
            transform: rotateX(180deg);
          `} ${tableStyles}`}
          {...getTableProps()}
        >
          <thead style={{ backgroundColor: '#e0e0e2' }}>
            {headerName && headerName.length > 0 && (
              <tr>
                <th
                  colSpan={columnCount}
                  style={{
                    backgroundColor: backgroundColor ? backgroundColor : 'white',
                    color: color ? color : 'black',
                    textAlign: textAlign ? 'center' : 'left',
                  }}
                >
                  {headerName}
                </th>
              </tr>
            )}
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // @ts-ignore
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} style={{ color: colorRow ? colorRow[i] : 'black' }}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
            {columnCountTotal && total && (
              <tr>
                <th colSpan={columnCountTotal}></th>
                <th>
                  <b>TOPLAM:</b>
                </th>
                <th>{total}</th>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className={actionStyles}>
        <div>
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => {
              gotoPage(0)
              sessionStorage.setItem('Page', '0')
              onPageChange && onPageChange(0) // Sayfa değişikliği bildirimi
            }}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </button>
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => {
              previousPage()
              sessionStorage.setItem('Page', String(pageIndex - 1))
              onPageChange && onPageChange(pageIndex - 1) // Sayfa değişikliği bildirimi
            }}
            disabled={!canPreviousPage}
          >
            {'<'}
          </button>
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => {
              nextPage()
              sessionStorage.setItem('Page', String(pageIndex + 1))
              onPageChange && onPageChange(pageIndex + 1) // Sayfa değişikliği bildirimi
            }}
            disabled={!canNextPage}
          >
            {'>'}
          </button>
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => {
              gotoPage(pageCount - 1)
              sessionStorage.setItem('Page', String(pageCount - 1))
              onPageChange && onPageChange(pageCount - 1) // Sayfa değişikliği bildirimi
            }}
            disabled={!canNextPage}
          >
            {'>>'}
          </button>
          {' '}
        </div>
        <div>
          <span>
            Sayfa{' '}
            <strong>
              {pageIndex + 1} / {pageOptions.length}
            </strong>{' '}
          </span>
          <span>
            | Sayfaya Git:{' '}
            <input
              type='number'
              defaultValue={pageIndex + 1}
              onChange={e => {
                if (Number(e.target.value) < 0) return
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
                sessionStorage.setItem('Page', String(page))
              }}
              style={{ width: 80, marginRight: '10px' }}
            />
          </span>{' '}
        </div>
        <div>
          Satır Göster:
          <input
            type="number"
            value={pageSize}
            onChange={e => {
              let newValue = Number(e.target.value);
              if (newValue === undefined || newValue < 1)
                newValue = 1
              setPageSize(newValue);
              sessionStorage.setItem('PageSize', String(newValue));
            }}
            style={{ width: 100, marginLeft: '10px', marginRight: '10px' }}
            min={10}
            max={500}
            step={10}
          />
          Hızlı Seç:
          <select
            value={pageSize}
            onChange={e => {
              const newValue = Number(e.target.value);
              setPageSize(newValue);
              sessionStorage.setItem('PageSize', String(newValue));
            }}
            style={{ width: 130, marginLeft: '10px' }}
          >
            {[10, 20, 30, 40, 50, 100, 200, 250, 500].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize} Satır Göster
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  )
}
