import { useMemo, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { translate } from '../../../../../base/localization'
import InputCampaign from '../../../../../components/InputCampaign'
import IPButton from '../../../../../components/IPButton/IPButton'
import IPInput from '../../../../../components/IPInput'
import IPModal from '../../../../../components/IPModal'
import IPSelectBox from '../../../../../components/IPSelectBox/IPSelectBox'
import Table from '../../../../../components/TablePagination'
import Translations from '../../../../../translations'
import { ICompany } from '../../../components/models/models'
import { ISelectOption } from '../../../HomeAndSiteOperation/BulkAdding/components/BulkAdding'
import { GetAllFcvService } from '../../../ProductOperation/CampaignBundleCrud/services/GetAllFcvService'
import {
  FCVObject,
  IHistory,
  IListAllFCV,
} from '../../../ProductOperation/CampaignBundleCrud/services/InsertFcvService'
import { AddToTablePaymentTermList } from '../services/addToTablePaymentTermList'
import { PaymentTermHistoryByIdApi } from '../services/paymentTermHistoryByIdApi'
import { DeletePaymentTerms } from '../services/deletePaymentTerms'

interface ICampaignState {
  CampaignList: FCVObject[]
  FcvList: FCVObject[]
  showDetails: boolean
  id: number
  showHistory: boolean
  historyData: IHistory[]
  companies: ICompany[]
  campaignName: string
  clusterName: string
  FilterCampaignList: FCVObject[]
  companyListOption: ISelectOption | undefined
  typeListOption: ISelectOption | undefined
  totalRows: number
  currentPage: number
  currentPageSize: number
  url?: string
}
const companies = [
  { label: 'Index', value: '1000' },
  { label: 'Netex', value: '2300' },
  { label: 'Despec', value: '1200' },
  { label: 'Datagate', value: '1100' },
]
const types = [
  { label: 'Avantaj Pos', value: 0 },
  { label: 'Fırsat POS', value: 1 },
  { label: 'FÇV2', value: 2 },
  //{ label: 'Cari Ödeme', value: 3 },
]

function CampaignBundleList(props: BaseComponentWithContextProps) {
  const [searchParams, setSearchParams] = useSearchParams()

  const searchCompany = searchParams.get('Sirket')
  const searchType = searchParams.get('Tip')
  const searchDealer = searchParams.get('Bayi')
  const searchCampaign = searchParams.get('Kampanya')
  const searchStart = searchParams.get('Baslangic')
  const searchEnd = searchParams.get('Bitis')

  const navigate = useNavigate()
  const t = translate(Translations)
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  // Last day of the month
  const nextMonth = new Date(inititalStartDate)
  nextMonth.setMonth(inititalStartDate.getMonth() + 1)
  nextMonth.setDate(0)
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }
  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  const [startDate, setStartDate] = useState(searchStart ? searchStart : toDateTimeLocal(inititalStartDate))
  const [endDate, setEndDate] = useState(searchEnd ? searchEnd : toDateTimeLocal(nextMonth))

  const getInitialState = (): ICampaignState => {
    return {
      CampaignList: [],
      FilterCampaignList: [],
      campaignName: searchCampaign ? searchCampaign : '',
      clusterName: searchDealer ? searchDealer : '',
      FcvList: [],
      showDetails: false,
      id: 0,
      showHistory: false,
      historyData: [],
      companies: [],
      companyListOption: searchCompany
        ? {
          label: companies.find(a => a.label == searchCompany)?.label || '',
          value: companies.find(a => a.label == searchCompany)?.value || 0,
        }
        : undefined,
      typeListOption: searchType
        ? {
          label: types.find(a => a.label == searchType)?.label || '',
          value: types.find(a => a.label == searchType)?.value || 0,
        }
        : undefined,
      totalRows: 0,
      currentPage: 0,
      currentPageSize: 10,
    }
  }

  const initialState = getInitialState()
  const [state, setState] = useCustomReducer<ICampaignState>(initialState)
  const [campaignList, setCampaignList] = useState<FCVObject[]>([])
  const [filterCampaignList, setFilterCampaignList] = useState<FCVObject[]>([])

  const columns = useMemo(
    () => [
      {
        Header: 'Şirket',
        accessor: 'Company',
      },
      {
        Header: 'Tip',
        accessor: 'Type',
      },
      {
        Header: 'Kampanya Adı',
        accessor: 'PaymentTermsName',
        width: 400,
        minWidth: 250,
      },

      {
        Header: 'Bayi/Limit Detay',
        accessor: 'Detail',
        minWidth: 120,
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'Start',
        width: 180,
        maxWidth: 180,
      },
      {
        Header: 'Bitiş Tarihi',
        accessor: 'End',
        width: 180,
        maxWidth: 180,
      },
      {
        Header: 'İşlemler',
        accessor: 'Actions',
        minWidth: 70,
        maxWidth: 80,
      },
    ],
    []
  )
  const getCompany = company => {
    const companyMap = {
      '1000': 'Index',
      '1100': 'Datagate',
      '1200': 'Despec',
      '2300': 'Netex',
    }
    return companyMap[company] || ''
  }

  const getType = (type: any) => {
    const typeMap = {
      0: 'Avantaj POS',
      1: 'Fırsat POS',
      2: 'FÇV2',
      3: 'Cari Ödeme',
    }
    return typeMap[type] || ''
  }

  const tableData = useMemo(() => {
    return filterCampaignList.map(dt => {
      return {
        Company: getCompany(dt.Company),
        Type: getType(dt.Type),
        PaymentTermsName: dt.PaymentTermsName,
        Detail: (
          <IPButton
            onClick={() => setState({ showDetails: true, id: dt.Id })}
            text='Detay'
            className='btn btn-danger'
            style={{ padding: '0px', width: '120px', height: '30px', color: 'white', borderColor: 'red' }}
          ></IPButton>
        ),
        Start: new Date(dt.StartDate).toLocaleString('tr-TR'),
        End: new Date(dt.EndDate).toLocaleString('tr-TR'),
        Actions: (
          <>
            <div className='d-flex justify-content-center align-items-center gap-2 h-100 pt-3'>
              <div
                data-toggle='tooltip'
                data-original-title='Rapor Kaydı'
                onClick={() => addAllToPaymentTransactionList(dt.Id)}
                style={{ cursor: 'pointer', color: 'blue' }}
                title='İşlem Listesi Güncelleme'
              >
                <i className='icon-book'></i>
              </div>

              <div
                data-toggle='tooltip'
                data-original-title={t('pages.admin.homeAndSiteOperation.bannersCrud.listBanner.edit')}
                onClick={() => updateFcv(dt)}
                style={{ cursor: 'pointer', color: 'green' }}
                title='Düzenle'
              >
                <i className='icon-edit'></i>
              </div>
              {
                dt.ClusterAndLimit.every(a => a.UsedLimit === 0) &&
                <div
                  data-toggle='tooltip'
                  data-original-title={t('pages.admin.homeAndSiteOperation.bannersCrud.listBanner.edit')}
                  onClick={() => dt.Id && deleteFcv(dt.Id)}
                  style={{ cursor: 'pointer', color: 'red' }}
                  title='Sil'
                >
                  <i className='icon-trash-2'></i>
                </div>
              }

            </div>
          </>
        ),
      }
    })
  }, [filterCampaignList])

  const addAllToPaymentTransactionList = async id => {
    const shouldAdd = await Swal.fire({
      icon: 'warning',
      title:
        'Bunu sadece işlem listesinde full rapor almak (Kullanılmayan limitleri dahil etmek) istediğinizde çalıştırın. Rapor kaydı oluşturmak istediğinize emin misiniz?  2 dakikaya yakın sürebilir, bekleyiniz.',
      showCancelButton: true,
      showConfirmButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Evet',
      cancelButtonText: 'Hayır',
    })

    if (shouldAdd.isConfirmed) {
      try {
        props.showLoading()
        const list: AddToTablePaymentTermList = new AddToTablePaymentTermList({})
        await list.addToTablePaymentTermList(id)
        props.hideLoading()

        await Swal.fire({
          icon: 'success',
          title: `Tüm kayıtlar atıldı!`,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      } catch (error) {
        props.hideLoading()
        // Handle other errors appropriately
        console.error('Error add all to list:', error)
        await Swal.fire({
          icon: 'warning',
          title: `${error}Kayıtlar atılamadı!`,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      }
    }
  }

  // const filterCampaing = () => {
  //   if (startDate && !endDate) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Lütfen bitiş tarihi seçiniz!',
  //       showConfirmButton: true,
  //       allowOutsideClick: false,
  //     });
  //     return;
  //   }

  //   const sDate = new Date(startDate);
  //   const eDate = new Date(endDate);

  //   let filterCampaing: FCVObject[] = campaignList;

  //   if (state.campaignName.length > 0) {
  //     filterCampaing = filterCampaing.filter((cam) => {
  //       return (
  //         cam.Company.toLowerCase().includes(state.campaignName.toLowerCase()) ||
  //         cam.PaymentTermsName.toLowerCase().includes(state.campaignName.toLowerCase())
  //       );
  //     });
  //   }

  //   if (state.clusterName.length > 0) {
  //     filterCampaing = filterCampaing.filter((cam) => {
  //       return cam.ClusterAndLimit.some((x) => x.Cluster.replace("0000", "").includes(state.clusterName));
  //     });
  //   }

  //   if (state.companyListOption) {
  //     filterCampaing = filterCampaing.filter((cam) => {
  //       return cam.Company === state.companyListOption?.value;
  //     });
  //   }

  //   if (startDate && endDate) {
  //     filterCampaing = filterCampaing.filter((cam) => {
  //       return (
  //         (eDate >= new Date(cam.StartDate) && new Date(cam.StartDate) >= sDate) ||
  //         (sDate <= new Date(cam.EndDate) && new Date(cam.EndDate) <= eDate) ||
  //         (new Date(cam.EndDate) >= eDate && new Date(cam.StartDate) <= sDate)
  //       );
  //     });
  //   }

  //   if (state.typeListOption?.value !== undefined) {
  //     filterCampaing = filterCampaing.filter((cam) => {
  //       return cam.Type === state.typeListOption?.value;
  //     });
  //   }

  //   setFilterCampaignList(filterCampaing);
  // };

  const getAllFcv = async (pageNumber: 0, pageSize: 10) => {
    const queryParams = {
      Sirket: state.companyListOption
        ? companies.find(a => a.label == state.companyListOption?.label)?.label
        : undefined,
      Tip: state.typeListOption ? types.find(a => a.value == state.typeListOption?.value)?.label : undefined,
      Kampanya: state.campaignName !== '' && state.campaignName != null ? state.campaignName : undefined,
      Bayi: state.clusterName !== '' && state.clusterName != null ? state.clusterName : undefined,
      Baslangic: startDate ? startDate : undefined,
      Bitis: endDate ? endDate : undefined,
    }

    const filteredQueryParams = Object.entries(queryParams)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    const navigateRoute = `/admin/bankAndInstallementOperation/fcv/fcvCampaignList?${filteredQueryParams}`

    setState({ url: filteredQueryParams })
    navigate(navigateRoute)

    const req: IListAllFCV = {
      Company: state.companyListOption?.value === undefined ? '' : String(state.companyListOption?.value),
      Type: state.typeListOption?.value === undefined ? undefined : +state.typeListOption?.value,
      PaymentTermsName: state.campaignName,
      StartDate: startDate,
      EndDate: endDate,
      DealerCode: state.clusterName,
      PageNumber: pageNumber,
      PageSize: pageSize,
      PageType: 1,
    }
    setState({ currentPage: pageNumber, currentPageSize: pageSize })
    props.showLoading()
    const fcvListAPI: GetAllFcvService = new GetAllFcvService({})
    try {
      await fcvListAPI.listAllFCV(req).then(res => {
        // setCampaignList(res)
        setFilterCampaignList(res.PaymentTermsList)
        setState({ FcvList: res.PaymentTermsList, totalRows: res.TotalPaymentCount })
      })
      props.hideLoading()
    } catch (err: any) {
      setFilterCampaignList([])
      setState({ FcvList: [], totalRows: undefined })
      props.hideLoading()
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  const paymentTermHistoryById = async (id: number) => {
    props.showLoading()
    const paymentTermHistoryByIdApi: PaymentTermHistoryByIdApi = new PaymentTermHistoryByIdApi({})
    try {
      await paymentTermHistoryByIdApi.paymentTermHistoryById(id).then(res => {
        setState({ showHistory: true, historyData: res })
      })
      props.hideLoading()
    } catch (err: any) {
      props.hideLoading()
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  const updateFcv = (paymentCampaign: FCVObject) => {
    navigate('/admin/bankAndInstallementOperation/fcv/fcvCampaignAdd', {
      state: { paymentCam: paymentCampaign, url: state.url },
    })
  }

  const deleteFcv = async (id: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Kampanya silinecektir. Devam etmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {

        props.showLoading()
        const deleteApi: DeletePaymentTerms = new DeletePaymentTerms({})
        try {
          await deleteApi.deletePaymentTerms(id).then(res => {
            Swal.fire({
              icon: 'success',
              title: `Kayıtlı başarıyla silindi.`,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            getAllFcv(0, 10)
          })
          props.hideLoading()
        } catch (err: any) {
          props.hideLoading()
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      }
    })
  }


  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Ödeme Kampanya Ekranı</span>
            <div className='col-12 col-md-2 mb-2'>
              <label className='p-switch switch-color-success'>
                <Link to='/admin/bankAndInstallementOperation/fcv/fcvCampaignAdd'>
                  <IPButton
                    text={'Yeni Kampanya ' + t('pages.admin.bankAndInstallementOperation.bankCrud.bankUpdateDel.add')}
                    className='btn btn-primary'
                    style={{ height: '45px', marginLeft: '15px' }}
                  />
                </Link>
              </label>
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='form-group col-md-2'>
                <IPSelectBox
                  isClearable
                  placeholder='Şirket Seçiniz...'
                  options={companies}
                  value={state.companyListOption}
                  onChangeSingle={option => {
                    setState({ companyListOption: option })
                  }}
                />
              </div>
              <div className='form-group col-md-2'>
                <IPSelectBox
                  isClearable
                  placeholder='Tip Seçiniz...'
                  options={types}
                  value={state.typeListOption}
                  onChangeSingle={option => {
                    setState({ typeListOption: option })
                  }}
                />
              </div>
              <div className='form-group col-md-3'>
                <IPInput
                  type='text'
                  id='Title'
                  className='form-control'
                  name='Title'
                  placeholder='Kampanya Adı...'
                  onChange={e => setState({ campaignName: e.target.value })}
                  value={state.campaignName}
                />
              </div>
              <div className='form-group col-md-3'>
                <IPInput
                  type='text'
                  id='Title'
                  className='form-control'
                  name='Title'
                  placeholder='Bayi Kodu...'
                  onChange={e => setState({ clusterName: e.target.value })}
                  value={state.clusterName}
                />
              </div>
              <div className='col-12 col-md-2 mb-2'>
                <IPButton
                  type='button'
                  className='btn btn-danger'
                  text='Detaylı Süz'
                  onClick={() => getAllFcv(0, 10)}
                  style={{ height: '45px' }}
                />
              </div>
              <div className='col-12 col-md-2 mb-2'>
                <InputCampaign
                  type='date'
                  id='StartDate'
                  name='StartDate'
                  label={'Başlangıç Tarihi'}
                  value={startDate && toDateTimeLocal(startDate)}
                  onChange={e => getDate(e.target.value, 'Start')}
                  isActive
                />
              </div>
              <div className='col-12 col-md-2 mb-2'>
                <InputCampaign
                  type='date'
                  id='EndDate'
                  name='EndDate'
                  label={'Bitiş Tarihi'}
                  value={endDate && toDateTimeLocal(endDate)}
                  onChange={e => getDate(e.target.value, 'End')}
                  isActive
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4' style={{ padding: '10px' }}>
                <div className='row'>
                  <div className='col-sm-12 pr-4' style={{ overflowX: 'auto' }}>
                    <Table
                      columns={columns}
                      data={tableData}
                      pageChangeHandler={getAllFcv}
                      manualPagination={true}
                      totalRows={state.totalRows}
                      currentPage={state.currentPage}
                      currentPageSize={state.currentPageSize}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <IPModal
        show={state.showDetails}
        onClose={() => setState({ showDetails: false })}
        hideConfirm={true}
        width={800}
        title='Bayi/Limit'
      >
        <table className='table'>
          <thead>
            <tr role='row' className='odd'>
              <th>Bayi</th>
              <th>Limit</th>
              <th>Harcanan</th>
              <th>Kalan</th>
              <th>Tarihçe</th>
            </tr>
          </thead>
          <tbody>
            {state.FcvList.filter(i => {
              return i.Id === state.id
            }).map((fcv, index) => {
              return (
                <>
                  {fcv.ClusterAndLimit &&
                    fcv.ClusterAndLimit.length > 0 &&
                    fcv.ClusterAndLimit.map((dealer, i) => {
                      return (
                        <tr role='row' className='odd' key={i}>
                          <td>{dealer.Cluster.replace('0000', '')}</td>
                          <td>
                            {dealer.Limit.toFixed(2)
                              .replace('.', ',')
                              .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ₺'}
                          </td>
                          <td>
                            {dealer.UsedLimit.toFixed(2)
                              .replace('.', ',')
                              .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ₺'}
                          </td>
                          <td>
                            {dealer.RemainderLimit.toFixed(2)
                              .replace('.', ',')
                              .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ₺'}
                          </td>
                          <td>
                            <IPButton
                              onClick={() => {
                                paymentTermHistoryById(dealer.Id)
                              }}
                              text='Tarihçe'
                            ></IPButton>
                          </td>
                        </tr>
                      )
                    })}
                </>
              )
            })}
          </tbody>
        </table>
      </IPModal>
      <IPModal
        show={state.showHistory}
        onClose={() => setState({ showHistory: false, historyData: [] })}
        hideConfirm={true}
        width={1000}
        title='Tarihçe'
      >
        <table className='table'>
          <thead>
            <tr role='row' className='odd'>
              <th>Tarih</th>
              <th>Limit</th>
              <th>Kullanıcı</th>
              <th style={{ width: '400px' }}>Düzenleyen Kişi</th>
            </tr>
          </thead>
          <tbody>
            {state.historyData &&
              state.historyData.length > 0 &&
              state.historyData.map(his => (
                <tr role='row' className='odd' key={his.UserId}>
                  <td>{his.Date.toLocaleString()}</td>
                  <td>
                    {his.Limit.toFixed(2)
                      .replace('.', ',')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ₺'}
                  </td>
                  <td>{his.UserName}</td>
                  <td>{his.NameSurname}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </IPModal>
    </div>
  )
}
export default componentWithContext(CampaignBundleList)
