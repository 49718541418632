import { useEffect, useMemo, useState } from 'react'
import Swal from 'sweetalert2'
import data from '../../../api/bankData.json'
import { useCustomReducer } from '../../../base/customHooks'
import { exportToXLSX } from '../../../base/utils/dataHelper'
import IPButton from '../../../components/IPButton'
import IPInput from '../../../components/IPInput'
import IPSelectBox from '../../../components/IPSelectBox'
import { ISelectOption } from '../../../components/IPSelectBox/IPSelectBox'
import Table from '../../../components/Table'
import { financialButtonStyles } from '../FinancialInformationLayout'
import { IBankAccountNumbers } from '../models/models'
interface IBankAccountNumbersState {
  bankAccountNumbers?: IBankAccountNumbers[]
  tabName: string
  currencyListOptions: ISelectOption[]
  currencyListOption: any
}

export default function BankAccountNumbers() {
  const getInitialState = (): IBankAccountNumbersState => {
    return {
      tabName: 'data',
      bankAccountNumbers: data.data,
      currencyListOption: null,
      currencyListOptions: [
        { label: 'Tümü', value: '0' },
        { label: 'USD', value: 'USD' },
        { label: 'EUR', value: 'EUR' },
        { label: 'TL', value: 'TL' },
      ],
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IBankAccountNumbersState>(intialState)
  const [filteredList, setFilteredList] = useState<IBankAccountNumbers[]>([])
  const [showFilterRow, setShowFilterRow] = useState(false)
  const [bankSelectOptions, setBankSelectOptions] = useState<ISelectOption[]>([])
  const [bankSelectOption, setBankSelectOption] = useState<any>()
  const [searchFilter, setSearchFilter] = useState('')

  useEffect(() => {
    if (state.bankAccountNumbers && state.bankAccountNumbers.length > 0) {
      setFilteredList(state.bankAccountNumbers)
    }
  }, [state.bankAccountNumbers])

  const tabControl = async (tab: 'data' | 'Index' | 'Datagate' | 'Despec' | 'Netex') => {
    const uniqueBankLabels = {}

    const setListAndOptions = list => {
      setFilteredList(list)
      setBankSelectOptions(
        list
          .map(x => ({
            value: x.Bank,
            label: x.Bank.toUpperCase(),
          }))
          .filter(option => {
            if (!uniqueBankLabels[option.label]) {
              uniqueBankLabels[option.label] = true
              return true
            }
            return false
          })
      )
    }

    // Use a switch statement for better readability
    switch (tab) {
      case 'Index':
        setListAndOptions(data.index)
        break
      case 'Datagate':
        setListAndOptions(data.datagate)
        break
      case 'Despec':
        setListAndOptions(data.despec)
        break
      case 'Netex':
        setListAndOptions(data.netex)
        break
      default:
        setListAndOptions(data.data)
        break
    }
    setShowFilterRow(true)
    setState({ tabName: tab })
  }

  const excelButton = async () => {
    try {
      if (data) {
        const excelData =
          state.tabName === 'Index'
            ? data.index
            : state.tabName === 'Datagate'
              ? data.datagate
              : state.tabName === 'Despec'
                ? data.despec
                : state.tabName === 'Netex'
                  ? data.netex
                  : data.data

        const edata: any[] = []
        if (excelData.length > 0) {
          excelData.map(p => {
            return edata.push({
              Id: p.Id,
              Banka: p.Bank,
              Şube: p.Branch,
              'Şube Kodu': p.BranchCode,
              'Şube adı': p.BranchName,
              'Hesap Numarası': p.AccountNumber,
              'Hesap Türü': p.Type,
              IBAN: p.Iban,
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(edata, state.tabName + ' Banka Hesap Numaraları')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  //#region Tablo Verileri
  const tableData = useMemo(() => {
    return filteredList.map(dt => {
      return {
        Banka: dt.Bank,
        Şube: dt.Branch,
        'Şube Kodu': dt.BranchCode,
        'Şube Adı': dt.BranchName,
        'Hesap Türü': dt.Type === 'YTL' ? 'TL' : dt.Type,
        'Hesap Numarası': dt.AccountNumber,
        IBAN: dt.Iban,
      }
    })
  }, [filteredList])

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Banka',
        accessor: 'Banka',
      },
      {
        Header: 'Şube',
        accessor: 'Şube',
      },
      {
        Header: 'Şube Kodu',
        accessor: 'Şube Kodu',
      },
      {
        Header: 'Şube Adı',
        accessor: 'Şube Adı',
      },
      {
        Header: 'Hesap Türü',
        accessor: 'Hesap Türü',
      },
      {
        Header: 'Hesap Numarası',
        accessor: 'Hesap Numarası',
      },
      {
        Header: 'IBAN',
        accessor: 'IBAN',
      },
    ],
    []
  )
  //#endregion

  //#region Detaylı Süzme fonksiyonu
  const filterList = () => {
    const filterConditions: ((item: IBankAccountNumbers) => boolean)[] = []

    if (bankSelectOption) {
      filterConditions.push(b => b.Bank.toUpperCase() === bankSelectOption.label)
    }

    if (searchFilter) {
      filterConditions.push(x => {
        const searchTerm = searchFilter.toUpperCase()
        return (
          x.Bank.includes(searchTerm) ||
          x.Type.includes(searchTerm) ||
          x.BranchName.includes(searchTerm) ||
          x.BranchCode.includes(searchFilter)
        )
      })
    }

    if (state.currencyListOption && state.currencyListOption.value !== '0') {
      filterConditions.push(c => (c.Type === 'YTL' ? 'TL' : c.Type) === state.currencyListOption.value)
    }

    let filteredData: IBankAccountNumbers[] = []

    switch (state.tabName) {
      case 'Index':
        filteredData = data.index
        break
      case 'Datagate':
        filteredData = data.datagate
        break
      case 'Despec':
        filteredData = data.despec
        break
      case 'Netex':
        filteredData = data.netex
        break
      default:
        filteredData = data.data
        break
    }

    if (filterConditions.length > 0) {
      filteredData = filteredData.filter(item => {
        return filterConditions.every(condition => condition(item))
      })
    }
    setFilteredList(filteredData)
  }
  //#endregion

  return (
    <>
      <div className='d-flex mb-2 justify-content-between'>
        <div className={financialButtonStyles}>
          <IPButton
            className={`${state.tabName === 'Index' ? 'active' : ''} indexButton`}
            name='order'
            onClick={() => tabControl('Index')}
            text='Index'
          />
          <IPButton
            name='company'
            className={`${state.tabName === 'Datagate' ? 'active' : ''} datagateButton`}
            onClick={() => tabControl('Datagate')}
            text='Datagate'
          />
          <IPButton
            name='company'
            className={`${state.tabName === 'Despec' ? 'active' : ''} despecButton`}
            onClick={() => tabControl('Despec')}
            text='Despec'
          />
          <IPButton
            name='company'
            className={`${state.tabName === 'Netex' ? 'active' : ''} netexButton`}
            onClick={() => tabControl('Netex')}
            text='Netex'
          />
        </div>
        <div className='d-flex align-items-center'>
          <a onClick={excelButton} className='excel-button ms-2 d-flex justify-content-center align-items-center'>
            <img
              src='/images/icons/excel.png'
              alt=''
              width={25}
              height={25}
              style={{ cursor: 'pointer', marginRight: '5px' }}
              title="Excel'e Aktar"
            />
            Excel'e Aktar
          </a>
        </div>
      </div>
      {!showFilterRow &&
        <div style={{ textAlign: 'center' }}>
          Lütfen Banka Hesap Numaralarını görmek için şirket seçimi yapınız.
        </div>
      }

      {showFilterRow && (
        <>
          <div className='detailFilter'>
            <div className='row'>
              <div className='form-group col-md-2'>
                <IPSelectBox
                  isClearable
                  placeholder='Banka'
                  options={bankSelectOptions}
                  value={bankSelectOption}
                  onChangeSingle={option => {
                    setBankSelectOption(option)
                  }}
                />
              </div>
              <div className='form-group col-md-2'>
                <IPSelectBox
                  isClearable
                  placeholder='Hesap Türü'
                  options={state.currencyListOptions}
                  value={state.currencyListOption}
                  onChangeSingle={option => {
                    setState({ currencyListOption: option })
                  }}
                />
              </div>
              <div className='form-group col-md-2'>
                <IPInput
                  type='text'
                  id='searchFilter'
                  className='form-control'
                  name='searchFilter'
                  placeholder='Arama'
                  onChange={e => setSearchFilter(e.target.value)}
                  value={searchFilter}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      filterList()
                    }
                  }}
                />
              </div>

              <div className='col-12 col-md-2 mb-2 ms-2'>
                <IPButton
                  type='button'
                  className='btn btn-primary'
                  text='Filtrele'
                  onClick={filterList}
                  style={{ height: '45px' }}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {/* UseMemo'dan gelen verilerin tablosu */}
      <div className=''>
        <Table columns={tableColumns} data={tableData} currentPage={0} color='white' />
      </div>
    </>
  )
}
