import React, { useState } from 'react'
import Item from './Item/Item'
import Header from './Header'
import Sidebar from './Sidebar'
import CustomCard from './Components/CustomCard'

function MySpecialLayout(props: any) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  return (
    <React.Fragment>
      <section style={{ padding: "20px 0", background: "#f5f5f5" }}>
        <div className="container">
          <div>
            <span
              style={{
                marginBottom: "10px",
                padding: "5px 10px",
                backgroundColor: "#979292",
                color: "black",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                userSelect: "none",
              }}
              onClick={toggleSidebar}>{`${isSidebarOpen ? '<' : '>'}`}</span>
          </div>
          <div className="row">
            <div className={`col-12 col-lg-3`}>
              {isSidebarOpen && <Sidebar />}
            </div>
            <div className={`col-12 ${isSidebarOpen ? "col-lg-9" : "col-lg-12"} sidebar-right mt-lg-0 mt-5 p-sm-2`}>
              <CustomCard>
                <Header />
                {props.children}
              </CustomCard>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default MySpecialLayout
