import React, { useState, useMemo, useEffect } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import IPSelectBox from '../../../../../components/IPSelectBox'
import IPButton from '../../../../../components/IPButton'
import Table from '../../../../../components/Table'
import IPInput from '../../../../../components/IPInput'
import { Link, useNavigate } from 'react-router-dom'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { useCustomReducer } from '../../../../../base/customHooks'
import { IListXMLRequest, IListXMLResponse } from '../models/models'
import { GetXmlListApi } from '../services/getXmlListApi'
import Swal from 'sweetalert2'
import IPCheckbox from '../../../../../components/IPCheckBox'
import { ICompany } from '../../../components/models/models'
import { getCompanyApi } from '../../../components/api'
import { exportToXLSX } from '../../../../../base/utils/dataHelper'

interface IListXmlState {
  fieldOption?: ISelectOption
  statusOption: ISelectOption | null
  field: string
  companiesOption: ISelectOption | null
}

function ListXml(props: BaseComponentWithContextProps) {
  const fieldOption = [
    {
      label: "Tüm Liste",
      value: 1,
    },
    {
      label: "Hesap Kodu",
      value: 2,
    },
    // {
    //   label: "Hesap Adı/Ünvan",
    //   value: 3,
    // },
    // {
    //   label: "Şirket Kodu",
    //   value: 4,
    // }
  ]

  const statusOption = [
    {
      label: "XML Alım Aktif",
      value: 1,
    },
    {
      label: "XML Alım Pasif",
      value: 2,
    },
    {
      label: "Cari Aktif",
      value: 3,
    },
    {
      label: "Cari Pasif",
      value: 4,
    },
    {
      label: "XML Kayıt Aktif",
      value: 5,
    },
    {
      label: "XML Kayıt Pasif",
      value: 6,
    },
  ]

  const getInitialState = (): IListXmlState => {
    return {
      statusOption: null,
      field: "",
      companiesOption: null
    }
  }

  const navigate: any = useNavigate()
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListXmlState>(intialState)
  const [xml, setXml] = useState<IListXMLResponse[]>([])
  const [filterXml, setFilterXml] = useState<IListXMLResponse[]>([])
  // const [companies, setCompanies] = useState<ICompany[]>([])
  // const getCompanies = async () => {
  //   const company: any[] = await getCompanyApi()
  //   const data: ICompany[] = []
  //   company
  //     .filter(com => {
  //       return com.Name !== 'Index Group'
  //     })
  //     .map((co) => {
  //       data.push(co)
  //     })
  //   // data.push({
  //   //   Name: "GRP",
  //   //   Image: "",
  //   //   IyzipayMerchantKey: "",
  //   //   Id: 0,
  //   //   Code: 0
  //   // })
  //   setCompanies(data)
  // }
  const tableColumns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'col1',
      },
      // {
      //   Header: 'Durum',
      //   accessor: 'col2',
      // },
      {
        Header: 'Uyarı',
        accessor: 'col2',
      },
      {
        Header: 'Hesap Kodu',
        accessor: 'col3',
      },
      {
        Header: 'Hesap Adı/Ünvan',
        accessor: 'col4',
      },
      {
        Header: 'Tipi',
        accessor: 'col5',
      },
      {
        Header: 'M.T.',
        accessor: 'col6',
      },
      {
        Header: 'Şirket',
        accessor: 'col7',
      },
      {
        Header: 'Email',
        accessor: 'col8',
      },
      {
        Header: 'Vergi No',
        accessor: 'col9',
      },
      {
        Header: 'Statik IP',
        accessor: 'col10',
      },
      {
        Header: 'XML Alım Durumu Aktif',
        accessor: 'col11',
      },
      {
        Header: 'XML Kayıt Durumu Aktif',
        accessor: 'col12',
      },
      {
        Header: 'Cari Aktif',
        accessor: 'col13',
      },
      {
        Header: 'Oluşturulma Zamanı',
        accessor: 'col14',
      },
      {
        Header: 'Oluşturan',
        accessor: 'col15',
      },
      {
        Header: 'Güncelleme Zamanı',
        accessor: 'col16',
      },
      {
        Header: 'Güncelleyen',
        accessor: 'col17',
      },
      {
        Header: 'Not',
        accessor: 'col18',
      },
      {
        Header: 'Expire Olmaz',
        accessor: 'col19',
      },
      {
        Header: 'İşlem',
        accessor: 'col20',
      }
    ],
    []
  )

  const tableData = useMemo(
    () =>
      filterXml.map((item, index) => {
        const dt = item.StaticIp ? item.StaticIp.split("/") : []
        let ips = ""
        dt.length > 0 && dt.forEach((spec) => (
          ips += `<div style="margin-bottom:10px">${spec}</div>`
        ))
        const data = {
          col1: index + 1,
          col2: "",
          col3: item.SAPCode.replace("0000", ""),
          col4: item.TheName,
          col5: "",
          col6: "",
          col7: item.CmpCode,
          col8: item.Email,
          col9: item.TaxNumber,
          col10: (<button
            type='button'
            className={'btn btn-sm btn-secondary'}
            onClick={() => Swal.fire({
              icon: 'info',
              title: 'IP Listesi',
              html: `<div>
              <div>${item.SAPCode.replace("0000", "")} / ${item.TheName}</div><br/>
                  ${ips}
         </div > `,
              showConfirmButton: true,
              allowOutsideClick: false,
            })}>Liste</button>),
          col11: item.XmlAktive ? "E" : "H",
          col12: item.XmlRecordActive ? "E" : "H",
          col13: item.DealerActive ? "E" : "H",
          col14: item.CreateWhen ? new Date(item.CreateWhen).toLocaleString() : "",
          col15: item.CreateUser,
          col16: item.UpdateWhen ? new Date(item.UpdateWhen).toLocaleString() : "",
          col17: item.UpdateUser,
          col18: item.Notes,
          col19: <IPCheckbox checked={item.NeverExpire} disabled={false} />,
          col20: (
            <div className='ml-2' onClick={() =>
              navigate('/admin/UserOperation/XMLDealer', {
                state: { xmlData: xml.filter((x) => { return x.Id === item.Id }), approve: false, id: 0 },
              })}>
              <i className='icon-edit' style={{ color: 'green' }}>
              </i>{' '}
            </div>),
        }

        return data
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterXml]
  )

  const getXmlList = async () => {
    if (!state.fieldOption) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen saha seçiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (state.fieldOption && state.fieldOption.value === 2 && state.field.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen hesap kodu giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const req: IListXMLRequest = {
      searchType: state.fieldOption ? +state.fieldOption.value : 0,
      dealerCode: state.field.length > 5 ? state.field.padStart(10, "0000") : state.field
    }
    props.showLoading()
    const getXmlListApi: GetXmlListApi = new GetXmlListApi({})
    await getXmlListApi
      .getXmlList(req)
      .then(result => {
        setXml(result)
        let resultData: IListXMLResponse[] = result;
        if (state.statusOption) {
          if (+state.statusOption.value === 1)
            resultData = resultData.filter((res) => { return res.XmlAktive && res.XmlRecordActive })
          if (+state.statusOption.value === 2)
            resultData = resultData.filter((res) => { return !res.XmlAktive && !res.XmlRecordActive })
          if (+state.statusOption.value === 3)
            resultData = resultData.filter((res) => { return res.DealerActive })
          if (+state.statusOption.value === 4)
            resultData = resultData.filter((res) => { return !res.DealerActive })
          if (+state.statusOption.value === 5)
            resultData = resultData.filter((res) => { return res.XmlRecordActive })
          if (+state.statusOption.value === 6)
            resultData = resultData.filter((res) => { return !res.XmlRecordActive })
        }
        if (state.companiesOption) {
          if (state.companiesOption.value === "1")
            resultData = resultData.filter((res) => { return res.CmpCode === "IDX" || res.CmpCode === "ID_" })
          if (state.companiesOption.value === "2")
            resultData = resultData.filter((res) => { return res.CmpCode === "DGX" || res.CmpCode === "DX_" })
          if (state.companiesOption.value === "3")
            resultData = resultData.filter((res) => { return res.CmpCode === "DPC" || res.CmpCode === "DP_" })
          if (state.companiesOption.value === "4")
            resultData = resultData.filter((res) => { return res.CmpCode === "NTX" || res.CmpCode === "NT_" })
          // if (state.companiesOption.value === "0")
          //   resultData = resultData.filter((res) => { return res.CmpCode === "GRP" || res.CmpCode === "GR_" })
        }
        setFilterXml(resultData)
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  // useEffect(() => {
  //   getCompanies()
  // }, [])

  const excelButton = async () => {
    try {
      if (filterXml) {
        const data: any[] = []
        if (filterXml.length > 0) {
          filterXml.map(item => {
            return data.push({
              'Bayi Kodu': item.SAPCode,
              'Bayi Adı': item.TheName,
              'Şirket': item.CmpCode,
              'Vergi No': item.TaxNumber,
              'XML Alım Durumu': item.XmlAktive ? "Aktif" : "Pasif",
              'XML Kayıt Durumu': item.XmlRecordActive ? "Aktif" : "Pasif",
              'Cari Durumu': item.DealerActive ? "Aktif" : "Pasif"
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'XML Tüm Liste')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const companiesOptions: ISelectOption[] = [
    { value: '1', label: 'IDX' },
    { value: '2', label: 'DGX' },
    { value: '3', label: 'DPC' },
    { value: '4', label: 'NTX' }
  ]
  return (
    <div className='card'>
      <div className='card-header d-flex justify-content-between'>
        <span className='h4'>XML Sorgulama/Güncelleme</span>
        <Link to='/admin/UserOperation/XMLDealer'>
          <IPButton
            type='button'
            className='btn btn-primary m-l-20'
            text='Ekle'
            style={{ width: '25px', marginRight: '10px' }}
          />
        </Link>
      </div>
      <div className='card-body'>
        <div className='row mb-3'>
          <div className='col-12 col-md-2 mb-3'>
            <IPSelectBox
              options={fieldOption}
              value={state.fieldOption}
              onChangeSingle={option => {
                setState({ fieldOption: option })
              }}
              placeholder="Saha"
            />
          </div>
          <div className='col-12 col-md-2 mb-3'>
            <IPInput
              disabled={state.fieldOption?.value === 1}
              type='text'
              id='Title'
              className='form-control'
              name='Title'
              value={state.field}
              placeholder="Saha Değeri/Koşul"
              onChange={(e) => setState({ field: e.target.value })}
            />
          </div>
          <div className='col-12 col-md-2 mb-3'>
            <IPSelectBox
              isClearable
              options={statusOption}
              value={state.statusOption}
              onChangeSingle={option => {
                setState({ statusOption: option })
              }}
              placeholder="Aktiflik Durumu"
            />
          </div>
          <div className='col-12 col-md-2 mb-3'>
            <IPSelectBox
              isClearable
              name='CompanyId'
              value={state.companiesOption}
              onChangeSingle={(option: ISelectOption) => {
                setState({ companiesOption: option })
              }}
              // options={companies.map((com: ICompany) => {
              //   return {
              //     value: `${com.Code}`,
              //     label: com.Name,
              //   }
              // })}
              options={companiesOptions}
              placeholder="Şirket Seçiniz..."
            />
          </div>
          <div className='col-12 col-md-1 mb-3'>
            <IPButton
              type='button'
              className='btn btn-success m-l-10'
              text='Göster'
              style={{ width: '25px', marginRight: '10px' }}
              onClick={getXmlList}
            />
          </div>
          <div className='col-12 col-md-3 mb-3'
            style={{ textAlign: 'right' }}>
            <div className='col-lg-12'>
              <span>Excel: </span>
              <button
                type='button'
                style={{
                  border: '1px solid #2e7d32',
                  borderRadius: 0,
                  width: '35px',
                  height: '35px',
                  backgroundColor: 'white',
                  margin: '0 10px',
                }}
                onClick={excelButton}
                className='col-lg-4'
              >
                <i
                  className='fas fa-file-excel'
                  style={{
                    marginLeft: '-6px',
                    fontSize: '18px',
                    color: '#2e7d32',
                  }}
                ></i>
              </button>
            </div>
          </div>

        </div>
        <div className='row'>
          <Table columns={tableColumns} data={tableData} currentPage={0} />
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(ListXml)