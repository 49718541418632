import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import IPButton from '../../../../../components/IPButton'
import IPModal from '../../../../../components/IPModal'
import { IProductSuggestResponse, IProductSuggestResponseHistory } from '../models/ProductSuggestListModel'
import { SuggestHistoryApi } from '../services/SuggestHistoryApi'
import { DeleteProductSuggestApi } from '../services/deleteProductSuggest'
import { GetProductSuggestApi } from '../services/getProductSuggest'
import Table from '../../../../../components/Table'

interface IAddProductSuggestState {
  history: boolean
  historyData: IProductSuggestResponseHistory[]
}

function ProductSuggestList(props: BaseComponentWithContextProps) {
  const getInitialState = (): IAddProductSuggestState => {
    return {
      history: false,
      historyData: [],
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IAddProductSuggestState>(intialState)
  let navigate = useNavigate()

  const [productSuggest, setSuggestProduct] = useState<IProductSuggestResponse[]>([])

  const showHistory = async (id: number) => {
    props.showLoading()
    const suggestHistory: SuggestHistoryApi = new SuggestHistoryApi({})
    try {
      await suggestHistory.historyByID(id).then(res => {
        setState({ history: true, historyData: res })
      })
      props.hideLoading()
    } catch (err: any) {
      props.hideLoading()
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  const getSuggestList = async () => {
    props.showLoading()
    const getProductSuggestApi: GetProductSuggestApi = new GetProductSuggestApi({})
    getProductSuggestApi
      .getProductSuggest()
      .then((result: IProductSuggestResponse[]) => {
        props.hideLoading()
        setSuggestProduct(result)
      })
      .catch((err: any) => {
        props.hideLoading()
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  useEffect(() => {
    getSuggestList()
  }, [])

  const deleteSuggest = async (id: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Ürün önermesini silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        props.showLoading()
        const deleteProductSuggestApi: DeleteProductSuggestApi = new DeleteProductSuggestApi({})
        deleteProductSuggestApi
          .deleteProductSuggest(id)
          .then(async result => {
            props.hideLoading()
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'Ürün Önermesi Silindi',
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then(async () => {
                getSuggestList()
              })
            }
          })
          .catch((err: any) => {
            props.hideLoading()
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          })
      }
    })
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
      },
      {
        Header: 'İsim',
        accessor: 'col2',
      },
      {
        Header: 'Açıklama',
        accessor: 'col3',
      },
      {
        Header: 'Önerilen Ürünler',
        accessor: 'col4',
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'colStartDate',
      },
      {
        Header: 'Bitiş Tarihi',
        accessor: 'colEndDate',
      },
      {
        Header: 'Şirket',
        accessor: 'col5',
      },
      {
        Header: 'Durum',
        accessor: 'col6',
      },
      {
        Header: 'İşlemler',
        accessor: 'col7',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      productSuggest.map((item, index) => {
        const col3Content = item.SuggestProductIds.map((productId, index) =>
          productId != 0 ? (
            <React.Fragment key={index}>
              <Link target='_blank' to={`/product/detail/${productId}?from=productList`}>
                {item.SuggestProductCodes[index]}
              </Link>
              {index < item.SuggestProductIds.length - 1 && ' , '}
            </React.Fragment>
          ) : (
            <React.Fragment key={index}>
              {item.SuggestProductCodes[index]}
              {index < item.SuggestProductIds.length - 1 && ' , '}
            </React.Fragment>
          )
        )

        const data = {
          col1: index + 1,
          col2: item.Name,
          col3: item.Info,
          col4: col3Content,
          col5: item.Company,
          colStartDate: moment(item.StartDate).format('DD.MM.YYYY HH:mm:ss'),
          colEndDate: moment(item.EndDate).format('DD.MM.YYYY HH:mm:ss'),
          col6: item.Enabled ? 'Aktif' : 'Pasif',
          col7: (
            <div>
              <a
                data-action-type='edit'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title={'düzenle'}
                onClick={() => {
                  navigate('/admin/ProductOperation/ProductSuggestAdd', {
                    state: { linkData: item, data: 'update' },
                  })
                }}
              >
                <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
              </a>
              <a
                data-action-type='delete'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title={'Sil'}
                onClick={() => deleteSuggest(item.Id)}
              >
                <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
              </a>
              <a style={{ cursor: 'pointer', color: 'blue' }} onClick={() => showHistory(item.Id)}>
                <i className='icon-clock' style={{ fontSize: '1.2rem', color: 'blue' }}></i>
              </a>
            </div>
          ),
        }

        return data
      }),
    [productSuggest]
  )

  return (
    <div className='container addProductLimitation'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Ürün Önerme Listesi</span>
            <Link to='/admin/ProductOperation/ProductSuggestAdd'>
              <IPButton
                type='button'
                className='btn btn-primary m-l-10'
                text='Ekle'
                style={{ width: '25px', marginRight: '10px' }}
              />
            </Link>
          </div>
          <div className='card-body'>
            <div className='m-4'>
              <Table columns={tableColumns} data={tableData} currentPage={0} />
            </div>
          </div>
          <IPModal
            show={state.history}
            onClose={() => setState({ history: false, historyData: [] })}
            hideConfirm={true}
            width={1200}
            title='Tarihçe'
          >
            <table className='table'>
              <thead>
                <tr role='row' className='odd'>
                  <th>#</th>
                  <th>Tarih</th>
                  <th>Ürün Kodu</th>
                  <th>Marka</th>
                  <th>Kota</th>
                  <th>Kategori</th>
                  <th>Ürün Alt Tipi</th>
                  <th>Önerilenler</th>
                  <th>Şirket</th>
                  <th>Durum</th>
                  <th>Bayi Kodu</th>
                  <th>Kullanıcı Adı</th>
                </tr>
              </thead>

              <tbody>
                {state.historyData &&
                  state.historyData.length > 0 &&
                  state.historyData.map(his => (
                    <tr role='row' className='odd' key={his.Id}>
                      <td>
                        {
                          <a
                            className='btn btn-transparent'
                            style={{ color: 'blue' }}
                            type='button'
                            data-action-type='order'
                            onClick={() => {
                              navigate('/admin/ProductOperation/ProductSuggestAdd', {
                                state: {
                                  linkData: his,
                                  data: 'history',
                                },
                              })
                            }}
                          >
                            {his.Id}
                          </a>
                        }
                      </td>
                      <td>{his.UpdateTime && moment(his.UpdateTime).format('DD.MM.YYYY HH:mm:ss')}</td>
                      <td>
                        {his.ProductList &&
                          his.ProductList.toString()
                            .split('@@')
                            .map((code, index) => (
                              <>
                                {code}
                                <br></br>
                              </>
                            ))}
                      </td>
                      <td>
                        {his.BrandList &&
                          his.BrandList.toString()
                            .split(',')
                            .map((code, index) => (
                              <>
                                {code}
                                <br></br>
                              </>
                            ))}
                      </td>
                      <td>
                        {his.QuotaList &&
                          his.QuotaList.toString()
                            .split(',')
                            .map((code, index) => (
                              <>
                                {code}
                                <br></br>
                              </>
                            ))}
                      </td>
                      <td>
                        {his.CatList &&
                          his.CatList.toString()
                            .split(',')
                            .map((code, index) => (
                              <>
                                {code}
                                <br></br>
                              </>
                            ))}
                      </td>
                      <td>
                        {his.ProductSubTypeList &&
                          his.ProductSubTypeList.toString()
                            .split('@@')
                            .map((code, index) => (
                              <>
                                {code}
                                <br></br>
                              </>
                            ))}
                      </td>
                      <td>
                        {his.SuggestProductCodes &&
                          his.SuggestProductCodes.toString()
                            .split('@@')
                            .map((code, index) => (
                              <>
                                {code}
                                <br></br>
                              </>
                            ))}
                      </td>
                      <td>
                        {his.Company === 1
                          ? 'Index'
                          : his.Company === 2
                            ? 'Netex'
                            : his.Company === 3
                              ? 'Despec'
                              : his.Company === 4
                                ? 'Datagate'
                                : ''}
                      </td>
                      <td>{his.Enabled ? 'Aktif' : 'Pasif'}</td>
                      <td>{his.UserDealer && his.UserDealer.replace('0000', '')}</td>
                      <td>{his.UserName}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </IPModal>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(ProductSuggestList)
