import { useCustomReducer } from '../../../../../base/customHooks'
import IPButton from '../../../../../components/IPButton'
import { useMemo, useState } from 'react'
import '../../AccountSummary/AccountSummary.scss'
import Swal from 'sweetalert2'
import InputCampaign from '../../../../../components/InputCampaign'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { exportToXLSX, formatDate } from '../../../../../base/utils/dataHelper'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import Table from '../../../../../components/Table'
import IPExcelButton from '../../../../../components/IPExcelButton'
import { IPO, PODocRequest } from '../models'
import { GetPOApi } from '../services/getPODocApi'
import IPCheckbox from '../../../../../components/IPCheckBox'
import { InsertLogApi } from '../../Provizyon/services/insertLogApi'
import { GetLogRequest } from '../../Provizyon/models'

interface IPOState {
  selectedCompany?: ISelectOption[]
}

function PODocument(props: BaseComponentWithContextProps) {
  const getInitialState = (): IPOState => {
    return {
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IPOState>(intialState)
  const [poData, setPoData] = useState<IPO[]>([])

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  const [currentPage, setCurrentPage] = useState(0)

  const getPo = async () => {
    if (!state.selectedCompany || state.selectedCompany?.length === 0) {
      return Swal.fire({
        icon: 'error',
        title: 'Şirket seçimi zorunludur!',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }

    if (!startDate || !endDate) {
      return Swal.fire({
        icon: 'error',
        title: 'Tarih seçimi zorunludur!',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
    const req: PODocRequest = {
      StartDate: formatDate(startDate),
      EndDate: formatDate(endDate),
      CompanyList: state.selectedCompany ? (state.selectedCompany.map((item: ISelectOption) => item.value) as any) : [],
    }
    props.showLoading()
    const getPoDoc: GetPOApi = new GetPOApi({})
    getPoDoc
      .getPODoc(req)
      .then(result => {
        if (result) {
          setPoData(result)
          if (result.length === 0) {
            Swal.fire({
              icon: 'warning',
              title: 'Fiyat farkı verisi bulunamadı',
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          }
          props.hideLoading()
        } else {
          console.warn('PO result is empty.')
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        if (!err.description || err.description === 'OK') {
          props.hideLoading()
          Swal.fire({
            icon: 'error',
            title: 'Fiyat farkı verisi bulunamadı',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        } else {
          props.hideLoading()
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      })
    return
  }

  const faturaKes = async (checked: boolean, belNo: string) => {
    const req: GetLogRequest = {
      Check: checked,
      BelNo: belNo,
      Type: "FiyatFarkı"
    }
    props.showLoading()
    const insertLog: InsertLogApi = new InsertLogApi({})
    insertLog
      .insertLogApi(req)
      .then(result => {
        if (result) {
          setPoData(prevData =>
            prevData.map(item =>
              item.FAT_NO === belNo
                ? { ...item, FaturaKestim: checked }
                : item
            )
          )
          props.hideLoading()
        } else {
          console.warn('Log error')
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        if (!err.description || err.description === 'OK') {
          props.hideLoading()
          Swal.fire({
            icon: 'error',
            title: 'Provizyon bulunamadı',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        } else {
          props.hideLoading()
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      })
    return
  }


  const companyOptions = [
    { value: '1000', label: 'Index' },
    { value: '1100', label: 'Datagate' },
    { value: '1200', label: 'Despec' },
    { value: '2300', label: 'Netex' },
  ]

  // Tablo başlıkları
  const columnDefinitions = [
    // { Header: 'Bayi', accessor: 'bayi' },
    // { Header: 'Ünvan', accessor: 'unvan' },
    { Header: 'Fatura Kestim', accessor: 'col1' },
    { Header: 'Şirket', accessor: 'company' },
    { Header: 'Fatura No', accessor: 'fatNo' },
    { Header: 'Fatura Tarihi', accessor: 'tarih' },
    { Header: 'EFatura No', accessor: 'efat' },
    { Header: "Yıl", accessor: 'yil' },
    { Header: 'Tutar', accessor: 'tutar' },
    { Header: 'Para Birimi', accessor: 'paraBirimi' },
    { Header: 'Kur', accessor: 'kur' },
    { Header: 'MT', accessor: 'mt' },

  ]

  const tableColumns = useMemo(() => columnDefinitions, [])

  const tableDataAndTotal = useMemo(() => {
    const tableData = poData.map(item => {
      const data = {
        // bayi: item.BAYI,
        // unvan: item.UNVAN,
        col1: (
          <IPCheckbox
            label=''
            onCheckedChange={(checked: boolean) => faturaKes(checked, item.FAT_NO)}
            checked={item.FaturaKestim}
          />
        ),
        company: companyOptions.find(a => a.value === item.COMPANY)?.label,
        fatNo: item.FAT_NO,
        tarih: item.FAT_TARIHI,
        efat: item.EFATURA_NO,
        yil: item.YIL,
        tutar: item.TUTAR,
        paraBirimi: item.PARA_BIRIMI,
        kur: item.KUR,
        mt: item.MT_AD
      }
      return data
    })
    return {
      tableData,
    }
  }, [poData])

  const excelButton = async () => {
    try {
      if (poData) {
        const data: any[] = []
        if (poData.length > 0) {
          poData.map((item, index) => {
            return data.push({
              // "Bayi": item.BAYI,
              // "Ünvan": item.UNVAN,
              "Şirket": companyOptions.find(a => a.value === item.COMPANY)?.label,
              "Fatura No": item.FAT_NO,
              "Fatura Tarihi": item.FAT_TARIHI,
              "EFatura No": item.EFATURA_NO,
              "Yıl": item.YIL,
              "Tutar": item.TUTAR,
              "Para Birimi": item.PARA_BIRIMI,
              "Kur": item.KUR,
              "MT": item.MT_AD
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Fiyat Farkı')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  return (
    <div className='Reports'>
      <div className='heading-text d-flex justify-content-between align-items-center'>
        <h4 style={{ textAlign: 'center' }}>Fiyat Farkı Raporu</h4>
        <div className='d-flex align-items-center'>
          <IPExcelButton onClick={excelButton} />
        </div>
      </div>
      <form>
        <div className='form-row'>
          <div className='form-group col-md-3'>
            <InputCampaign
              type='date'
              id='StartDate'
              name='StartDate'
              label={'Başlangıç Tarihi'}
              //value={values.dateStart}
              value={toDateTimeLocal(startDate)}
              onChange={e => getDate(e.target.value, 'Start')}
              isActive
            />
          </div>
          <div className='form-group col-md-3'>
            <InputCampaign
              type='date'
              id='EndDate'
              name='EndDate'
              label={'Bitiş Tarihi'}
              //value={values.endDate}
              value={toDateTimeLocal(endDate)}
              onChange={e => getDate(e.target.value, 'End')}
              isActive
            />
          </div>
          <div className='form-group col-md-3'>

          </div>
          <div className='form-group col-md-3'>
            <IPButton
              text='Listele'
              className='button'
              onClick={() => getPo()}
              style={{
                backgroundColor: 'red',
                color: 'white',
                borderRadius: '10px',
                width: '150px',
                height: '50px',
              }}
            />
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group col-md-7'>
            <div className='labeltext'>
              <IPSelectBox
                isMulti
                isClearable
                isCreatableSelect
                id='ProductCode'
                name='ProductCode'
                isSearchable
                onChangeMulti={(options: ISelectOption[]) => {
                  setState({ selectedCompany: options })
                }}
                options={companyOptions}
                value={state.selectedCompany}
              />
            </div>
          </div>
        </div>
      </form>
      <div className='m-4'>
        <Table
          columns={tableColumns}
          data={tableDataAndTotal.tableData}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          headerName={`İşlem Tarihi Aralığı: ${new Date(startDate).toLocaleDateString()} - ${new Date(
            endDate
          ).toLocaleDateString()}`}
          columnCount={11}
          backgroundColor='red'
          color='white'
        />
      </div>
    </div>
  )
}
export default componentWithContext(PODocument)
