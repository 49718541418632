import { IUser } from './models/response/getUserListResponseModel'
import { useCustomReducer } from '../../../base/customHooks'
import { useNavigate } from 'react-router-dom'
import { DeleteUserApi } from './services/deleteUserApi'
import Translations from '../../../translations'
import { translate } from '../../../base/localization'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import $ from 'jquery'
import { GetUsersApi } from './services/getUsersApi'
import Swal from 'sweetalert2'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'
import { logoutUser } from '../../../base/proxy/authenticate'
import Table from '../../../components/Table'
import IPCheckbox from '../../../components/IPCheckBox'
import IPInput from '../../../components/IPInput'
interface IListUserState {
  userLists?: IUser[]
  userListsFilter?: IUser[]
}

function Index(props: BaseComponentWithContextProps) {
  const getInitialState = (): IListUserState => {
    return {
      userLists: [],
      userListsFilter: [],
    }
  }

  const t = translate(Translations)
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListUserState>(intialState)
  const [searchValue, setSearchValue] = useState<string>()
  const navigate: any = useNavigate()
  //const tableRef = useRef<any>()

  useEffect(() => {
    getUsers()
  }, [])

  const getUsers = async () => {
    props.showLoading()
    try {
      const getUsersApi: GetUsersApi = new GetUsersApi({})

      const res = await getUsersApi.getUsers()
      if (res) {
        props.hideLoading()
        setState({ userLists: res, userListsFilter: res })
      }
    }
    catch {
      props.hideLoading()
    }
  }

  useEffect(() => {
    if (state.userLists) {
      if (searchValue?.trim()) {
        setState({
          userListsFilter: state.userLists.filter(a =>
            a.Name?.includes(searchValue) ||
            a.Surname?.includes(searchValue) ||
            a.Email?.includes(searchValue) ||
            a.UserCode?.includes(searchValue) ||
            a.UserName?.includes(searchValue) ||
            (`${a.Name} ${a.Surname}`.includes(searchValue))
          )
        })
      } else {
        setState({ userListsFilter: state.userLists })
      }
    }
  }, [searchValue])

  const columns = useMemo(
    () => [
      { Header: 'Id', accessor: 'col1' },
      { Header: 'Hesap Kodu', accessor: 'col2' },
      { Header: 'Kullanıcı Adı', accessor: 'col3' },
      { Header: 'Ad', accessor: 'col4' },
      { Header: 'Soyad', accessor: 'col5' },
      { Header: 'E-Posta', accessor: 'col6' },
      { Header: 'Durum', accessor: 'col7' },
      { Header: 'İşlemler', accessor: 'col8' },
    ],
    []
  )

  const data = useMemo(
    () =>
      state.userListsFilter?.map((t) => ({
        col1: t.Id,
        col2: t.UserCode && t.UserCode.replace('0000', ''),
        col3: t.UserName,
        col4: t.Name,
        col5: t.Surname,
        col6: t.Email,
        col7: <IPCheckbox checked={t.Enable} />,
        col8: <div className='d-flex gap-3'>
          <i className='icon-edit' style={{ color: 'green', cursor: 'pointer' }} onClick={() => navigate('/myspecial/AddNewUser', { state: { userData: t } })}></i>
          <i className='icon-trash-2' style={{ color: 'red', cursor: 'pointer' }} onClick={() => {
            if (props.user.UserId === t.Id) {
              var message = "Kendi kullanıcınızı silmek istediğinize emin misiniz?";
            }
            else {
              var message = "Kullanıcıyı silmek istediğinize emin misiniz?";
            }
            Swal.fire({
              icon: 'question',
              title: message,
              showConfirmButton: true,
              allowOutsideClick: false,
              showDenyButton: true,
              confirmButtonText: 'Evet',
              denyButtonText: 'Hayır',
            }).then(async result => {
              if (result.isConfirmed) {
                const deleteUserApi = new DeleteUserApi({})

                const isDeleted = await deleteUserApi.deleteUser(t.Id)
                if (isDeleted) {
                  setState({
                    userLists: state.userLists?.filter((user: IUser) => {
                      return user.Id !== t.Id
                    }),
                  })
                  Swal.fire({
                    icon: 'success',
                    title: 'Kullanıcı silindi',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                  })
                  if (props.user.UserId === t.Id) {
                    logoutUser()
                  }
                }
              }
            })
          }
          }></i>
          <i className='fa fa-unlock-alt' style={{ color: 'blue', cursor: 'pointer' }} onClick={() => navigate('/myspecial/Password', { state: { userId: t.Id } })} ></i>
        </div>
      })),
    [state.userLists, state.userListsFilter]
  )

  // const columnDefs = [
  //   {
  //     title: '#',
  //     data: 'Id',
  //   },
  //   {
  //     title: t('pages.mySpecial.userUpdate.accountCode'),
  //     data: 'UserCode',
  //     render: data => {
  //       // Remove leading zeros using parseInt
  //       const userCode = parseInt(data, 10); // 10 is the radix (base)
  //       return isNaN(userCode) ? data : userCode;
  //     },
  //   },
  //   {
  //     title: t('pages.mySpecial.userUpdate.userName'),
  //     data: 'UserName',
  //   },
  //   {
  //     title: 'Ad',
  //     data: 'Name',
  //   },
  //   {
  //     title: 'Soyad',
  //     data: 'Surname',
  //   },
  //   {
  //     title: t('pages.mySpecial.userUpdate.email'),
  //     data: 'Email',
  //   },
  //   {
  //     title: t('pages.mySpecial.userUpdate.status'),
  //     data: 'Enable',
  //     render: data => {
  //       return `<input type="checkbox" ${data ? 'checked' : ''} onclick="return false;" />`
  //     },
  //   },
  //   {
  //     title: t('pages.mySpecial.userUpdate.operations'),
  //     data: 'Id',
  //     render: (data: any) => {
  //       return `<a data-action-type="edit" role="button" style="cursor:pointer" data-toggle="tooltip" data-original-title={t('pages.mySpecial.userUpdate.edit')}>
  //       <i class='icon-edit' style="font-size:1.2rem; color:green"></i></a>
  //       <a data-action-type="delete" role="button" style="cursor:pointer" data-toggle="tooltip" data-original-title={t('pages.mySpecial.userUpdate.delete')}>
  //       <i class='icon-trash-2' style="font-size:1.2rem;color:red"></i></a>
  //       <a data-action-type="changePassword" role="button" style="cursor:pointer" data-toggle="tooltip" data-original-title={t('pages.mySpecial.userUpdate.changePassword')}>
  //       <i class='fa fa-unlock-alt' style="font-size:1.2rem;color:blue"></i></a>`
  //     },
  //   },
  // ]

  // useEffect(() => {
  //   const dt = syncTable()

  //   $('#datatb tbody').on('click', 'a', function () {
  //     const actionType: 'edit' | 'delete' | 'changePassword' = this.getAttribute('data-action-type')

  //     var data = dt.row($(this).parents('tr')).data()
  //     if (data) {
  //       if (actionType === 'edit') {
  //         navigate('/myspecial/AddNewUser', { state: { userData: data } })
  //       } else if (actionType === 'changePassword') {
  //         navigate('/myspecial/Password', { state: { userId: data.Id } })
  //       } else if (actionType === 'delete') {
  //         if (props.user.UserId === data.Id) {
  //           var message = "Kendi kullanıcınızı silmek istediğinize emin misiniz?";
  //         }
  //         else {
  //           var message = "Kullanıcıyı silmek istediğinize emin misiniz?";
  //         }
  //         Swal.fire({
  //           icon: 'question',
  //           title: message,
  //           showConfirmButton: true,
  //           allowOutsideClick: false,
  //           showDenyButton: true,
  //           confirmButtonText: 'Evet',
  //           denyButtonText: 'Hayır',
  //         }).then(async result => {
  //           if (result.isConfirmed) {
  //             const deleteUserApi = new DeleteUserApi({})

  //             const isDeleted = await deleteUserApi.deleteUser(data.Id)
  //             if (isDeleted) {
  //               setState({
  //                 userLists: state.userLists?.filter((user: IUser) => {
  //                   return user.Id !== data.Id
  //                 }),
  //               })
  //               Swal.fire({
  //                 icon: 'success',
  //                 title: 'Kullanıcı silindi',
  //                 showConfirmButton: true,
  //                 allowOutsideClick: false,
  //               })
  //               if (props.user.UserId === data.Id) {
  //                 logoutUser()
  //               }
  //             }
  //           }
  //         })
  //       }
  //     }
  //   })

  //   return function () {
  //     dt.destroy()
  //   }
  // }, [state.userLists])

  // const syncTable = () => {
  //   const data = state.userLists
  //   const dt = $(tableRef.current).DataTable({
  //     data: data,
  //     columns: columnDefs,
  //     ordering: true,
  //   })
  //   return dt
  // }

  return (
    <React.Fragment>
      <div className='container-fluid userupdate'>
        <div className='row'>
          <div className='heading-text'>
            <h4>{t('pages.mySpecial.userUpdate.header')}</h4>
          </div>
          <div className='col-lg-6 text-right'>
            <div id='export_buttons' className='mt-2'>
              <div className='dt-buttons btn-group'></div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12 '>
            <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='row'>
                    <div className='col-12 col-md-1 mt-2'>
                      <label>Arama: </label>
                    </div>
                    <div className='col-12 col-md-6 mb-3'>
                      <IPInput
                        type={'text'}
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ width: '100%', overflowX: 'auto' }}>
                    {state.userListsFilter && state.userListsFilter.length > 0 ? <Table columns={columns} data={data} currentPage={0} /> : 'Veri bulunamadı.'}
                    {/* <table
                      id={'datatb'}
                      ref={tableRef}
                      className='table table-responsive d-lg-table d-sm-block'
                      style={{ minWidth: '100%' }}
                    ></table> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  )
}

export default componentWithContext(Index)