import React, { useEffect, useMemo } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { useNavigate } from 'react-router-dom'
import { ListDppManagementPageDataApi } from '../services/ListDppManagementPageDataApi'
import Swal from 'sweetalert2'
import { IDeleteData, IDppManagementPageData, IItems, ITableData } from '../models/models'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPModal from '../../../../../components/IPModal'
import { DeleteDppManagementPageApi } from '../services/deleteDppManagementPageApi'
import Table from '../../../../../components/Table'

interface IListDppAndMicrosoftState {
  dppAndMicrosoftData: ITableData[]
  showDetail: boolean
  detail?: ITableData
  title: string
  field: string
}

function List(props: BaseComponentWithContextProps) {
  useEffect(() => {
    getDppAndMicrosoftPageList()
  }, [])

  const getInitialState = (): IListDppAndMicrosoftState => {
    return {
      dppAndMicrosoftData: [],
      showDetail: false,
      title: "",
      field: ""
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListDppAndMicrosoftState>(intialState)

  const getDppAndMicrosoftPageList = async () => {
    props.showLoading();
    const listDppManagementPageDataApi: ListDppManagementPageDataApi = new ListDppManagementPageDataApi({})

    listDppManagementPageDataApi
      .listDppManagementPageData()
      .then((res: IDppManagementPageData) => {
        props.hideLoading();
        const data: ITableData[] = []
        res.PagesInfo.map((info) => (
          info.PagesDetail.map((detail) => {
            return data.push({
              Page: info.Name,
              Field: detail.Name,
              FieldId: detail.IdLocation,
              Items: detail.PagesDetail.Items,
              IdPage: info.IdPage
            })
          })
        ))
        setState({ dppAndMicrosoftData: data })
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading();
      })
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'col1',
      },
      {
        Header: 'Sayfa',
        accessor: 'col2',
      },
      {
        Header: 'Bölüm',
        accessor: 'col3',
      },
      {
        Header: 'Detay',
        accessor: 'col4',
      },
      {
        Header: 'İşlemler',
        accessor: 'col5',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      state.dppAndMicrosoftData.map((item, index) => {
        const data = {
          col1: index + 1,
          col2: item.Page,
          col3: item.Field,
          col4: (
            item.Items && item.Items.length > 0 &&
            <button
              type='button'
              className={'btn btn-sm btn-secondary'}
              onClick={() => {
                setState({
                  showDetail: true,
                  detail: {
                    FieldId: item.FieldId,
                    Page: item.Page,
                    Field: item.Field,
                    Items: item.Items,
                    IdPage: item.IdPage
                  },
                  title: item.Page,
                  field: item.Field
                })
              }}
            >
              Detay
            </button>
          ),
          col5: (
            <a
              data-action-type='edit'
              role='button'
              style={{ cursor: 'pointer' }}
              data-toggle='tooltip'
              data-original-title="Güncelle"
              onClick={() => updateDppAndMicrosoftPages(item.IdPage, item.FieldId)}
            >
              <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
            </a>
          ),
        }
        return data
      }),
    [state.dppAndMicrosoftData]
  )

  const tableColumns2 = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'col1',
      },
      {
        Header: 'Resim',
        accessor: 'col2',
      },
      {
        Header: 'Başlık',
        accessor: 'col3',
      },
      {
        Header: 'Ad',
        accessor: 'col4',
      },
      {
        Header: 'Link',
        accessor: 'col5',
      },
      {
        Header: 'Sıra',
        accessor: 'col6',
      },
      {
        Header: 'Detay Resim',
        accessor: 'col7',
      },
      // {
      //   Header: 'İşlemler',
      //   accessor: 'col7',
      // },
    ],
    []
  )

  const tableData2 = useMemo(
    () =>
      state.detail && state.detail?.Items.map((item, index) => {
        const data = {
          col1: index + 1,
          col2: <img src={item.Image} alt='' style={{ width: 100 }} />,
          col3: item.IsTitle ? "Evet" : "Hayır",
          col4: item.Name,
          col5: item.Link,
          col6: item.OrderNo,
          col7: <img src={item.DetailImage} alt='' style={{ width: 100 }} />,
          // col7: (
          //   <a
          //     data-action-type='delete'
          //     role='button'
          //     style={{ cursor: 'pointer' }}
          //     data-toggle='tooltip'
          //     data-original-title="Sil"
          //     onClick={() => deleteDppAndMicrosoftPages(item.Id, state.detail?.IdPage)}
          //   >
          //     <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
          //   </a>
          // ),
        }
        return data
      }),
    [state.detail]
  )

  const deleteDppAndMicrosoftPages = async (id: number, pageId?: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        props.showLoading();
        const req: IDeleteData = {
          PageType: pageId ? pageId : 0,
          ItemId: id
        }
        const deleteDppManagementPageApi: DeleteDppManagementPageApi = new DeleteDppManagementPageApi({})
        deleteDppManagementPageApi
          .deleteDppManagementPage(req)
          .then(async result => {
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'Silindi',
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then(async () => {
                const items: IItems[] = []
                if (state.detail) {
                  state.detail.Items.filter((detail) => {
                    return detail.Id !== id
                  }).map((itm) => {
                    return items.push(itm)
                  })
                  if (items.length === 0) {
                    const dppAndMicrosoftData = state.dppAndMicrosoftData.filter((dpp) => { return dpp.IdPage === pageId })
                    return setState({
                      detail: {
                        FieldId: 0,
                        Page: "",
                        Field: "",
                        Items: [],
                        IdPage: 0
                      },
                      showDetail: false,
                      dppAndMicrosoftData: dppAndMicrosoftData
                    })
                  }
                  const detailData: ITableData = {
                    FieldId: state.detail?.FieldId,
                    Page: state.detail?.Page,
                    Field: state.detail?.Field,
                    Items: items,
                    IdPage: state.detail?.IdPage
                  }
                  return setState({ detail: detailData })

                }
              })
            }
            props.hideLoading();
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading();
          })
      }
    })
  }

  const navigate: any = useNavigate()
  const updateDppAndMicrosoftPages = async (id: number, fieldId: number) => {
    var dt = state.dppAndMicrosoftData.find(auth => {
      return auth.IdPage === id && auth.FieldId === fieldId
    })
    navigate('/admin/homeAndSiteOperation/dppAndMicrosoftPagesAdd', {
      state: { dppAndMicrosoft: dt },
    })
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>DPP ve Microsoft Sayfaları Listeleme</span>
            {/* <Link to='/admin/homeAndSiteOperation/dppAndMicrosoftPagesAdd'>
              <IPButton
                type='button'
                className='btn btn-primary m-l-10'
                text='Ekle'
                style={{ width: '25px', marginRight: '10px' }}
              />
            </Link> */}
          </div>
          <div className='card-body'>
            <Table columns={tableColumns} data={tableData} currentPage={0} />
          </div>
        </div>
      </div>
      <IPModal
        show={state.showDetail}
        onClose={() =>
          setState({
            showDetail: false,
            detail: {
              FieldId: 0,
              Page: "",
              Field: "",
              Items: [],
              IdPage: 0
            }
          })}
        hideConfirm={true}
        width={1000}
        title={`${state.title} ${state.field} Detayı`}
      >
        <Table columns={tableColumns2} data={tableData2} currentPage={0} />
      </IPModal>
    </div >
  )
}
export default componentWithContext(List)