import { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'

import Translations from '../../../translations'
import { translate } from '../../../base/localization'
import { componentWithContext } from '../../../base/customHooks/componentHOC'
import { useCustomReducer } from '../../../base/customHooks'

import IPButton from '../../../components/IPButton'
import IPInput from '../../../components/IPInput'
import IPRadio from '../../../components/IPRadio'
import IPFile from '../../../components/IPFile/IPFileRegister'
import IPCheckbox from '../../../components/IPCheckBox'
import IPSelectBox from '../../../components/IPSelectBox'
import validationSchema from './validation'

import { SellerApplicationService } from '../services/sellerApplicationService'
import { SellerApplicationRequestModel } from '../models/request/sellerApplicationRequestModel'
import {
  getUploadFileRequestSplit,
  isValidIBAN,
  isValidPostCode,
  isValidTCKN,
  isValidVKN,
  uuidv4,
} from '../../../base/utils/dataHelper'
import IPModal from '../../../components/IPModal'
import InputMask from '../../../components/InputMask'
import './SellerApplication.scss'
import { IPInputDataPattern } from '../../../components/IPInput/IPInput'
import { css } from '@emotion/css'

import ReCaptcha from 'react-google-recaptcha'
import { useNavigate, useLocation } from 'react-router-dom'
import kibris from '../../../api/cyprus.json'

interface IStatusOptions {
  code: number
  text: string
  color: string
}

interface ISelectOption {
  value: string | number
  label: string
  isDisabled?: boolean
  tabSize?: number
  PostCode?: string
}
interface IStates {
  selectedCountry?: ISelectOption
  selectedInvoiceTaxpayer?: ISelectOption
  selectedFirmType?: ISelectOption
  selectedInvoiceUsage?: ISelectOption
  selectedIban1?: ISelectOption
  selectedIban2?: ISelectOption
  selectedIban3?: ISelectOption
  selectedIban4?: ISelectOption
  selectedDistrict?: ISelectOption
  selectedCity?: ISelectOption
  status?: IStatusOptions
  statusCode: number
  showModal: boolean
  loadedFiles?: any[]
  statusInformation?: any[]
  isReadOnly?: boolean
  isFileDisabled?: boolean
  selectedId?: number
  dealerControl?: boolean
  isAuthorizedTCValid: boolean
  isTCValid: boolean
  isTaxValid: boolean
  isIBANValid: boolean
  isIBANValid2: boolean
  isIBANValid3: boolean
  isIBANValid4: boolean
  isIBANType2Valid: boolean
  isIBANType3Valid: boolean
  isIBANType4Valid: boolean
  isPostCodeValid: boolean
  cityOptions: ISelectOption[]
  townOptions: ISelectOption[]
  neighbourhoodOptions: ISelectOption[]
  fetchedSellerResponse?: SellerApplicationRequestModel
  isReCaptchaValid: boolean
  trackingNumber: string
}

const RECAPTCHA_KEY = '6LdfrCUUAAAAAKh4XTEprI6Fvx8dkl-Ug5Hd66Pr'

function BayiForms(props) {
  const t = translate(Translations)
  const getInitialState = (): IStates => {
    return {
      showModal: false,
      dealerControl: true,
      isAuthorizedTCValid: true,
      isTCValid: true,
      isTaxValid: true,
      isIBANValid: true,
      isIBANValid2: true,
      isIBANValid3: true,
      isIBANValid4: true,
      isIBANType2Valid: true,
      isIBANType3Valid: true,
      isIBANType4Valid: true,
      isPostCodeValid: true,
      cityOptions: [],
      townOptions: [],
      neighbourhoodOptions: [],
      isReCaptchaValid: false,
      statusCode: 0,
      trackingNumber: '',
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IStates>(intialState)
  const [isCapsLockOn, setIsCapsLockOn] = useState(false)

  // This function is triggered on the keyup event
  const checkCapsLock = event => {
    if (event.getModifierState('CapsLock')) {
      setIsCapsLockOn(true)
    } else {
      setIsCapsLockOn(false)
    }
  }

  const StatusOptions: IStatusOptions[] = [
    { code: 1, text: 'Yeni Giriş', color: 'black' },
    { code: 2, text: 'Eksik Evrakı Olanlar', color: '#FFC800' },
    { code: 3, text: 'Sözleşme PDF Oluşturuldu', color: '#FFC800' },
    { code: 4, text: 'Sözleşme Onay Maili Gönderildi', color: 'green' },
    { code: 5, text: 'Sözleşme Bayi Tarafından Onaylandı', color: 'green' },
    { code: 6, text: 'Tamamlandı', color: 'green' },
    { code: 7, text: 'Başvurunuz Onaylanmadı', color: 'red' },
  ]

  const ICountryOption: ISelectOption[] = [
    { label: t('pages.register.bayiForms.turkish'), value: 'TR' },
    { label: t('pages.register.bayiForms.cyprus'), value: 'CY' },
  ]
  const InvoiceTypeOption: ISelectOption[] = [
    { label: t('pages.register.bayiForms.eArchive'), value: 1 },
    { label: t('pages.register.bayiForms.eInvoice'), value: 2 },
  ]
  const IInvoiceUsageOption: ISelectOption[] = [
    { label: t('pages.register.bayiForms.doesNotUse'), value: '1' },
    { label: t('pages.register.bayiForms.basic'), value: '2' },
    { label: t('pages.register.bayiForms.commercial'), value: '3' },
  ]
  const IFirmTypeOption: ISelectOption[] = [
    { label: 'Şahıs', value: 1 },
    { label: 'Ltd./A.Ş.', value: 2 },
  ]
  const IIbanOption: ISelectOption[] = [
    { label: 'TRY', value: 1 },
    { label: 'USD', value: 2 },
    { label: 'EURO', value: 3 },
  ]

  const getCityCyprus = async () => {
    const data = kibris.İl.map(item => {
      return { label: item.Adı, value: item.Number }
    })
    setState({ cityOptions: data })
    state.cityOptions = data
  }

  const getTownCyprus = async (data: string) => {
    let filteredIlce = [] as any
    kibris.İl.forEach(a => {
      if (a.Adı === data) {
        filteredIlce = a.İlçeler
      }
    })

    const datam = filteredIlce.map(b => {
      return {
        label: b.Adı,
        value: b.Number,
      }
    })

    setState({ townOptions: datam })
    state.townOptions = datam
  }

  const reCaptchaRef = useRef<any>(null)

  const getCity = async () => {
    props.showLoading && props.showLoading()
    const service = new SellerApplicationService({})
    const res = await service.getCity()
    if (res) {
      const data = res.map(item => {
        return { label: item.Il, value: item.PlakaKodu.toString() }
      })
      setState({ cityOptions: data })
      state.cityOptions = data
    }
    props.hideLoading && props.hideLoading()
  }

  const getTown = async (plate: number) => {
    const service = new SellerApplicationService({})
    const res = await service.getTown(plate)
    if (res) {
      const data = res.map(item => {
        return {
          label: item.IlceAdi,
          value: item.IlceKodu,
        }
      })
      setState({ townOptions: data })
      state.townOptions = data
    }
  }

  const getNeighbourhood = async (townCode: number) => {
    const service = new SellerApplicationService({})
    const res = await service.getDistrict(townCode)
    if (res) {
      const data = res.map(item => {
        return {
          label: item.MahalleAdi,
          value: item.MahalleAdi,
          PostCode: item.PostaKodu,
        }
      })
      setState({ neighbourhoodOptions: data })
      state.neighbourhoodOptions = data
    }
  }

  useEffect(() => {
    if (state.selectedCountry?.value === 'TR') getCity()
    else if (state.selectedCountry?.value === 'CY') getCityCyprus()
  }, [state.selectedCountry, state.selectedCity])

  const handleFiles = async (files: any[]) => {
    const event = Object.assign({})
    event.target = Object.assign({})
    event.target.files = files

    const fileList = await getUploadFileRequestSplit(event)
    setFieldValue('Files', fileList)
  }
  const [selectedOption, setSelectedOption] = useState('taxNum')
  const [selectedNationality, setSelectedNationality] = useState('TC')
  const [selectedCompanyTypeOption, setSelectedCompanyTypeOption] = useState(0)

  const { handleSubmit, handleChange, handleBlur, setFieldValue, setValues, validateField, values, errors, touched } =
    useFormik({
      initialValues: {
        CompanyName: '',
        Address: '',
        PostCode: '',
        Country: { value: '', label: '' },
        Phone: '',
        MobilePhone: '',
        Fax: '',
        InvoiceType: { value: '', label: '' },
        CompanyType: { value: '', label: '' },
        TaxOrTcNumber: '',
        IbanNumber1: '',
        IbanNumberType1: { value: '', label: '' },
        IbanNumber2: '',
        IbanNumberType2: { value: 0, label: '' },
        IbanNumber3: '',
        IbanNumberType3: { value: 0, label: '' },
        IbanNumber4: '',
        IbanNumberType4: { value: 0, label: '' },

        AuthorizedNameSurname: '',
        AuthorizedTC: '',
        City: { value: '', label: '' },
        Town: { value: '', label: '' },
        Neighbourhood: { value: '', label: '' },
        InvoiceUsageType: IInvoiceUsageOption[1],
        Email: '',
        MersisNo: '',
        TaxAdministration: '',

        CommercialMessage: '',
        KvkApproval: false,
        Files: [
          {
            Label: '',
            Content: '',
            Extension: '',
            FileType: '',
          },
        ],
        TrackingNumber: '',
      },

      onSubmit: async values => {
        const request: SellerApplicationRequestModel = {
          CompanyName: values.CompanyName,
          Address: values.Address,
          Country: values.Country.value,
          Phone: values.Phone,
          Fax: values.Fax,
          InvoiceType: Number(values.InvoiceType.value),
          CompanyType: Number(values.CompanyType.value),
          TaxOrTcNumber: values.TaxOrTcNumber.replaceAll('_', ''),
          AuthorizedNameSurname: values.AuthorizedNameSurname,
          AuthorizedTC: values.AuthorizedTC,
          City: values.City.value.toString(),
          Town: values.Town.value.toString(),
          Neighbourhood: values.Neighbourhood.value.toString(),
          PostCode: values.PostCode,
          MobilePhone: values.MobilePhone,
          Email: values.Email,
          InvoiceUsageType: Number(values.InvoiceUsageType.value),
          MersisNo: values.MersisNo,
          TaxAdministration: values.TaxAdministration,
          IbanNumber1: values.IbanNumber1,
          IbanNumberType1: Number(values.IbanNumberType1.value),
          IbanNumber2: values.IbanNumber2,
          IbanNumberType2: Number(values.IbanNumberType2.value),
          IbanNumber3: values.IbanNumber3,
          IbanNumberType3: Number(values.IbanNumberType3.value),
          IbanNumber4: values.IbanNumber4,
          IbanNumberType4: Number(values.IbanNumberType4.value),
          CommercialMessage: values.CommercialMessage === '1' ? true : false,
          KvkApproval: values.KvkApproval,
          Files: values.Files,
          Id: state.selectedId ? state.selectedId : 0,
          ReKey: reCaptchaRef.current ? reCaptchaRef.current.getValue() : '',
        }
        if (state.trackingNumber) request.TrackingNumber = values.TrackingNumber

        try {
          props.showLoading && props.showLoading()

          const sellerApplication = new SellerApplicationService({})
          const res = await sellerApplication.postSellerApplication(request)

          props.hideLoading && props.hideLoading()

          Swal.fire({
            icon: 'success',
            title: 'Başvuru Talebiniz Alınmıştır.',
            html: `<h4>Takip Kodu</h4><Link to='/register/${res.TrackingNumber}'>${res.TrackingNumber}</Link>`,
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(() => window.location.reload())
        } catch (err: any) {
          console.error('Submission Error:', err)
          props.hideLoading && props.hideLoading()
          Swal.fire({
            icon: 'error',
            title: 'Hata',
            text: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      },
      validationSchema,
    })
  let navigate = useNavigate()
  const getSellerApplication = async (trackingNumber: string, isMail?: boolean) => {
    const req = { TrackingId: trackingNumber }
    const api = new SellerApplicationService({})
    const sellerApplication = isMail ? api.getSellerApplicationMail(req) : api.getSellerApplication(req)

    try {
      await sellerApplication
        .then(async res => {
          if (typeof res === 'string') {
            return Swal.fire('Hata', res, 'error').then(async result => {
              navigate('/login')
            })
          }

          if (res.Country === 'TR') {
            setSelectedNationality('TC')
          } else if (res.Country === 'CY') {
            setSelectedNationality('KKTC')
          }

          if (res.CompanyType === 1) {
            setSelectedOption('idNum')
          } else if (res.CompanyType === 2) {
            setSelectedOption('taxNum')
          }

          setState({ fetchedSellerResponse: res })

          setState({ selectedId: res.Id && res.Id })

          setState({
            isReadOnly: Number(res.Status) > 1,
            isFileDisabled: Number(res.Status) > 2,
            statusCode: Number(res.Status),
          })
          const status = StatusOptions.find(status => {
            if (status.code === Number(res.Status)) {
              return status.text
            }
          })
          setState({ status })

          const parsedStatusInfo = JSON.parse(res.StatusInformation as any)

          setState({
            statusInformation: parsedStatusInfo,
          })

          setValues(res as any)

          setFieldValue('AuthorizedTC', res.AuthorizedTc)

          setFieldValue(
            'Country',
            ICountryOption.find(elem => elem.value === res.Country)
          )
          setFieldValue(
            'InvoiceType',
            InvoiceTypeOption.find(elem => elem.value === res.InvoiceType)
          )
          setFieldValue(
            'CompanyType',
            IFirmTypeOption.find(elem => elem.value === res.CompanyType)
          )
          setFieldValue(
            'IbanNumberType1',
            IIbanOption.find(elem => elem.value === res.IbanNumberType1)
          )

          let IbanNumberType2 = IIbanOption.find(elem => elem.value === res.IbanNumberType2)
          if (IbanNumberType2 === undefined) {
            IbanNumberType2 = { label: '', value: 0 }
          }
          setFieldValue('IbanNumberType2', IbanNumberType2)

          let IbanNumberType3 = IIbanOption.find(elem => elem.value === res.IbanNumberType3)
          if (IbanNumberType3 === undefined) {
            IbanNumberType3 = { label: '', value: 0 }
          }
          setFieldValue('IbanNumberType3', IbanNumberType3)

          let IbanNumberType4 = IIbanOption.find(elem => elem.value === res.IbanNumberType4)
          if (IbanNumberType4 === undefined) {
            IbanNumberType4 = { label: '', value: 0 }
          }
          setFieldValue('IbanNumberType4', IbanNumberType4)

          if (res.Country === 'CY') {
            await getCityCyprus()
            const foundCityOption = kibris.İl.find(elem => elem.Number === res.City)

            if (foundCityOption) {
              const selectedCityLabel = foundCityOption.Adı

              const data = kibris.İl.map(item => {
                return { label: item.Adı, value: item.Number }
              })

              setFieldValue(
                'City',
                data.find(elem => elem.value.toString() === res.City.toString())
              )
              await getTownCyprus(selectedCityLabel)

              let filteredIlce = [] as any
              kibris.İl.forEach(a => {
                if (a.Adı === selectedCityLabel) {
                  filteredIlce = a.İlçeler
                }
              })

              const datam = filteredIlce.map(b => {
                return {
                  label: b.Adı,
                  value: b.Number,
                }
              })

              setFieldValue(
                'Town',
                datam.find(elem => elem.value.toString() === res.Town.toString())
              )

              const Noptions = [{ label: 'KIBRIS', value: 'KIBRIS', PostCode: '99123' }]

              setFieldValue('Neighbourhood', Noptions)
            }
          } else if (res.Country === 'TR') {
            await getCity()
            await getTown(+res.City)
            await getNeighbourhood(+res.Town)

            setFieldValue(
              'City',
              state.cityOptions.find(elem => elem.value === res.City)
            )
            setFieldValue(
              'Town',
              state.townOptions.find(elem => elem.value === res.Town)
            )
            setFieldValue(
              'Neighbourhood',
              state.neighbourhoodOptions.find(elem => elem.label === res.Neighbourhood)
            )
          }

          setFieldValue(
            'InvoiceUsageType',
            IInvoiceUsageOption.find(elem => elem.value === `${res.InvoiceUsageType}`)
          )

          setFieldValue('CommercialMessage', res.CommercialMessage === true ? '1' : '0')

          const docsResponse = JSON.parse(res.Documents as any)
          setState({ loadedFiles: docsResponse })
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    } catch (err: any) {
      console.error(err)

      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  const location: any = useLocation()
  const code = window.location.href.split('code=')[1]

  useEffect(() => {
    if (location.state && location.state.trackingCode && location.state.trackingCode.length > 0) {
      getSellerApplication(location.state.trackingCode)
      setState({ trackingNumber: location.state.trackingCode })
    }
    if (code) {
      getSellerApplication(code, true)
      setState({ trackingNumber: code })
    }
  }, [])

  const postDealerControl = async value => {
    const sellerApplication = new SellerApplicationService({})
    const res = await sellerApplication.dealerControl({
      ID: state.selectedId ? state.selectedId : 0,
      TaxOrTcNumber: value,
    })
    setState({ dealerControl: res })
  }

  const isFormValid =
    (!state.isReadOnly || state.statusCode === 2) &&
    state.dealerControl &&
    state.isAuthorizedTCValid &&
    state.isIBANValid &&
    state.isIBANValid2 &&
    state.isIBANValid3 &&
    state.isIBANValid4 &&
    state.isIBANType2Valid &&
    state.isIBANType3Valid &&
    state.isIBANType4Valid &&
    state.isTaxValid &&
    state.isTCValid &&
    state.isReCaptchaValid &&
    state.isPostCodeValid

  return (
    <div className='sellerApplication'>
      {props.trackingNumber && (
        <div className='row mb-4'>
          <div className='col-12' style={{ color: state.status?.color }}>
            Başvuru Durumu: {state.status?.text}
          </div>
          {state.statusInformation && (
            <div className='col-12'>
              {state.statusInformation.map(msg => {
                return (
                  <div key={uuidv4()} className='statusInfoMessage'>
                    <div className='d-flex gap-4 align-items-center'>
                      <div>{new Date(msg.Date).toLocaleDateString('tr-TR')}</div>
                      <div>
                        {msg.Status === null ? (
                          <div style={{ display: 'block', marginBottom: '6px' }}>{msg.Message}</div>
                        ) : (
                          <div style={{ display: 'block', marginBottom: '6px' }}>{msg.Status}</div>
                        )}

                        {msg.Status !== null && (
                          <div style={{ display: 'block', marginBottom: '6px' }}>{msg.Message}</div>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      )}
      <form
        id='id1'
        className={`sellerApplicationForm ${state.isReadOnly ? 'readOnlyForm' : ''}`}
        onSubmit={e => {
          e.preventDefault()

          if (values.IbanNumber2.length > 0 && values.IbanNumberType2.label.length === 0) {
            setState({ isIBANType2Valid: false })
            return
          }
          if (values.IbanNumber3.length > 0 && values.IbanNumberType3.label.length === 0) {
            setState({ isIBANType3Valid: false })
            return
          }
          if (values.IbanNumber4.length > 0 && values.IbanNumberType4.label.length === 0) {
            setState({ isIBANType4Valid: false })
            return
          }
          // if (state.isIBANType2Valid === false && state.isIBANType3Valid === false && state.isIBANType3Valid === false) {
          // 	return
          // }

          handleSubmit(e)
        }}
      >
        <div className='row'>
          <div className='form-group col-md-6'>
            <label htmlFor='location' className='form-label'>
              {t('pages.register.bayiForms.companyName')}
              <span className='required'>*</span>
            </label>
            <div>
              <IPInput
                type='text'
                className='form-control'
                name='CompanyName'
                value={values.CompanyName}
                onChange={e => setFieldValue('CompanyName', e.target.value.toLocaleUpperCase().trim())}
                onBlur={handleBlur}
                errors={errors.CompanyName}
                touched={touched.CompanyName}
                readOnly={state.isReadOnly}
                maxLength={80}
              />
            </div>
          </div>
          <div className='form-group col-md-6'>
            <label htmlFor='location' className='form-label'>
              {t('pages.register.bayiForms.nameSurname')}
              <span className='required'>*</span>{' '}
              <label htmlFor='location' className='form-label' style={{ color: 'red' }}>
                {'Yetkili kişi ile evrakların uyuşması gerekmektedir.'}
              </label>
            </label>
            <div>
              <IPInput
                type='text'
                className='form-control'
                name='AuthorizedNameSurname'
                value={values.AuthorizedNameSurname}
                onChange={e => {
                  const newValue = e.target.value.toUpperCase().trim()
                  setFieldValue('AuthorizedNameSurname', newValue)
                }}
                onBlur={handleBlur}
                errors={errors.AuthorizedNameSurname}
                touched={touched.AuthorizedNameSurname}
                readOnly={state.isReadOnly}
              />
            </div>
          </div>
          <div className='form-group col-md-6'>
            <label htmlFor='location' className='form-label mt-3'>
              {t('pages.register.bayiForms.email')}
              <span className='required'>*</span>
            </label>
            <div>
              <IPInput
                onKeyUp={checkCapsLock}
                type='text'
                className='form-control'
                name='Email'
                dataPattern={IPInputDataPattern.Email}
                value={values.Email}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.Email}
                touched={touched.Email}
                readOnly={state.isReadOnly}
              />
              {isCapsLockOn && <p className='validationError2'>* UYARI: Caps Lock AÇIK.</p>}
            </div>
          </div>
          <div className='form-group col-md-6'>
            <div>
              <div className='radioOptionsContainer'>
                <div className='form-check form-check-inline pl-0'>
                  <IPRadio
                    type='radio'
                    name='inlineRadioOptions2'
                    id='inlineRadio3'
                    value='TC'
                    onChange={e => {
                      handleChange(e)
                      setSelectedNationality(e.target.value)
                      setFieldValue('AuthorizedTC', '') // Reset the text field
                    }}
                    checked={selectedNationality === 'TC'}
                    disabled={state.isReadOnly}
                    text={''}
                  />
                  <label htmlFor='location' className='form-check-label'>
                    T.C Vatandaşı
                    <span className='required'>*</span>
                  </label>
                </div>
                <div className='form-check form-check-inline'>
                  <IPRadio
                    type='radio'
                    name='inlineRadioOptions2'
                    id='inlineRadio4'
                    value='KKTC'
                    onChange={e => {
                      handleChange(e)
                      setSelectedNationality(e.target.value)
                      setFieldValue('AuthorizedTC', '') // Reset the text field
                    }}
                    checked={selectedNationality === 'KKTC'}
                    disabled={state.isReadOnly}
                    text={''}
                  />
                  <label className='form-check-label'>
                    KKTC Vatandaşı
                    <span className='required'>*</span>
                  </label>
                </div>
              </div>
              {/* Render the appropriate InputMask based on the selected radio button */}
              {selectedNationality === 'TC' ? (
                <InputMask
                  mask='99999999999'
                  placeholder='Yetkili T.C No'
                  type='text'
                  className='form-control'
                  name='AuthorizedTC'
                  value={values.AuthorizedTC}
                  onChange={e => {
                    handleChange(e)
                    if (e.target.value.replaceAll('_', '').length === 11) {
                      setState({
                        isAuthorizedTCValid: isValidTCKN(e.target.value),
                      })
                    }
                  }}
                  onBlur={handleBlur}
                  errors={errors.AuthorizedTC}
                  touched={touched.AuthorizedTC}
                  readOnly={state.isReadOnly}
                />
              ) : selectedNationality === 'KKTC' ? (
                <IPInput
                  dataPattern={IPInputDataPattern.Numeric}
                  type='text'
                  className='form-control'
                  name='AuthorizedTC'
                  value={values.AuthorizedTC}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.AuthorizedTC}
                  readOnly={state.isReadOnly}
                />
              ) : (
                <InputMask mask='' />
              )}
              {selectedNationality === 'TC' && !state.isAuthorizedTCValid && (
                <span className='validationError2'>TC Kimlik numarasını kontrol edin!</span>
              )}
            </div>
          </div>

          <div className='form-group col-md-6'>
            <label htmlFor='location' className='form-label'>
              {t('pages.register.bayiForms.phone')}
            </label>
            <div>
              <InputMask
                mask='(999)999-9999'
                name='Phone'
                type='text'
                className='form-control'
                value={values.Phone}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.Phone}
                touched={touched.Phone}
                readOnly={state.isReadOnly}
              />
            </div>
          </div>
          <div className='form-group col-md-6'>
            <label htmlFor='location' className='form-label'>
              Mobil Telefon
              <span className='required'>*</span>
            </label>
            <div>
              <InputMask
                mask='(999)999-9999'
                name='MobilePhone'
                type='text'
                className='form-control'
                value={values.MobilePhone}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.MobilePhone}
                touched={touched.MobilePhone}
                readOnly={state.isReadOnly}
              />
            </div>
          </div>
          <div className='form-group col-md-6'>
            <label htmlFor='location' className='form-label'>
              Fax
            </label>
            <div>
              <InputMask
                mask='(999)999-9999'
                name='Fax'
                type='text'
                className='form-control'
                value={values.Fax}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.Fax}
                touched={touched.Fax}
                readOnly={state.isReadOnly}
              />
            </div>
          </div>

          <div className='form-group col-md-6'>
            <label htmlFor='location' className='form-label'>
              {t('pages.register.bayiForms.country')}
              <span className='required'>*</span>
            </label>
            <div>
              <IPSelectBox
                name='Country'
                options={ICountryOption}
                onChangeSingle={(option: ISelectOption) => {
                  setFieldValue('Country', option)
                  setFieldValue('City', null)
                  setFieldValue('Town', null)
                  setFieldValue('District', null)
                  setFieldValue('Neighbourhood', null)
                  setFieldValue('PostCode', '')
                  setState({ neighbourhoodOptions: [] })
                  if (option.value === 'TR') {
                    getCity()
                  } else if (option.value === 'CY') {
                    getCityCyprus()
                  }
                  setState({ selectedCountry: option })
                }}
                value={values.Country}
                onBlur={handleBlur}
                isDisabled={state.isReadOnly}
              />
              <p className='validationError'>
                {errors.Country?.label && touched.Country?.label && errors.Country.label}
              </p>
            </div>
          </div>
          <div className='form-group col-md-6'>
            <label htmlFor='location' className='form-label'>
              {t('pages.register.bayiForms.city')}
              <span className='required'>*</span>
            </label>
            <div>
              <IPSelectBox
                name='City'
                options={state.cityOptions}
                onChangeSingle={(option: ISelectOption) => {
                  setFieldValue('City', option)
                  setFieldValue('Town', null)
                  setState({ neighbourhoodOptions: [] })
                  if (state.selectedCountry && state.selectedCountry.value === 'TR') {
                    getTown(option.value as number)
                    setFieldValue('Neighbourhood', null)
                    setFieldValue('PostCode', '')
                    setFieldValue('District', null)
                  } else if (state.selectedCountry && state.selectedCountry.value === 'CY') {
                    getTownCyprus(option.label as string)
                    setFieldValue('Neighbourhood', 'KIBRIS')
                    setFieldValue('PostCode', '99123')
                    setFieldValue('District', 'KIBRIS')
                  }
                  setState({ selectedCity: option })
                }}
                value={values.City}
                onBlur={handleBlur}
                isDisabled={state.isReadOnly}
              />
              <p className='validationError'>{errors.City?.label && touched.City?.label && errors.City.label}</p>
            </div>
          </div>

          <div className='form-group col-md-6'>
            <label htmlFor='location' className='form-label'>
              {t('pages.register.bayiForms.town')}
              <span className='required'>*</span>
            </label>
            <div>
              <IPSelectBox
                name='Town'
                options={state.townOptions}
                onChangeSingle={(option: ISelectOption) => {
                  setFieldValue('Town', option)
                  setState({ neighbourhoodOptions: [] })
                  if (state.selectedCountry && state.selectedCountry.value === 'TR') {
                    setFieldValue('Neighbourhood', null)
                    setFieldValue('PostCode', '')
                    setFieldValue('District', null)
                    getNeighbourhood(option.value as number)
                  } else if (state.selectedCountry && state.selectedCountry.value === 'CY') {
                    setFieldValue('Neighbourhood', 'KIBRIS')
                    setFieldValue('PostCode', '99123')
                    setFieldValue('District', 'KIBRIS')
                  }
                }}
                value={values.Town}
                onBlur={handleBlur}
                isDisabled={state.isReadOnly}
              />
              <p className='validationError'>{errors.Town?.label && touched.Town?.label && errors.Town.label}</p>
            </div>
          </div>
          <div className='form-group col-md-6'>
            <label htmlFor='location' className='form-label'>
              Mahalle
              <span className='required'>*</span>
            </label>
            <div>
              <IPSelectBox
                name='Neighbourhood'
                options={
                  state.selectedCountry?.value === 'CY'
                    ? [{ label: 'KIBRIS', value: 'KIBRIS', PostCode: '99123' }]
                    : state.neighbourhoodOptions
                }
                onChangeSingle={(option: ISelectOption) => {
                  setFieldValue('Neighbourhood', option)
                  setFieldValue('PostCode', option.PostCode)
                }}
                value={values.Neighbourhood}
                onBlur={handleBlur}
                isDisabled={state.isReadOnly}
              />
              <p className='validationError'>
                {errors.Neighbourhood?.label && touched.Neighbourhood?.label && errors.Neighbourhood.label}
              </p>
            </div>
          </div>
          <div className='form-group col-md-6'>
            <label htmlFor='location' className='form-label'>
              {t('pages.register.bayiForms.postCode')}
              <span className='required'>*</span>
              {!state.isPostCodeValid && <span className='validationError2'>Posta kodunu kontrol edin!</span>}
            </label>
            <div>
              <IPInput
                type='text'
                className='form-control'
                name='PostCode'
                dataPattern={IPInputDataPattern.Numeric}
                value={values.PostCode}
                onChange={e => {
                  handleChange(e)
                  if (e.target.value.length === 5) isValidPostCode(e.target.value)
                }}
                onBlur={handleBlur}
                errors={errors.PostCode}
                touched={touched.PostCode}
                readOnly={state.isReadOnly}
                disabled
              />
            </div>
          </div>
          <div className='form-group col-md-12'>
            <label htmlFor='location' className='form-label'>
              {t('pages.register.bayiForms.address')}
              <span className='required'>*</span>
            </label>
            <div className='siparisKodu'>
              <IPInput
                type='text'
                className='form-control'
                name='Address'
                value={values.Address}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.Address}
                touched={touched.Address}
                readOnly={state.isReadOnly}
              />
            </div>
          </div>
          <div className='form-group col-md-6'>
            {selectedNationality === 'TC' ? (
              <div>
                <div className='radioOptionsContainer'>
                  <div className='form-check form-check-inline pl-0'>
                    <IPRadio
                      type='radio'
                      name='inlineRadioOptions'
                      id='inlineRadio1'
                      value='taxNum'
                      checked={selectedOption === 'taxNum'}
                      onChange={e => {
                        handleChange(e)
                        setSelectedOption(e.target.value)
                        setFieldValue('TaxOrTcNumber', '') // Reset the text field
                        setFieldValue('CompanyType', { label: 'Ltd./A.Ş.', value: 2 })
                        setSelectedCompanyTypeOption(2)
                      }}
                      disabled={state.isReadOnly}
                      text={''}
                    />
                    <label htmlFor='location' className='form-check-label'>
                      Vergi Numarası
                      <span className='required'>*</span>
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <IPRadio
                      className='form-check-input'
                      type='radio'
                      name='inlineRadioOptions'
                      id='inlineRadio2'
                      value='idNum'
                      checked={selectedOption === 'idNum'}
                      onChange={e => {
                        handleChange(e)
                        setSelectedOption(e.target.value)
                        setFieldValue('TaxOrTcNumber', '') // Reset the text field
                        setFieldValue('CompanyType', { label: 'Şahıs', value: 1 })
                        setSelectedCompanyTypeOption(1)
                      }}
                      disabled={state.isReadOnly}
                      text={''}
                    />
                    <label className='form-check-label'>
                      TC. Kimlik No
                      <span className='required'>*</span>
                    </label>
                  </div>
                </div>
                {/* Render the appropriate InputMask based on the selected radio button */}
                {selectedOption === 'taxNum' ? (
                  <InputMask
                    mask='9999999999'
                    type='text'
                    className='form-control'
                    name='TaxOrTcNumber'
                    value={values.TaxOrTcNumber}
                    onChange={e => {
                      handleChange(e)
                      const val = e.target.value
                      if (val.length === 10) {
                        postDealerControl(val)
                        setState({ isTaxValid: isValidVKN(val) })
                      }
                    }}
                    onBlur={handleBlur}
                    errors={errors.TaxOrTcNumber}
                    touched={touched.TaxOrTcNumber}
                    readOnly={state.isReadOnly}
                  />
                ) : selectedOption === 'idNum' ? (
                  <InputMask
                    mask='99999999999'
                    type='text'
                    className='form-control'
                    name='TaxOrTcNumber'
                    value={values.TaxOrTcNumber}
                    onChange={e => {
                      handleChange(e)
                      const val = e.target.value
                      if (val.length === 11) {
                        postDealerControl(val)
                        setState({ isTCValid: isValidTCKN(val) })
                        setState({ isTaxValid: true })
                      }
                    }}
                    onBlur={handleBlur}
                    errors={errors.TaxOrTcNumber}
                    touched={touched.TaxOrTcNumber}
                    readOnly={state.isReadOnly}
                  />
                ) : (
                  <InputMask mask='' />
                )}
                {/* Validation messages */}
                {!state.dealerControl && (
                  <div className='validationError2'>
                    Bu vergi numarası yada TC kimlik numarasıyla daha önce kayıt oluşturulmuş!
                  </div>
                )}
                {values.TaxOrTcNumber && values.TaxOrTcNumber.replaceAll('_', '').length === 11 && !state.isTCValid && (
                  <div className='validationError2'>TC Kimlik numarasını kontrol edin!</div>
                )}
                {values.TaxOrTcNumber &&
                  values.TaxOrTcNumber.replaceAll('_', '').length === 10 &&
                  !state.isTaxValid && <div className='validationError2'>Vergi numarasını kontrol edin!</div>}
                {selectedOption === 'taxNum' && values.TaxOrTcNumber.replaceAll('_', '').length < 10 && (
                  <div className='validationError2'>Şirketler için vergi numarası zorunludur!</div>
                )}
                {selectedOption === 'idNum' && values.TaxOrTcNumber.replaceAll('_', '').length !== 11 && (
                  <div className='validationError2'>Şahıslar için T.C Kimlik No zorunludur!</div>
                )}
              </div>
            ) : selectedNationality === 'KKTC' ? (
              <div>
                <div className='radioOptionsContainer'>
                  <div className='form-check form-check-inline pl-0'>
                    <IPRadio
                      type='radio'
                      name='inlineRadioOptions'
                      id='inlineRadio1'
                      value='taxNum'
                      checked={selectedOption === 'taxNum'}
                      onChange={e => {
                        handleChange(e)
                        setSelectedOption(e.target.value)
                        setFieldValue('CompanyType', { label: 'Ltd./A.Ş.', value: 2 })
                        setFieldValue('TaxOrTcNumber', '') // Reset the text field
                        setSelectedCompanyTypeOption(2)
                      }}
                      disabled={state.isReadOnly}
                      text={''}
                    />
                    <label htmlFor='location' className='form-check-label'>
                      Vergi Numarası
                      <span className='required'>*</span>
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <IPRadio
                      className='form-check-input'
                      name='inlineRadioOptions'
                      id='inlineRadio2'
                      value='idNum'
                      checked={selectedOption === 'idNum'}
                      onChange={e => {
                        handleChange(e)
                        setSelectedOption(e.target.value)
                        setFieldValue('TaxOrTcNumber', '') // Reset the text field
                        setSelectedCompanyTypeOption(1)
                        setFieldValue('CompanyType', { label: 'Şahıs', value: 1 })
                      }}
                      disabled={state.isReadOnly}
                      text={''}
                    />
                    <label className='form-check-label'>
                      KKTC Kimlik No
                      <span className='required'>*</span>
                    </label>
                  </div>
                </div>
                {/* Render the appropriate InputMask based on the selected radio button */}
                {selectedOption === 'taxNum' ? (
                  <IPInput
                    dataPattern={IPInputDataPattern.Numeric}
                    type='text'
                    className='form-control'
                    name='TaxOrTcNumber'
                    value={values.TaxOrTcNumber}
                    onChange={e => {
                      handleChange(e)
                      const val = e.target.value
                      if (val) {
                        postDealerControl(val)
                      }
                    }}
                    onBlur={handleBlur}
                    touched={touched.TaxOrTcNumber}
                    readOnly={state.isReadOnly}
                  />
                ) : selectedOption === 'idNum' ? (
                  <IPInput
                    dataPattern={IPInputDataPattern.Numeric}
                    type='text'
                    className='form-control'
                    name='TaxOrTcNumber'
                    value={values.TaxOrTcNumber}
                    onChange={e => {
                      handleChange(e)
                      const val = e.target.value
                      if (val) {
                        postDealerControl(val)
                      }
                    }}
                    onBlur={handleBlur}
                    touched={touched.TaxOrTcNumber}
                    readOnly={state.isReadOnly}
                  />
                ) : (
                  <InputMask mask='' />
                )}
                {/* Validation messages */}
                {!state.dealerControl && (
                  <div className='validationError2'>
                    Bu vergi numarası yada TC kimlik numarasıyla daha önce kayıt oluşturulmuş!
                  </div>
                )}
                {selectedOption === 'taxNum' && values.TaxOrTcNumber.replaceAll('_', '').length < 5 && (
                  <div className='validationError2'>Şirketler için vergi numarası zorunludur!</div>
                )}
                {selectedOption === 'idNum' && values.TaxOrTcNumber.replaceAll('_', '').length < 5 && (
                  <div className='validationError2'>Şahıslar için T.C Kimlik No zorunludur!</div>
                )}
              </div>
            ) : (
              <InputMask mask='' />
            )}
          </div>
          <div className='form-group col-md-6'>
            <label htmlFor='location' className='form-label mt-3'>
              {t('pages.register.bayiForms.type')}
              <span className='required'>*</span>
            </label>
            <div>
              <IPSelectBox
                name='CompanyType'
                options={IFirmTypeOption}
                onChangeSingle={(option: ISelectOption) => {
                  setFieldValue('CompanyType', option)
                  setFieldValue('TaxOrTcNumber', '')
                  setSelectedOption(option.value === 1 ? 'idNum' : 'taxNum')
                  setSelectedCompanyTypeOption(+option.value)
                }}
                value={values.CompanyType}
                onBlur={handleBlur}
                isDisabled={state.isReadOnly}
              />
              <p className='validationError'>
                {errors.CompanyType?.label && touched.CompanyType?.label && errors.CompanyType.label}
              </p>
              {selectedCompanyTypeOption === 2 && values.TaxOrTcNumber.length !== 10 && (
                <div className='validationError2'>Şirketler için vergi numarası zorunludur!</div>
              )}
              {selectedCompanyTypeOption === 1 &&
                ((selectedNationality === 'KKTC' && values.TaxOrTcNumber.length < 10) ||
                  (selectedNationality === 'TC' && values.TaxOrTcNumber.length !== 11)) && (
                  <div className='validationError2'>Şahıslar için T.C Kimlik No zorunludur!</div>
                )}
            </div>
          </div>
          <div className='form-group col-md-6'>
            <label htmlFor='location' className='form-label'>
              {t('pages.register.bayiForms.usageType')}
              <span className='required'>*</span>
            </label>
            <div>
              <IPSelectBox
                name='InvoiceUsageType'
                options={IInvoiceUsageOption}
                onChangeSingle={(option: ISelectOption) => {
                  setFieldValue('InvoiceUsageType', option)
                }}
                value={values.InvoiceUsageType}
                onBlur={handleBlur}
                isDisabled={true}
              />
              <p className='validationError'>
                {errors.InvoiceUsageType?.label && touched.InvoiceUsageType?.label && errors.InvoiceUsageType.label}
              </p>
            </div>
          </div>
          <div className='form-group col-md-6'>
            <label htmlFor='location' className='form-label'>
              {t('pages.register.bayiForms.taxpayerType')}
              <span className='required'>*</span>
            </label>
            <div>
              <IPSelectBox
                name='InvoiceType'
                options={InvoiceTypeOption}
                onChangeSingle={(option: ISelectOption) => {
                  setFieldValue('InvoiceType', option)
                }}
                value={values.InvoiceType}
                onBlur={handleBlur}
                isDisabled={state.isReadOnly}
              />
              <p className='validationError'>
                {errors.InvoiceType?.label && touched.InvoiceType?.label && errors.InvoiceType.label}
              </p>
            </div>
          </div>
          <div className='form-group col-md-6'>
            <label htmlFor='location' className='form-label'>
              <div className='infoWrapper'>
                Mersis No <span className='required'>*</span>
                <a style={{ color: 'red' }} className='infoMarker'>
                  ?
                </a>
                <div className='infoBox'> Şahıs Firması için lütfen 16 tane 0 yazınız.</div>
              </div>
            </label>
            <div>
              <InputMask
                mask='9999999999999999'
                type='text'
                className='form-control'
                name='MersisNo'
                value={values.MersisNo}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.MersisNo}
                touched={touched.MersisNo}
                readOnly={state.isReadOnly}
              />
            </div>
          </div>

          <div className='form-group col-md-6'>
            <label htmlFor='location' className='form-label'>
              {t('pages.register.bayiForms.taxAdministration') + ' Adı'}
              <span className='required'>*</span>
            </label>
            <div>
              <IPInput
                dataPattern={IPInputDataPattern.AlphabeticWithSpaces}
                type='text'
                className='form-control'
                name='TaxAdministration'
                value={values.TaxAdministration}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.TaxAdministration}
                touched={touched.TaxAdministration}
                readOnly={state.isReadOnly}
              />
            </div>
          </div>

          <div className='form-group col-md-6'>
            <div className='row mb-0 mt-0'>
              <div className='col-lg-8 mb-0 mt-0'>
                <label htmlFor='location' className='form-label'>
                  {t('pages.register.bayiForms.iban')}
                  <span className='required'>*</span>
                  {!state.isIBANValid && values.IbanNumber1.length > 0 && (
                    <span className='validationError2'>IBAN numarasını kontrol edin!</span>
                  )}
                </label>
                <InputMask
                  mask='TR99 9999 9999 9999 9999 9999 99'
                  type='text'
                  className='form-control'
                  name='IbanNumber1'
                  value={values.IbanNumber1}
                  onChange={e => {
                    handleChange(e)
                    setState({ isIBANValid: isValidIBAN(e.target.value) })
                  }}
                  onBlur={handleBlur}
                  errors={errors.IbanNumber1}
                  touched={touched.IbanNumber1}
                  readOnly={state.isReadOnly}
                />
              </div>
              <div className='col-lg-4 mb-0 mt-0'>
                <div className='align-items-bottom'>
                  <label htmlFor='location' className='form-label'>
                    Para Birimi<span className='required'>*</span>
                  </label>
                  <IPSelectBox
                    placeholder='Seçiniz...'
                    isClearable
                    name='IbanNumberType1'
                    options={IIbanOption}
                    onChangeSingle={(option: ISelectOption) => {
                      setFieldValue('IbanNumberType1', option)
                    }}
                    value={values.IbanNumberType1}
                    onBlur={handleBlur}
                    isDisabled={state.isReadOnly}
                    className='w-100'
                  />
                  <p className='validationError'>
                    {errors.IbanNumberType1?.label && touched.IbanNumberType1?.label && errors.IbanNumberType1.label}
                  </p>
                  {values.IbanNumber1 && !values.IbanNumberType1 && (
                    <p className='validationError'>Para Birimi seçmediniz!</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='form-group col-md-6'>
            <div className='row'>
              <div className='col-lg-8'>
                <label htmlFor='location' className='form-label'>
                  {t('pages.register.bayiForms.iban')}
                  {!state.isIBANValid2 && values.IbanNumber2.length > 0 && (
                    <span className='validationError2'>IBAN numarasını kontrol edin!</span>
                  )}
                </label>
                <InputMask
                  mask='TR99 9999 9999 9999 9999 9999 99'
                  type='text'
                  className='form-control'
                  name='IbanNumber2'
                  value={values.IbanNumber2}
                  onChange={e => {
                    handleChange(e)
                    setState({ isIBANValid2: isValidIBAN(e.target.value) })
                  }}
                  onBlur={e => {
                    handleBlur(e)
                    if (e.target.value === '') {
                      setState({ isIBANValid2: true, isIBANType2Valid: true })
                      setFieldValue('IbanNumberType2', {
                        label: '',
                        value: '',
                      })
                    }
                  }}
                  errors={errors.IbanNumber2}
                  touched={touched.IbanNumber2}
                  readOnly={state.isReadOnly}
                />
              </div>
              <div className='col-lg-4'>
                <div className=' align-items-bottom'>
                  <label htmlFor='location' className='form-label'>
                    Para Birimi
                  </label>
                  <IPSelectBox
                    placeholder='Seçiniz...'
                    isClearable
                    name='IbanNumberType2'
                    options={IIbanOption}
                    onChangeSingle={(option: ISelectOption) => {
                      setFieldValue('IbanNumberType2', option)
                    }}
                    value={values.IbanNumberType2}
                    onBlur={() => {
                      setState({
                        isIBANType2Valid: values.IbanNumberType2 && values.IbanNumberType2.label.length > 0,
                      })
                    }}
                    isDisabled={state.isReadOnly}
                    className='w-100'
                  />
                  {values.IbanNumber2 && !state.isIBANType2Valid && !values.IbanNumberType2 && (
                    <p className='validationError'>Para Birimi seçmediniz!</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='form-group col-md-6'>
            <div className='row mb-0 mt-0'>
              <div className='col-lg-8 mb-0 mt-0'>
                <label htmlFor='location' className='form-label'>
                  {t('pages.register.bayiForms.iban')}
                  {!state.isIBANValid3 && values.IbanNumber3.length > 0 && (
                    <span className='validationError2'>IBAN numarasını kontrol edin!</span>
                  )}
                </label>
                <InputMask
                  mask='TR99 9999 9999 9999 9999 9999 99'
                  type='text'
                  className='form-control'
                  name='IbanNumber3'
                  value={values.IbanNumber3}
                  onChange={e => {
                    handleChange(e)
                    setState({ isIBANValid3: isValidIBAN(e.target.value) })
                  }}
                  onBlur={e => {
                    handleBlur(e)
                    if (e.target.value === '') {
                      setState({ isIBANValid3: true, isIBANType3Valid: true })
                      setFieldValue('IbanNumberType3', {
                        label: '',
                        value: '',
                      })
                    }
                  }}
                  errors={errors.IbanNumber3}
                  touched={touched.IbanNumber3}
                  readOnly={state.isReadOnly}
                />
              </div>
              <div className='col-lg-4 mb-0 mt-0'>
                <div className='align-items-bottom'>
                  <label htmlFor='location' className='form-label'>
                    Para Birimi
                  </label>
                  <IPSelectBox
                    placeholder='Seçiniz...'
                    isClearable
                    name='IbanNumberType3'
                    options={IIbanOption}
                    onChangeSingle={(option: ISelectOption) => {
                      setFieldValue('IbanNumberType3', option)
                    }}
                    value={values.IbanNumberType3}
                    onBlur={() => {
                      setState({
                        isIBANType3Valid: values.IbanNumberType3 && values.IbanNumberType3.label.length > 0,
                      })
                    }}
                    isDisabled={state.isReadOnly}
                    className='w-100'
                  />
                  {values.IbanNumber3 && !state.isIBANType3Valid && !values.IbanNumberType3 && (
                    <p className='validationError'>Para Birimi seçmediniz!</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='form-group col-md-6'>
            <div className='row mb-0 mt-0'>
              <div className='col-lg-8 mb-0 mt-0'>
                <label htmlFor='location' className='form-label'>
                  {t('pages.register.bayiForms.iban')}
                  {!state.isIBANValid4 && values.IbanNumber4.length > 0 && (
                    <span className='validationError2'>IBAN numarasını kontrol edin!</span>
                  )}
                </label>
                <InputMask
                  mask='TR99 9999 9999 9999 9999 9999 99'
                  type='text'
                  className='form-control'
                  name='IbanNumber4'
                  value={values.IbanNumber4}
                  onChange={e => {
                    handleChange(e)
                    setState({ isIBANValid4: isValidIBAN(e.target.value) })
                  }}
                  onBlur={e => {
                    handleBlur(e)
                    if (e.target.value === '') {
                      setState({ isIBANValid4: true, isIBANType4Valid: true })
                      setFieldValue('IbanNumberType4', {
                        label: '',
                        value: '',
                      })
                    }
                  }}
                  errors={errors.IbanNumber4}
                  touched={touched.IbanNumber4}
                  readOnly={state.isReadOnly}
                />
              </div>
              <div className='col-lg-4 mb-0 mt-0'>
                <div className='align-items-bottom'>
                  <label htmlFor='location' className='form-label'>
                    Para Birimi
                  </label>
                  <IPSelectBox
                    placeholder='Seçiniz...'
                    isClearable
                    name='IbanNumberType4'
                    options={IIbanOption}
                    onChangeSingle={(option: ISelectOption) => {
                      setFieldValue('IbanNumberType4', option)
                    }}
                    value={values.IbanNumberType4}
                    onBlur={() => {
                      setState({
                        isIBANType4Valid: values.IbanNumberType4 && values.IbanNumberType4.label.length > 0,
                      })
                    }}
                    isDisabled={state.isReadOnly}
                    className='w-100'
                  />
                  {values.IbanNumber4 && !state.isIBANType4Valid && !values.IbanNumberType4 && (
                    <p className='validationError'>Para Birimi seçmediniz!</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-12 mt-5'>
            <div className='form-group col-md-12'>
              <div className='infoWrapper'>
                <div className='acceptForm'>
                  {t('pages.register.bayiForms.electronicMessage')}
                  <a style={{ color: 'red' }} className='infoMarker'>
                    ?
                  </a>

                  <div className='infoBox'>
                    {' '}
                    ‘’ İNDEKS GRUP ŞİRKETLERİ ürün ve hizmetleri ile ilgili kampanyalar, tanıtımlar, duyurular hakkında
                    link ve yönlendirmeleri, tanıtımları, haberleri veya reklamları da içerebilen şekilde E-BAYİ'yi
                    bilgilendirmek için E-BAYİ'nin gerek sözleşmenin imzalanması sırasında gerekse sonradan bildirdiği
                    telefon numaraları, faks, posta ve e-posta adreslerinden oluşan tüm bilgileri kullanabilir, yasal
                    düzenleme kapsamında ticari iletişim ve ticari elektronik ileti gönderebilir. E-BAYİ, bu kapsamda
                    bildirdiği telefon numaraları, faks, posta ve e-posta adresleri gibi iletişim bilgileri için izin
                    vermeye yetkili olduğunu, verdiği iletişim bilgilerinin doğru ve gerçek olduğunu, kendisi ile
                    ilişkisi olmayan 3. kişilere ait bilgileri sağlaması halinde doğabilecek tüm zarardan sorumlu
                    olduğunu kabul, beyan ve taahhüt eder. ‘’
                  </div>
                </div>
              </div>
              <div className='d-flex gap-4'>
                <IPRadio
                  text={t('pages.register.bayiForms.yes')}
                  name='CommercialMessage'
                  onClick={() => {
                    values.CommercialMessage = '1'
                    validateField('CommercialMessage')
                  }}
                  // value={values.CommercialMessage}
                  checked={values.CommercialMessage === '1'}
                  disabled={state.isReadOnly}
                />
                <IPRadio
                  text={t('pages.register.bayiForms.no')}
                  name='CommercialMessage'
                  onClick={() => {
                    values.CommercialMessage = '0'
                    validateField('CommercialMessage')
                  }}
                  // value={values.CommercialMessage}
                  checked={values.CommercialMessage === '0'}
                  disabled={state.isReadOnly}
                />
              </div>
              <p className='validationError'>
                {errors.CommercialMessage && touched.CommercialMessage && errors.CommercialMessage}
              </p>
            </div>

            <div className='form-group col-md-12'>
              <div className='acceptForm'>
                <div className='d-flex align-items-center'>
                  {/* <IPCheckbox
										name='KvkApproval'
										onCheckedChange={(checked: boolean) => {
											setFieldValue('KvkApproval', checked)
										}}
										onBlur={handleBlur}
										checked={values.KvkApproval}
										disabled={state.isReadOnly}
									/> */}
                  <div>
                    {t('pages.register.bayiForms.kvk')}{' '}
                    <span
                      className={css`
                        color: #ec4c4c;
                        cursor: pointer;
                        &:hover {
                          color: red;
                        }
                      `}
                      onClick={() => setState({ showModal: true })}
                    >
                      {t('pages.register.bayiForms.click')}
                    </span>
                  </div>
                  {values.KvkApproval ? '✅' : ''}
                </div>
                <p className='validationError'>{errors.KvkApproval && touched.KvkApproval && errors.KvkApproval}</p>
              </div>
            </div>

            <div className='form-group col-md-12'>
              <div className='row'>
                {state.loadedFiles && state.loadedFiles.length > 0 && (
                  <div className='col-12 mb-4'>
                    <div>Yüklü Evraklar</div>
                    {state.loadedFiles.map(file => {
                      return (
                        <a key={uuidv4()} className='d-block' href={file.FileUrl} target='_blank' rel='noreferrer'>
                          {file.Name}
                        </a>
                      )
                    })}
                  </div>
                )}
                <div className='col-12'>
                  <div className='d-flex gap-3'>
                    <div className='flex-shrink-0'>{t('pages.register.bayiForms.uploadDocument')}</div>
                    <div className='infoWrapper'>
                      <div className='infoMarker'>?</div>
                      <div className='infoBox'>{props.requestedDocuments}</div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <IPFile
                    id='Files'
                    label='Dosya Seç'
                    name='Files'
                    onBlur={handleBlur}
                    handleFiles={handleFiles}
                    removeAfterSelect={true}
                    multiple={true}
                    disabled={state.isFileDisabled}
                    className={
                      state.isFileDisabled
                        ? css`
                            opacity: 0.6;
                          `
                        : ''
                    }
                  />
                  <p className='validationError'>{errors.Files && touched.Files && errors.Files}</p>
                </div>
              </div>
            </div>
            <div className='form-group col-12'>
              <ReCaptcha
                ref={reCaptchaRef}
                sitekey={RECAPTCHA_KEY}
                hl='tr'
                onChange={() => setState({ isReCaptchaValid: true })}
                onExpired={() => setState({ isReCaptchaValid: false })}
                onErrored={() => setState({ isReCaptchaValid: false })}
              />
            </div>
            <div className='form-group col-md-12'>
              <br />
              <div className='buttonsuz'>
                <IPButton
                  disabled={!isFormValid}
                  type='submit'
                  className='btn btn-success w-25 mb-4'
                  text={t('pages.register.bayiForms.save')}
                />
              </div>
              {state.isFileDisabled && (
                <div className='alert alert-warning d-inline'>
                  Hesabınızda güncelleme olduğundan dolayı güncelleme yapamazsınız.
                </div>
              )}
              {!isFormValid && (
                <p className='validationError mt-2'>
                  {' '}
                  Kayıt olmak için lütfen gerekli tüm alanları kurallara uygun doldurunuz!{' '}
                </p>
              )}
            </div>
          </div>
        </div>
        <IPModal
          title='KVKK Aydınlatma Metni'
          width={1200}
          show={state.showModal}
          confirmText='Onayla'
          onConfirm={() => {
            setFieldValue('KvkApproval', true)
            setState({ showModal: false })
          }}
          onClose={() => {
            setState({ showModal: false })
          }}
          closeOnEscapeKey={true}
          checkbox={
            <div className='d-flex align-items-center'>
              <IPCheckbox
                name='KvkApproval'
                onCheckedChange={(checked: boolean) => {
                  setFieldValue('KvkApproval', checked)
                }}
                onBlur={handleBlur}
                checked={values.KvkApproval}
                disabled={state.isReadOnly}
              />
              <div
                onClick={() => setFieldValue('KvkApproval', true)}
                className='ml-3 mb-2'
                style={{ cursor: 'pointer' }}
              >
                KVKK metnini okudum ve onaylıyorum.
              </div>
            </div>
          }
        >
          <>
            {window.innerWidth < 1000 && (
              <iframe
                title='kvkk-aydinlatma-metni'
                src='https://docs.google.com/gview?embedded=true&url=https://dev-b2b.vbt.com.tr:1443/assetWeb/docs/kvkk.pdf'
                style={{ height: '90vh' }}
              ></iframe>
            )}
            {window.innerWidth > 1000 && (
              <iframe title='kvkk-aydinlatma-metni' src='/assetWeb/docs/kvkk.pdf' style={{ height: '90vh' }}></iframe>
            )}
          </>
        </IPModal>
      </form>
    </div>
  )
}

export default componentWithContext(BayiForms)
