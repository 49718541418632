import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IPO, PODocRequest } from '../models'

export class GetPOApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetPODocument',
      requesterInfo,
    })
  }

  public async getPODoc(request: PODocRequest): Promise<IPO[]> {
    return await this.postAsync<IPO[]>(request)
  }
}
