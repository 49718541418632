import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import IPImage from '../../../components/IPImage'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'
import Swal from 'sweetalert2'
import { IChannelDataApple, IChannelGroupDataApple, IDPP, IMenu, INetChannelMixData } from '../models/models'
import { useCustomReducer } from '../../../base/customHooks'
import '../../Dpp/components/style.scss'
import IPButton from '../../../components/IPButton'
import IPSelectBox from '../../../components/IPSelectBox/IPSelectBox'
import IPInput from '../../../components/IPInput'
import { ISelectOption } from '../../../components/IPSelectBox/IPSelectBox'
import InputCampaign from '../../../components/InputCampaign'
import moment from 'moment'
import Table from '../../../components/Table'
import IPCheckbox from '../../../components/IPCheckBox'
import { GetNetChannelListApi } from '../services/getNetChannelListApi'
import { GetModelCompanyListApi } from '../services/getModelCompanyListApi'
import { exportToXLSX } from '../../../base/utils/dataHelper'
import ChartsPage1 from '../../NetChannel/components/ChartBar'
import StackPage from '../../NetChannel/components/Stack'
import { GetNetChannelGroupApi } from '../services/getNetChannelGroupApi'
import { logoutUser } from '../../../base/proxy/authenticate'

interface INetChannelState {
  product?: string
  dealer?: string
  selectedStatus?: ISelectOption
  selectedModel?: ISelectOption
  filterModel: ISelectOption[]
  dataLoad?: boolean
  filterLoad?: boolean
  bar?: boolean
  cake?: boolean
  table?: boolean
}

function Index(props: BaseComponentWithContextProps) {
  useEffect(() => {
    pageControl()
    getList()
    // getGroup()
    getModelList()
  }, [])

  const getInitialState = (): INetChannelState => {
    return {
      filterModel: [{ label: '', value: 0 }],
      bar: true,
      cake: false,
      table: true,
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<INetChannelState>(intialState)

  useEffect(() => {
    if (state.filterLoad && state.dataLoad) props.hideLoading()
  }, [state.filterLoad, state.dataLoad])

  const filterStatus = [
    { label: 'Satılanlar', value: 1 },
    { label: 'Stokta Olanlar', value: 0 },
  ]

  const [searchParams, setSearchParams] = useSearchParams()
  const [button, setButton] = useState('')
  const [netChannelList, setNetChannel] = useState<IChannelDataApple[]>([])
  const [netChannelGroup, setGroup] = useState<IChannelGroupDataApple[]>([])

  const changePage = async (param: string) => {
    navigate(`?page=${param}`)
    setButton(param)
    const element = document.getElementById(param) // Replace 'elementId' with the ID of the element you want to scroll to
    // Scroll the page to the element
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const pageControl = async () => {
    const param = searchParams.get('page')
    setButton(param ? param : '')
  }

  const navigate = useNavigate()

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setMonth(6)
  inititalStartDate.setFullYear(2024)

  const toDateTimeLocal = date => {
    if (date != null) {
      return date
        .toLocaleString('sv-SE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        .replace(' ', 'T')
    }
    return undefined
  }
  const [startSatisDate, setStartSatisDate] = useState(null)
  const [endSatisDate, setEndSatisDate] = useState(null)
  const [startAlmaDate, setStartAlmaDate] = useState(inititalStartDate)
  const [endAlmaDate, setEndAlmaDate] = useState(new Date())

  const getDate = async (data: any, title: string) => {
    if (title === 'StartSatis') setStartSatisDate(data)
    else if (title === 'EndSatis') setEndSatisDate(data)
    else if (title === 'StartAlma') setStartAlmaDate(data)
    else if (title === 'EndAlma') setEndAlmaDate(data)
  }

  const getList = async () => {
    // if (startAlmaDate && endAlmaDate) {
    //   const difference = new Date(endAlmaDate).getTime() - new Date(startAlmaDate).getTime()
    //   const absoluteDifference = difference >= 0 ? difference : -difference
    //   const differenceInDays = absoluteDifference / (1000 * 3600 * 24)

    //   if (differenceInDays > 90) {
    //     return Swal.fire({
    //       icon: 'error',
    //       title: 'En fazla 3 aylık rapor alabilirsiniz!',
    //       showConfirmButton: true,
    //       allowOutsideClick: false,
    //     });
    //   }
    // }

    props.showLoading()

    const reqModel: any = {
      StartSatis: startSatisDate ? new Date(startSatisDate) : undefined,
      EndSatis: endSatisDate ? new Date(endSatisDate) : undefined,
      StartAlma: new Date(startAlmaDate),
      EndAlma: new Date(endAlmaDate),
      Dealer: state.dealer && state.dealer?.length > 0 ? state.dealer : undefined,
      Status: state.selectedStatus?.value === 1 ? true : state.selectedStatus?.value === 0 ? false : undefined,
      Model: state.selectedModel?.value,
      Product: state.product && state.product?.length > 0 ? state.product : undefined,
    }

    const netapi: GetNetChannelListApi = new GetNetChannelListApi({})
    try {
      await netapi
        .getNetChannelList(reqModel)
        .then(result => {
          if (result) {
            setNetChannel(result.modelApples)
            setGroup(result.GroupedDataModel)
          }
          setState({ dataLoad: true })
          if (state.dataLoad) props.hideLoading()
        })
        .catch((err: any) => {
          setState({ dataLoad: true })
          if (state.dataLoad) props.hideLoading()
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    } catch {
      setState({ dataLoad: true })
      if (state.dataLoad) props.hideLoading()
    }
  }

  // const getGroup = async () => {
  //   props.showLoading()

  //   const reqModel: any = {
  //     StartSatis: startSatisDate ? new Date(startSatisDate) : undefined,
  //     EndSatis: endSatisDate ? new Date(endSatisDate) : undefined,
  //     StartAlma: new Date(startAlmaDate),
  //     EndAlma: new Date(endAlmaDate),
  //     Dealer: (state.dealer && state.dealer?.length > 0) ? state.dealer : undefined,
  //     Status: state.selectedStatus?.value === 1 ? true : state.selectedStatus?.value === 0 ? false : undefined,
  //     Model: state.selectedModel?.value,
  //     Product: (state.product && state.product?.length > 0) ? state.product : undefined
  //   }

  //   const groupapi: GetNetChannelGroupApi = new GetNetChannelGroupApi({})
  //   try {
  //     await groupapi.getData(reqModel).then(result => {
  //       if (result) {
  //         setGroup(result)
  //       }
  //       props.hideLoading()
  //     })
  //       .catch((err: any) => {
  //         Swal.fire({
  //           icon: 'error',
  //           title: err.description,
  //           showConfirmButton: true,
  //           allowOutsideClick: false,
  //         })
  //         props.hideLoading()
  //       })
  //   }
  //   catch {
  //     props.hideLoading()
  //   }
  // }

  const getData = async () => {
    getList()
    //getGroup()
  }

  const getModelList = async () => {
    props.showLoading()

    const netapi: GetModelCompanyListApi = new GetModelCompanyListApi({})
    try {
      await netapi
        .getDataList()
        .then(result => {
          if (result) {
            const modelOptions = result.Model.map(item => ({ label: item, value: item }))

            setState({ filterModel: modelOptions })
          }
          setState({ filterLoad: true })
        })
        .catch((err: any) => {
          setState({ filterLoad: true })
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    } catch {
      setState({ filterLoad: true })
    }
  }

  const columnDefinitions = [
    { Header: '#', accessor: 'order' },
    { Header: 'Bayi Kodu', accessor: 'bayiKodu' },
    { Header: 'Bayi Adı', accessor: 'bayiAdı' },
    { Header: 'Satın Alma Tarihi', accessor: 'alTarih' },
    { Header: 'Ürün Kodu', accessor: 'productCode' },
    { Header: 'Ürün Açıklaması', accessor: 'productInfo' },
    { Header: 'Ürün Modeli', accessor: 'productModel' },
    { Header: 'IMEI', accessor: 'imei' },
    { Header: 'Satış Tarihi', accessor: 'satTarih' },
    { Header: 'Durum', accessor: 'status' },
  ]

  const tableColumns = useMemo(() => columnDefinitions, [])

  const tableDataAndTotal = useMemo(() => {
    const tableData = netChannelList?.map((item, index) => {
      const data = {
        order: index + 1,
        bayiKodu: item.DealerCode,
        bayiAdı: item.DealerName,
        alTarih: item.AlmaTarihi && moment(item.AlmaTarihi).format('DD.MM.YYYY'),
        productCode: item.ProductCode,
        productInfo: item.ProductInfo,
        productModel: item.ProductModel,
        imei: item.IMEINumber,
        satTarih: item.SatisTarihi && moment(item.SatisTarihi).format('DD.MM.YYYY'),
        status:
          item.Status === false ? (
            <span style={{ color: 'red' }}>Stokta</span>
          ) : (
            <span style={{ color: 'green' }}>Satıldı</span>
          ),
      }
      return data
    })
    return {
      tableData,
    }
  }, [netChannelList])

  const columnDefinitionsSummary = [
    { Header: '#', accessor: 'order' },
    { Header: 'Bayi Kodu', accessor: 'bayiKodu' },
    { Header: 'Bayi Adı', accessor: 'bayiAdı' },
    { Header: 'Toplam Adet', accessor: 'total' },
    { Header: 'Satılan Adet', accessor: 'satilan' },
    { Header: 'Stok Adet', accessor: 'stock' },
  ]

  const tableColumnsSummary = useMemo(() => columnDefinitionsSummary, [])

  const tableDataAndTotalSummary = useMemo(() => {
    const tableData = netChannelGroup?.map((item, index) => {
      const data = {
        order: index + 1,
        bayiKodu: item.DealerCode,
        bayiAdı: item.DealerName,
        total: Number(item.SoldCount) + Number(item.StockCount),
        satilan: item.SoldCount,
        stock: item.StockCount,
      }
      return data
    })
    return {
      tableData,
    }
  }, [netChannelGroup])

  const postExcel = async () => {
    try {
      if (netChannelList) {
        const data: any[] = []
        if (netChannelList.length > 0) {
          netChannelList.map(item => {
            return data.push({
              'Bayi Kodu': item.DealerCode,
              'Bayi Adı': item.DealerName,
              'Satın Alma Tarihi': item.AlmaTarihi && moment(item.AlmaTarihi).format('DD.MM.YYYY'),
              'Ürün Kodu': item.ProductCode,
              'Ürün Açıklaması': item.ProductInfo,
              'Ürün Modeli': item.ProductModel,
              IMEI: item.IMEINumber,
              'Satış Tarihi': item.SatisTarihi && moment(item.SatisTarihi).format('DD.MM.YYYY'),
              Durum: item.Status === false ? 'Stokta' : 'Satıldı',
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Apple NetChannel')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const ozetExcel = async () => {
    try {
      if (netChannelList) {
        const data: any[] = []
        if (netChannelGroup.length > 0) {
          netChannelGroup.map(item => {
            return data.push({
              'Bayi Kodu': item.DealerCode,
              'Bayi Adı': item.DealerName,
              'Toplam Adet': Number(item.SoldCount) + Number(item.StockCount),
              'Satılan Adet': item.SoldCount,
              'Stok Adet': item.StockCount,
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Apple NetChannel DealerSummary')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const modelAdet = (model: any, type: string) => {
    if (state.filterModel != null && netChannelList != null) {
      const productModelCounts = {}
      netChannelList?.forEach(item => {
        const productModel = item.ProductModel
        if (productModel in productModelCounts) {
          productModelCounts[productModel]++
        } else {
          productModelCounts[productModel] = 1
        }
      })

      if (type === 'Toplam') {
        // Her bir seçeneğin (option) değerine karşılık gelen tekrar sayılarını bul
        const optionData = state.filterModel?.map(option => {
          const productModel = option.value
          return parseInt(productModelCounts[productModel]) || 0 // Eğer ProductModel yoksa, 0 olarak dön
        })

        // optionData içindeki değerleri int yap ve topla
        const total = optionData.reduce((acc, val) => acc + val, 0)

        return total
      } else {
        // Her bir seçeneğin (option) değerine karşılık gelen tekrar sayılarını bul
        const optionData = state.filterModel
          ?.filter(a => a.value == model)
          .map(option => {
            const productModel = option.value
            return productModelCounts[productModel] || 0 // Eğer ProductModel yoksa, 0 olarak dön
          })
        return optionData
      }
    }
  }

  const modelPercentage = (model: any) => {
    const total = modelAdet(state.filterModel, 'Toplam')
    const adet = modelAdet(model, 'Adet')

    if (total === 0) return 0

    if (adet != undefined && total != undefined) return ((adet[0] / Number(total)) * 100).toFixed(2)
  }

  const groupDataByYearAndMonth = data => {
    const groupedData = data?.reduce(
      (acc, item) => {
        // AlmaTarihi'ni yıl ve ay olarak al
        const purchaseDate = new Date(item.AlmaTarihi)
        const purchaseYear = purchaseDate.getFullYear()
        const purchaseMonth = purchaseDate.getMonth() + 1 // JavaScript'te aylar 0'dan başlar, bu yüzden 1 ekliyoruz

        // SatisTarihi'ni yıl ve ay olarak al
        const saleDate = new Date(item.SatisTarihi)
        const saleYear = saleDate.getFullYear()
        const saleMonth = saleDate.getMonth() + 1 // JavaScript'te aylar 0'dan başlar, bu yüzden 1 ekliyoruz

        // Satın alınan adetleri grupla
        if (!acc.purchase[purchaseYear]) {
          acc.purchase[purchaseYear] = {}
        }
        if (!acc.purchase[purchaseYear][purchaseMonth]) {
          acc.purchase[purchaseYear][purchaseMonth] = 0
        }
        acc.purchase[purchaseYear][purchaseMonth] += 1

        // Satılan adetleri grupla
        if (!acc.sale[saleYear]) {
          acc.sale[saleYear] = {}
        }
        if (!acc.sale[saleYear][saleMonth]) {
          acc.sale[saleYear][saleMonth] = 0
        }
        if (item.Status) {
          acc.sale[saleYear][saleMonth] += 1
        }

        return acc
      },
      { purchase: {}, sale: {} }
    )

    return groupedData
  }

  const groupedData = groupDataByYearAndMonth(netChannelList)

  return (
    <>
      <div className='container current-account'>
        <div className='content col-lg-12 m-auto'>
          <div className='card'>
            <div className='card-body'>
              <div className='row m-t-10 mb-5'>
                <div className='col d-flex card-mobile-resize justify-content-center'>
                  <div className='room me-5' onClick={() => changePage('report')}>
                    <div className='room image-hover'>
                      <div className='room-image text-center'>
                        <IPImage className='Mc_Hedef Mc_Hedef-original' src='/images/netChannel/detay2.png' alt='' />
                        <IPImage className='Mc_Hedef Mc_Hedef-hover' src='/images/netChannel/detay2.png' alt='' />
                      </div>
                    </div>
                  </div>
                  <div className='room' onClick={() => changePage('dealerSummary')}>
                    <div className='room image-hover'>
                      <div className='room-image text-center'>
                        <IPImage
                          className='Mc_Kampanya Mc_Kampanya-original'
                          src='/images/netChannel/bayiOzetAcik.png'
                          alt=''
                        />
                        <IPImage
                          className='Mc_Kampanya Mc_Kampanya-hover'
                          src='/images/netChannel/bayiOzetAcik.png'
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                  <div className='room' onClick={() => changePage('dashboard')}>
                    <div className='room ms-5 text-center  image-hover'>
                      <div className='room-image text-center'>
                        <IPImage className='Mc_Proje Mc_Proje-original' src='/images/netChannel/Dashboard.png' alt='' />
                        <IPImage
                          className='Mc_Proje Mc_Proje-hover'
                          src='/images/netChannel/Dashboard.png'
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className='room'
                    onClick={() => {
                      logoutUser()
                    }}
                  >
                    <div className='room ms-5 text-center  image-hover'>
                      <div className='room-image text-center'>
                        <IPImage
                          className='Mc_Akademi Mc_Akademi-original'
                          src='/images/netChannel/oturumacik.png'
                          alt=''
                        />
                        <IPImage
                          className='Mc_Akademi Mc_Akademi-hover'
                          src='/images/netChannel/oturumacik.png'
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <form className='formbanner'>
        <div className='row'>
          <div className='col-lg-2'></div>
          <div className='col-lg-2'>
            <InputCampaign
              type='date'
              id='StartDateAlma'
              name='StartDateAlma'
              label={'Satın Alma Başlangıç Tarihi'}
              value={toDateTimeLocal(startAlmaDate)}
              onChange={e => getDate(e.target.value, 'StartAlma')}
              isActive
            />
          </div>
          <div className='col-lg-2'>
            <div className='product'>
              <InputCampaign
                type='date'
                id='EndDateAlma'
                name='EndDateAlma'
                label={'Satın Alma Bitiş Tarihi'}
                value={toDateTimeLocal(endAlmaDate)}
                onChange={e => getDate(e.target.value, 'EndAlma')}
                isActive
              />
            </div>
          </div>
          <div className='col-lg-2'>
            <IPInput
              type='text'
              className='form-control'
              id='bayi'
              placeholder={'Bayi Kodu/Adı'}
              value={state.dealer}
              onChange={e => {
                setState({ dealer: e.target.value })
              }}
            />
          </div>
          <div className='col-lg-2'>
            <IPSelectBox
              isClearable
              id='Model'
              name='Model'
              options={state.filterModel}
              placeholder={'Model Seçiniz'}
              value={state.selectedModel}
              onChangeSingle={option => {
                setState({ selectedModel: option })
              }}
            />
          </div>
        </div>
        <br></br>
        <div className='row'>
          <div className='col-lg-2'></div>
          <div className='col-lg-2'>
            <InputCampaign
              type='date'
              id='StartDateSatis'
              name='StartDateSatis'
              label={'Satış Başlangıç Tarihi'}
              value={toDateTimeLocal(startSatisDate)}
              onChange={e => getDate(e.target.value, 'StartSatis')}
              isActive
            />
          </div>
          <div className='col-lg-2'>
            <div className='product'>
              <InputCampaign
                type='date'
                id='EndDateSatis'
                name='EndDateSatis'
                label={'Satış Bitiş Tarihi'}
                value={toDateTimeLocal(endSatisDate)}
                onChange={e => getDate(e.target.value, 'EndSatis')}
                isActive
              />
            </div>
          </div>
          <div className='col-lg-2'>
            <IPInput
              type='text'
              className='form-control'
              id='product'
              placeholder={'Ürün Kodu/Açıklaması/IMEI'}
              value={state.product}
              onChange={e => {
                setState({ product: e.target.value })
              }}
            />
          </div>
          <div className='col-lg-2'>
            <IPSelectBox
              isClearable
              id='Durum'
              name='Durum'
              placeholder={'Durum Seçiniz'}
              options={filterStatus}
              value={state.selectedStatus}
              onChangeSingle={option => {
                setState({ selectedStatus: option })
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-5'></div>
          <div className='col-lg-2'>
            <IPButton text='Listele' className='button' onClick={() => getData()} />
          </div>
          <div className='col-lg-5'></div>
        </div>
      </form>
      {button === 'report' && (
        <>
          <hr />
          <br></br>
          <div id='report'>
            <br></br>
            <h2 style={{ textAlign: 'center' }}>Net Channel Satış Detayları</h2>
            <div className='row ml-4 mr-4'>
              <div className='col-lg-6'></div>
              <div className='col-lg-6' style={{ display: 'flex', justifyContent: 'end' }}>
                <a className='excel-button ms-2 d-flex justify-content-center align-items-center' onClick={postExcel}>
                  <img
                    src='/images/icons/excel.png'
                    alt=''
                    width={25}
                    height={25}
                    style={{ cursor: 'pointer', marginRight: '5px' }}
                  />
                  Excel'e Gönder
                </a>
              </div>
            </div>

            <div className='mr-4 ml-4'>
              <Table columns={tableColumns} data={tableDataAndTotal.tableData} currentPage={0} columnCount={9} />
            </div>
          </div>
          <div className='m-5'>
            <b>Toplam {tableDataAndTotal.tableData.length} adet veri bulundu</b>
          </div>
        </>
      )}
      {button === 'dashboard' && (
        <>
          {netChannelList && state.filterModel && (
            <>
              <hr />
              <br></br>
              <div className='row d-flext justify-content-center m-5' id='dashboard'>
                <div className='col-lg-2'>
                  <IPCheckbox label='Sütun Grafiği' onClick={() => setState({ bar: !state.bar })} checked={state.bar} />
                </div>
                <div className='col-lg-2'>
                  <IPCheckbox
                    label='Pasta Grafiği'
                    onClick={() => setState({ cake: !state.cake })}
                    checked={state.cake}
                  />
                </div>
                <div className='col-lg-2'>
                  <IPCheckbox label='Tablo' onClick={() => setState({ table: !state.table })} checked={state.table} />
                </div>
              </div>
              <br></br>
              <div className='row d-flext justify-content-center m-5'>
                {state.bar && (
                  <div className='col-lg-4'>
                    <StackPage
                      report={netChannelList}
                      options={state.filterModel.filter(a => {
                        var data = modelAdet(a.value, 'Adet')
                        if (Array.isArray(data) && data.some(item => item === 0)) {
                          return false // 0 olanları filtrele
                        }
                        return true
                      })}
                    />
                  </div>
                )}
                {state.cake && (
                  <div className='col-4'>
                    <ChartsPage1
                      report={netChannelList}
                      options={state.filterModel.filter(a => {
                        var data = modelAdet(a.value, 'Adet')
                        if (Array.isArray(data) && data.some(item => item === 0)) {
                          return false // 0 olanları filtrele
                        }
                        return true
                      })}
                    />
                  </div>
                )}
                {state.table && (
                  <div className='col-4'>
                    <table className='table table-bordered dataTable XmlTable'>
                      <tbody>
                        <tr role='row'>
                          <td
                            style={{
                              width: '100px',
                              backgroundColor: '#F1F2F2',
                              height: 300,
                            }}
                            rowSpan={
                              state.filterModel.filter(a => {
                                var data = modelAdet(a.value, 'Adet')
                                if (Array.isArray(data) && data.some(item => item === 0)) {
                                  return false // 0 olanları filtrele
                                }
                                return true // 0 olmayanları tut
                              }).length + 1
                            }
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height:
                                  (state.filterModel.filter(a => {
                                    var data = modelAdet(a.value, 'Adet')
                                    if (Array.isArray(data) && data.some(item => item === 0)) {
                                      return false // 0 olanları filtrele
                                    }
                                    return true // 0 olmayanları tut
                                  }).length -
                                    1) *
                                  75 +
                                  'px', // div bileşeninin yüksekliği

                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <span style={{ fontWeight: '500', color: '#616161' }}>Toplam</span>
                              <p
                                style={{
                                  fontSize: '1.3rem',
                                  color: '#00A843',
                                  fontWeight: '600',
                                }}
                              >
                                {modelAdet(state.filterModel, 'Toplam')}
                              </p>
                            </div>
                          </td>
                        </tr>
                        {state.filterModel.map(data => {
                          const adet = modelAdet(data.value, 'Adet')
                          const percentage = modelPercentage(data.value)
                          if (Array.isArray(adet) && adet.some(item => item === 0)) {
                            return null // Dizideki herhangi bir eleman 0 ise map'i atla
                          }
                          return (
                            <tr className='' key={data.value}>
                              <td style={{ verticalAlign: 'middle' }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <span style={{ color: '#676767', fontWeight: '500' }}>{data.value}</span>
                                  <p
                                    style={{
                                      fontSize: '1.3rem',
                                      color: '#00A843',
                                      fontWeight: '600',
                                      marginBottom: 0,
                                      wordBreak: 'break-word',
                                    }}
                                  >
                                    {adet} ({percentage}%)
                                  </p>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <br></br>
              <div className='row d-flex justify-content-center'>
                <table className='table' style={{ width: '80%' }}>
                  <tbody>
                    <tr>
                      <th>Satın Alınan Yıl</th>
                      <th>Satın Alınan Ay</th>
                      <th>Satın Alınan Adet</th>
                      <th>Satılan Adet</th>
                    </tr>
                    {Object.keys(groupedData.purchase).map(year =>
                      Object.keys(groupedData.purchase[year]).map(month => (
                        <tr key={`purchase-${year}-${month}`}>
                          <td>{year}</td>
                          <td>{month}</td>
                          <td>{groupedData.purchase[year][month]}</td>
                          <td>{groupedData.sale[year]?.[month] || 0}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      )}
      {button === 'dealerSummary' && (
        <>
          <hr />
          <br></br>
          <div id='dealerSummary'>
            <br></br>
            <h2 style={{ textAlign: 'center' }}>Net Channel Özet Durum Raporu</h2>
            <div className='row ml-4 mr-4'>
              <div className='col-lg-6'></div>
              <div className='col-lg-6' style={{ display: 'flex', justifyContent: 'end' }}>
                <a className='excel-button ms-2 d-flex justify-content-center align-items-center' onClick={ozetExcel}>
                  <img
                    src='/images/icons/excel.png'
                    alt=''
                    width={25}
                    height={25}
                    style={{ cursor: 'pointer', marginRight: '5px' }}
                  />
                  Excel'e Gönder
                </a>
              </div>
            </div>

            <div className='mr-4 ml-4'>
              <Table
                columns={tableColumnsSummary}
                data={tableDataAndTotalSummary.tableData}
                currentPage={0}
                columnCount={9}
              />
            </div>
          </div>
          <div className='m-5 d-flex justify-content-end'>
            <b style={{ marginRight: '10%' }}>
              {tableDataAndTotalSummary.tableData.reduce((sum, item) => sum + item.total, 0)} Toplam{' '}
            </b>
            <b style={{ marginRight: '10%' }}>
              {tableDataAndTotalSummary.tableData.reduce((sum, item) => sum + item.satilan, 0)} Satılan{' '}
            </b>
            <b style={{ marginRight: '3%' }}>
              {tableDataAndTotalSummary.tableData.reduce((sum, item) => sum + item.stock, 0)} Stokta
            </b>
          </div>
          <div className='m-5'>
            <b>Toplam {tableDataAndTotalSummary.tableData.length} adet veri bulundu</b>
          </div>
        </>
      )}
      <br></br>
      <br></br>
    </>
  )
}
export default componentWithContext(Index)
