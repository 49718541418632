import { css } from '@emotion/css'
import * as Switch from '@radix-ui/react-switch'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { translate } from '../../../../../base/localization'
import { excelToJSON, getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'
import IPButton from '../../../../../components/IPButton'
import IPFile from '../../../../../components/IPFile'
import IPInput from '../../../../../components/IPInput'
import IPModal from '../../../../../components/IPModal'
import IPSelectBox, { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import InputCampaign from '../../../../../components/InputCampaign'
import Translations from '../../../../../translations'
import { getCompanyApi } from '../../../components/api'
import { CommonPostResponse, ICompany } from '../../../components/models/models'
import { IOpportunitFilter } from '../models/opportunitFilterModel'
import { IBuyersDetailData, IListOpportunity, IListOpportunityHistory } from '../models/opportunityMarketList'
import { IAddOpportunity, IOpportunityDetail } from '../models/requestModel'
import { DeleteOpportunityApi } from '../service/opportunityDelete'
import { ListOpportunityMarketApi } from '../service/opportunityList'
import { UpdateOpportunityApi } from '../service/opportunityUpdate'
import { PostAddOpportunityListApi } from '../service/postOpportunityList'
import { MarketHistoryApi } from '../service/appHistoryApi'
import Table from '../../../../../components/Table'

interface ISecimState {
  selectedSecim?: ISelectOption
  productCode: string
  companyOption?: ISelectOption
  salesOption?: ISelectOption
  enableOption?: ISelectOption
  buyersDetailData: IBuyersDetailData[]
  currency: number
  historyData: IListOpportunityHistory[]
  showHistory: boolean
  url?: string
}

function OpportunityAisleProductList(props: BaseComponentWithContextProps) {
  const [searchParams, setSearchParams] = useSearchParams()

  const searchCompany = searchParams.get('Sirket')
  const searchStatus = searchParams.get('Durum')
  const searchSold = searchParams.get('Satis')
  const searchProduct = searchParams.get('Urun')
  const searchStart = searchParams.get('Baslangic')
  const searchEnd = searchParams.get('Bitis')

  const salesOptionData = [
    { value: 1, label: 'Satılan' },
    { value: 0, label: 'Satılmayan' },
  ]

  const enableOptionData = [
    { value: 1, label: 'Aktif' },
    { value: 0, label: 'Pasif' },
  ]

  const t = translate(Translations)
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [opportunitMarket, setopportunitMarket] = useState<IListOpportunity[]>([])
  const [showDetail, setShowDetail] = useState<boolean>(false)
  const [showBuyersDetail, setShowBuyersDetail] = useState<boolean>(false)
  const navigate: any = useNavigate()
  const tableRef = useRef<any>()
  const [detail, setDetail] = useState<IOpportunityDetail>({
    Start: '',
    End: '',
    Description: '',
    ShortDescription: '',
  })

  useEffect(() => {
    getCompanies()
  }, [])

  const getCompanies = async () => {
    const company: any[] = await getCompanyApi()
    setCompanies(company)
  }
  const getInitialState = (): ISecimState => {
    return {
      productCode: searchProduct && (searchProduct != null || searchProduct != "undefined") ? searchProduct : "",
      buyersDetailData: [],
      currency: 0,
      historyData: [],
      showHistory: false,
      companyOption: searchCompany ? { label: companies.find(a => a.Name == (searchCompany))?.Name || "", value: companies.find(a => a.Name == (searchCompany))?.Name || 0 } : undefined,
      enableOption: searchStatus ? { label: enableOptionData.find(a => a.label == (searchStatus))?.label || "", value: enableOptionData.find(a => a.label == (searchStatus))?.value || 0 } : undefined,
      salesOption: searchSold ? { label: salesOptionData.find(a => a.label == (searchSold))?.label || "", value: salesOptionData.find(a => a.label == (searchSold))?.value || 0 } : undefined,
    }
  }
  useEffect(() => {
    if (searchStart)
      setStartDate(searchStart)
    if (searchEnd)
      setEndDate(searchEnd)

    getFilterOpportunitMarket()
  }, [])

  const getOpportunityMarket = async () => {
    const getOpportunityListApi: ListOpportunityMarketApi = new ListOpportunityMarketApi({})

    const res = await getOpportunityListApi.getOpportunityMarket()
    setopportunitMarket(res.sort((a, b) => Number(b.Enabled) - Number(a.Enabled)))
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ISecimState>(intialState)

  const ISecimOption: ISelectOption[] = [
    {
      label: 'Tümü',
      value: 0,
    },
    {
      label: t('pages.admin.productOperation.opportunityAisleProduct.opportunityAisleProductList.onAir'),
      value: 2,
    },
    {
      label: t('pages.admin.productOperation.opportunityAisleProduct.opportunityAisleProductList.inTheArchive'),
      value: 3,
    },
    {
      label: t('pages.admin.productOperation.opportunityAisleProduct.opportunityAisleProductList.sold'),
      value: 4,
    },
    {
      label: t('pages.admin.productOperation.opportunityAisleProduct.opportunityAisleProductList.unsold'),
      value: 5,
    },
  ]

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(searchStart ? searchStart : "")
  const [endDate, setEndDate] = useState(searchEnd ? searchEnd : "")
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }


  const updateOpportunityDetail = async (index: any) => {
    var dt = opportunitMarket.find(auth => {
      return auth.Id === index.Id
    })
    if (dt) {
      dt.flag = 'Güncelle'
      navigate('/admin/ProductOperation/AddOpportunityAisle', {
        state: {
          opportunityDetailData: dt,
          data: 'Güncelle',
          url: state.url
        },
      })
    }
  }

  const deleteOpportunity = async (id: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Ürünü silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        props.showLoading()
        const deleteopportunityApi = new DeleteOpportunityApi({})
        await deleteopportunityApi
          .deleteOpportunity(id)
          .then(isDeleted => {
            if (isDeleted) {
              Swal.fire({
                icon: 'success',
                title: 'Ürün Silindi',
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then(async result => {
                setopportunitMarket((prevState: any) => prevState.filter(item => item.Id !== id))
              })
            }
            props.hideLoading()
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          })
      }
    })
  }

  const showHistory = async (id: number) => {
    props.showLoading();
    const marketHistory: MarketHistoryApi = new MarketHistoryApi({})
    try {
      await marketHistory
        .historyByID(id)
        .then(res => {
          setState({ showHistory: true, historyData: res })
        })
      props.hideLoading();
    } catch (err: any) {
      props.hideLoading();
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  }

  const handleFiles = async (files: File[]) => {
    props.showLoading()
    const firsat: any = await excelToJSON(files[0])
    const dt: Partial<IAddOpportunity>[] = []

    firsat.forEach(code => {
      code.forEach(c => {
        dt.push({
          ProductStockCode: c.ProductCode,
          Id: 0,
          WarehouseCode: c.Depo,
          ProductOrder: c.SiraNo,
          ProductSerialNo: c.SeriNo,
          Quantity: c.Adet,
          Description: c.Aciklama,
          Price: c.Fiyat,
          StartDate: getDateByTimezoneOffset(new Date(moment(c.Start, 'DD.MM.YYYY HH:mm:ss').toDate())),
          EndDate: getDateByTimezoneOffset(new Date(moment(c.End, 'DD.MM.YYYY HH:mm:ss').toDate())),
          NightMarket: c.GecePazari === 'Evet' ? true : false,
          OutOfWarranty: c.GarantiDisi === 'Evet' ? true : false,
          Enabled: true,
          CompanyId: companies.find(a => a.Code == c.Firma)!.Id,
          ShortDescription: c.KisaNot,
          DealerCodes: c.CariKodu.split(','),
          DealerSubTypes: c.CariAltTipi.split(','),
          QuantitySold: 0,
        })
      })
    })

    const postOpportunity: PostAddOpportunityListApi = new PostAddOpportunityListApi({})
    postOpportunity
      .postOpportunityList(dt as IAddOpportunity[])
      .then((result: CommonPostResponse) => {
        if (result) {
          props.hideLoading()
          Swal.fire({
            icon: 'success',
            title: 'Fırsat Reyonu Eklendi',
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async result => {
            props.hideLoading()
          })
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const handleOpportunitMarketActive = async (row: IListOpportunity) => {
    props.showLoading()
    const req: IListOpportunity = {
      CompanyId: row.CompanyId,
      Product: row.Product,
      Image: row.Image,
      ImageUrl: row.ImageUrl,
      Id: row.Id,
      ProductId: row.ProductId,
      Images: row.Images,
      ProductStockCode: row.ProductStockCode,
      WarehouseCode: row.WarehouseCode,
      ProductOrder: row.ProductOrder,
      ProductSerialNo: row.ProductSerialNo,
      Quantity: row.Quantity,
      QuantitySold: row.QuantitySold,
      Description: row.Description,
      Price: row.Price,
      StartDate: row.StartDate,
      EndDate: row.EndDate,
      NightMarket: row.NightMarket,
      OutOfWarranty: row.OutOfWarranty,
      Enabled: !row.Enabled,
      PriceDisplay: row.PriceDisplay,
      ShortDescription: row.ShortDescription,
      DealerCodes: row.DealerCodes,
      DealerSubTypes: row.DealerSubTypes,
    }
    if (req) {
      props.showLoading()
      const updateOpportunity: UpdateOpportunityApi = new UpdateOpportunityApi({})
      updateOpportunity
        .putOpportunity(req)
        .then(async result => {
          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'Ürün Güncellendi',
              showConfirmButton: true,
              allowOutsideClick: false,
            }).then(async result => {
              const updatedData = opportunitMarket.map(pState => {
                if (pState.Id === row.Id) {
                  pState.Enabled = !pState.Enabled
                }

                return pState
              })

              setopportunitMarket(updatedData)
            })
          }
          props.hideLoading()
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
    }
  }

  const getFilterOpportunitMarket = async () => {
    const queryParams = {
      Sirket: state.companyOption ? companies.find(a => a.Name == (state.companyOption?.label))?.Name : undefined,
      Durum: state.enableOption ? enableOptionData.find(a => a.value == (state.enableOption?.value))?.label : undefined,
      Satis: state.salesOption ? salesOptionData.find(a => a.value == (state.salesOption?.value))?.value : undefined,
      Urun: (state.productCode !== "" && state.productCode != null) ? state.productCode : undefined,
      Baslangic: startDate ? startDate : undefined,
      Bitis: endDate ? endDate : undefined,
    };

    const filteredQueryParams = Object.entries(queryParams)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const navigateRoute = `/admin/ProductOperation/OpportunityAisleProductList?${filteredQueryParams}`;

    setState({ url: filteredQueryParams })
    navigate(navigateRoute)


    const filterOpportunitRequest: IOpportunitFilter = {
      CompanyId: state.companyOption && +state.companyOption.value,
      ProductCode: state.productCode,
      DealerCode: '',
      DealerSubType: '',
      StartDate: startDate ? getDateByTimezoneOffset(new Date(startDate)) : null,
      EndDate: endDate ? getDateByTimezoneOffset(new Date(endDate)) : null,
      IsEnabled: state.enableOption && (+state.enableOption.value === 1 ? true : false),
      IsSold: state.salesOption && (+state.salesOption.value === 1 ? true : false),
    }
    props.showLoading()
    const opportunitRequest = new ListOpportunityMarketApi({})
    opportunitRequest
      .postOpportunityFilters(filterOpportunitRequest)
      .then(res => {
        setopportunitMarket(res)
        props.hideLoading()
      })
      .catch(e => {
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const getBuyersDetails = async (item: IListOpportunity) => {
    props.showLoading()
    const opportunitRequest = new ListOpportunityMarketApi({})
    opportunitRequest
      .getBuyersDetails(item.Id)
      .then(res => {
        setShowBuyersDetail(true)
        setState({ buyersDetailData: res, currency: item.Currency })
        props.hideLoading()
      })
      .catch(e => {
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Aktif',
        accessor: 'col1',
      },
      {
        Header: t('pages.admin.productOperation.opportunityAisleProduct.opportunityAisleProductList.order'),
        accessor: 'col2',
      },
      {
        Header: t('pages.admin.productOperation.opportunityAisleProduct.opportunityAisleProductList.code'),
        accessor: 'col3',
      },
      {
        Header: t('pages.admin.productOperation.opportunityAisleProduct.opportunityAisleProductList.warehouseCode'),
        accessor: 'col4',
      },
      {
        Header: 'Şirket',
        accessor: 'col5',
      },
      {
        Header: 'Seri No',
        accessor: 'col6',
      },
      {
        Header: 'Detay',
        accessor: 'col7',
      },
      {
        Header: 'Fiyat',
        accessor: 'col8',
      },
      {
        Header: t('pages.admin.productOperation.opportunityAisleProduct.opportunityAisleProductList.number'),
        accessor: 'col9',
      },
      {
        Header: t('pages.admin.productOperation.opportunityAisleProduct.opportunityAisleProductList.sold'),
        accessor: 'col10',
      },
      {
        Header: t('pages.admin.productOperation.opportunityAisleProduct.opportunityAisleProductList.sell'),
        accessor: 'col11',
      },
      {
        Header: 'İşlemler',
        accessor: 'col12',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      opportunitMarket.map(item => {
        const dat = companies.find(com => {
          return com.Id === item.CompanyId
        })
        const data = {
          col1: (
            <>
              <Switch.Root
                className='SwitchRoot'
                id='active'
                checked={item.Enabled}
                onCheckedChange={() => handleOpportunitMarketActive(item)}
              >
                <Switch.Thumb className='SwitchThumb' />
              </Switch.Root>
            </>
          ),
          col2: item.ProductOrder,
          col3: item.ProductStockCode,
          col4: item.WarehouseCode,
          col5: dat ? dat.Name : '',
          col6: item.ProductSerialNo,
          col7: (
            <button
              type='button'
              className={'btn btn-sm btn-secondary'}
              onClick={() => {
                setShowDetail(true)
                setDetail({
                  Start: moment(item.StartDate).format('DD.MM.YYYY HH:mm:ss'),
                  End: moment(item.EndDate).format('DD.MM.YYYY HH:mm:ss'),
                  Description: item.Description,
                  ShortDescription: item.ShortDescription,
                })
              }}
            >
              Detay
            </button>
          ),
          col8: item.PriceDisplay,
          col9: item.Quantity,
          col10:
            item.QuantitySold > 0 ? (
              <div onClick={() => getBuyersDetails(item)} style={{ cursor: 'pointer' }}>
                {item.QuantitySold}
              </div>
            ) : (
              item.QuantitySold
            ),
          col11: item.Quantity - item.QuantitySold > 0 ? 'H' : 'E',
          col12: (
            <div>
              <a
                data-action-type='edit'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title={t('pages.mySpecial.userUpdate.edit')}
                onClick={() => updateOpportunityDetail(item)}
              >
                <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
              </a>
              <a
                data-action-type='delete'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title={t('pages.mySpecial.userUpdate.delete')}
                onClick={() => deleteOpportunity(item.Id)}
              >
                <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
              </a>
              <a style={{ cursor: 'pointer', color: 'blue' }} onClick={() => showHistory(item.Id)}><i className='icon-clock' style={{ fontSize: '1.2rem', color: 'blue' }}></i></a>
            </div>
          ),
        }

        return data
      }),
    [opportunitMarket]
  )

  const tableColumns1 = useMemo(
    () => [
      {
        Header: 'Bayi',
        accessor: 'col1',
      },
      {
        Header: 'Açıklama',
        accessor: 'col2',
      },
      {
        Header: 'Depo',
        accessor: 'col3',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'col4',
      },
      {
        Header: 'Adet',
        accessor: 'col5',
      },
      {
        Header: 'Birim Fiyatı',
        accessor: 'col6',
      },
      {
        Header: 'Toplam',
        accessor: 'col7',
      },
      {
        Header: 'Kur',
        accessor: 'col8',
      },
      {
        Header: 'Seri No',
        accessor: 'col9',
      },
    ],
    []
  )

  const tableData1 = useMemo(
    () =>
      state.buyersDetailData.map(item => {
        const data = {
          col1: item.DealerCode.replace('0000', ''),
          col2: <p dangerouslySetInnerHTML={{ __html: item.Description }}></p>,
          col3: item.WarehouseCode,
          col4: item.ProductCode,
          col5: item.Quantity,
          col6: item.Price.toFixed(2),
          col7: (item.Quantity * item.Price).toFixed(2),
          col8: item.Currency
            ? item.Currency === '0'
              ? 'TRY'
              : item.Currency === '1'
                ? 'USD'
                : item.Currency === '2'
                  ? 'EUR'
                  : ''
            : state.currency === 0
              ? 'TRY'
              : state.currency === 1
                ? 'USD'
                : state.currency === 2
                  ? 'EUR'
                  : '',
          col9: item.ProductSeriNo,
        }

        return data
      }),
    [state.buyersDetailData]
  )


  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex justify-content-between align-items-center'>
          <div className='form-group'>
            <span className='h4'>
              {t('pages.admin.productOperation.opportunityAisleProduct.opportunityAisleProductList.header')}
            </span>
          </div>
          <div className='form-group' style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
            <a
              style={{ color: 'red', marginRight: '10px' }}
              target={'_blank'}
              href='/assetWeb/docs/firsatExcel.xlsx'
              className='infoMarker'
            >
              Örnek Excel
            </a>
            <div style={{ marginRight: '10px' }}></div>
            <IPFile
              id='FileInputProductCode'
              multiple={false}
              handleFiles={e => handleFiles(e)}
              removeAfterSelect={true}
              className='productCodeFileInput'
              label=' Excel Yükle'
              excelIcon
            />
            <div style={{ marginRight: '10px' }}></div>
            <Link to='/admin/ProductOperation/AddOpportunityAisle'>
              <IPButton type='button' className='btn btn-primary' text='Ekle' style={{ width: '25px' }} />
            </Link>
          </div>
        </div>
        <div className='card-body'>
          <form className={`formbanner`}>
            <div className='row'>
              <div className='col-lg-3'>
                <InputCampaign
                  type='date'
                  id='StartDate'
                  name='StartDate'
                  label={'Başlangıç Tarihi'}
                  value={toDateTimeLocal(startDate)}
                  onChange={e => getDate(e.target.value, 'Start')}
                  isActive
                />
              </div>
              <div className='col-lg-3'>
                <InputCampaign
                  type='date'
                  id='EndDate'
                  name='EndDate'
                  label={'Bitiş Tarihi'}
                  value={toDateTimeLocal(endDate)}
                  onChange={e => getDate(e.target.value, 'End')}
                  isActive
                />
              </div>
              <div className='col-lg-3'>
                <IPInput
                  type='text'
                  placeholder='Ürün Kodu'
                  className='form-control'
                  name='ProductCode'
                  onChange={e => {
                    setState({ productCode: e.target.value })
                  }}
                  value={state.productCode}
                />
              </div>
              <div className='col-lg-3'>
                <IPSelectBox
                  isClearable
                  placeholder='Şirket'
                  options={
                    companies &&
                    companies.map((item: ICompany) => ({
                      label: item.Name,
                      value: `${item.Id}`,
                    }))
                  }
                  value={state.companyOption}
                  onChangeSingle={option => setState({ companyOption: option })}
                />
              </div>
            </div>
            <div className='row' style={{ marginTop: '15px' }}>
              <div className='col-lg-3'>
                <IPSelectBox
                  placeholder='Aktiflik Durumu'
                  isClearable
                  options={enableOptionData}
                  value={state.enableOption}
                  onChangeSingle={option => setState({ enableOption: option })}
                />
              </div>
              <div className='col-lg-3'>
                <IPSelectBox
                  isClearable
                  placeholder='Satış Durumu'
                  options={salesOptionData}
                  value={state.salesOption}
                  onChangeSingle={option => setState({ salesOption: option })}
                />
              </div>
              <div className='col-md-1'>
                <IPButton
                  type='button'
                  className='btn btn-success'
                  onClick={getFilterOpportunitMarket}
                  text={t('pages.admin.productOperation.opportunityAisleProduct.opportunityAisleProductList.filtre')}
                />
              </div>
            </div>
          </form>
          <br />
          <div className='row'>
            <div className='col-lg-12'>
              <div
                id='datatable_wrapper'
                className={`dataTables_wrapper dt-bootstrap4 ${css`
                  #datatb_length label {
                    min-width: 200px;
                  }
                `}`}
              >
                <div className='m-4'>
                  <Table columns={tableColumns} data={tableData} currentPage={0} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <IPModal
        show={showDetail}
        onClose={() => setShowDetail(false)}
        hideConfirm={true}
        width={800}
        title='Fırsat Detay'
      >
        <div className='col'>
          <div className='d-flex align-items-center justify-content-start'>
            <b style={{ width: '400px' }}>Başlangıç Tarihi</b>
            <span className='mr-2'>:</span>
            {detail.Start}
          </div>
          <div className='d-flex align-items-center justify-content-start'>
            <b style={{ width: '400px' }}>Bitiş Tarihi</b> <span className='mr-2'>:</span>
            {detail.End}
          </div>
          <div className='d-flex align-items-center justify-content-start'>
            <b style={{ width: '400px' }}>Açıklama</b> <span className='mr-2'>:</span>
            {detail.Description}
          </div>
          <div className='d-flex align-items-center justify-content-start'>
            <b style={{ width: '400px' }}>Kısa Açıklama</b> <span className='mr-2'>:</span>
            {detail.ShortDescription}
          </div>
        </div>
      </IPModal>
      <IPModal
        show={showBuyersDetail}
        onClose={() => setShowBuyersDetail(false)}
        hideConfirm={true}
        width={1200}
        title='Satış Detay'
      >
        <Table columns={tableColumns1} data={tableData1} currentPage={0} />
      </IPModal>
      <IPModal
        show={state.showHistory}
        onClose={() => setState({ showHistory: false, historyData: [] })}
        hideConfirm={true}
        width={1200}
        title='Tarihçe'
      >
        <table className='table'>
          <thead>
            <tr role='row' className='odd'>
              <th>#</th>
              <th>Tarih</th>
              <th>Bayi Kodu</th>
              <th>Kullanıcı Adı</th>
            </tr>
          </thead>

          <tbody>
            {
              state.historyData && state.historyData.length > 0 && state.historyData.map((his) => (
                <tr role='row' className='odd' key={his.Id}>
                  <td>{<a
                    className='btn btn-transparent'
                    style={{ color: 'blue' }}
                    type='button'
                    data-action-type='order'
                    onClick={() => {
                      let data: string[] = his.DealerCodes.toString().split(',');
                      his.DealerCodes = data as []
                      if (his.DealerSubTypes.toString().length > 0) {
                        let data2: string[] = his.DealerSubTypes.toString().split(',');
                        his.DealerSubTypes = data2 as []
                      }

                      navigate('/admin/ProductOperation/AddOpportunityAisle', {
                        state: {
                          opportunityDetailData: his,
                          data: 'History',
                          url: state.url
                        },
                      })
                    }}
                  >
                    {his.Id}
                  </a>}</td>
                  <td>{his.UpdateTime && moment(his.UpdateTime).format('DD.MM.YYYY HH:mm:ss')}</td>
                  <td>{his.UserDealer && his.UserDealer.replace('0000', '')}</td>
                  <td>{his.UserName}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </IPModal>
    </div>
  )
}
export default componentWithContext(OpportunityAisleProductList)
