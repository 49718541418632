import { useEffect, useMemo, useState } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { GetOrderReportApi } from '../services/getOrderReportApi'
import Swal from 'sweetalert2'
import { IOrderReturn, IReturnList, IReturnListHistoryModel, ITableOrderRetunFormDetails } from '../models/models'
import './MyReturns.scss'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPModal from '../../../../../components/IPModal'
import { OrderReturnStateChangeApi } from '../services/orderReturnStateChangeApi'
import IPButton from '../../../../../components/IPButton'
import './MyReturns.scss'
import { getDateByTimezoneOffset, modeOfDelivery, productStatus, reasonForReturn } from '../../../../../base/utils/dataHelper'
import IPImage from '../../../../../components/IPImage'
import { ICompany } from '../../OrderOperation/models/responseModels'
import { ISelectOption } from '../../../HomeAndSiteOperation/BulkAdding/components/BulkAdding'
import IPSelectBox from '../../../../../components/IPSelectBox'
import IPInput from '../../../../../components/IPInput'
import InputCampaign from '../../../../../components/InputCampaign'
import IPCheckbox from '../../../../../components/IPCheckBox'
import { SetDeletedApi } from '../services/setDeletedApi'
import moment from 'moment'
import { GetOrderReturnHistoryApi } from '../services/getOrderReturnHistoryApi'
import Table from '../../../../../components/Table'
interface IReturnReportState {
  showModal: boolean
  tableOrderRetunFormDetails: ITableOrderRetunFormDetails[]
  companies: ICompany[]
  selectedCompany?: ISelectOption,
  trackNo?: string
  reason?: string
  isDeleted: boolean
  selectedType?: ISelectOption
  selectedStatus?: ISelectOption
  selectedReason?: ISelectOption
  showHistory: boolean
  showDecline?: boolean
  showDeclineNumber?: number
  historyData: IReturnListHistoryModel[]
}

function Index(props: BaseComponentWithContextProps) {
  const getInitialState = (): IReturnReportState => {
    return {
      showModal: false,
      tableOrderRetunFormDetails: [],
      companies: [],
      selectedCompany: undefined,
      isDeleted: false,
      showHistory: false,
      historyData: []

    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IReturnReportState>(intialState)
  const [returnList, setReturnList] = useState<IReturnList[]>([])

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Bayi Kodu',
        accessor: 'col1',
      },
      {
        Header: 'Bayi Adı',
        accessor: 'dealerName',
      },
      {
        Header: 'MT',
        accessor: 'MT',
      },
      {
        Header: 'Sipariş No',
        accessor: 'col2',
      },
      {
        Header: 'Şirket',
        accessor: 'col3',
      },
      {
        Header: 'İşlem Tipi',
        accessor: 'col4',
      },
      // {
      //   Header: 'Sipariş Tarihi',
      //   accessor: 'col4',
      // },
      {
        Header: 'Takip No',
        accessor: 'col5',
      },
      {
        Header: 'Takip Tarihi',
        accessor: 'col6',
      },
      {
        Header: 'PO Numarası',
        accessor: 'col7',
      },
      {
        Header: 'İade Fatura No',
        accessor: 'col8',
      },

      {
        Header: 'İptal/İade Detayı',
        accessor: 'col9',
      },
      {
        Header: 'Red Nedeni',
        accessor: 'red',
      },
      {
        Header: 'Durum',
        accessor: 'col10',
      },
      {
        Header: 'Silindi',
        accessor: 'col11',
      },
      {
        Header: 'Tarihçe',
        accessor: 'Tarih',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      returnList.map(item => {
        const data = {
          col1: item.DealerCode.replace("0000", ""),
          dealerName: item.DealerName,
          col2: item.SiparisNo,
          col3: item.TableOrderRetunFormDetails[0].Company === "1000" ? "Index" :
            item.TableOrderRetunFormDetails[0].Company === "1100" ? "Datagate" :
              item.TableOrderRetunFormDetails[0].Company === "1200" ? "Despec" :
                item.TableOrderRetunFormDetails[0].Company === "2300" ? "Netex" : "",
          col4: item.IslemTipi === 0 ? "İptal" : "İade",
          col5: item.TakipFormNo,
          col6: new Date(item.CreatedDate).toLocaleDateString(),
          col7: item.PONumber,
          col8: item.IadeFaturaNo,
          red: item.RedNedeni,
          col9: <div
            className='tableIcon'
            onClick={() => {
              setState({
                showModal: true,
                tableOrderRetunFormDetails: item.TableOrderRetunFormDetails
              })
            }}
          >
            <i className='icon-info'></i>
          </div>,
          col10:
            item.Durum === 0
              ? (
                <div>
                  <a
                    data-action-type='edit'
                    role='button'
                    style={{ cursor: 'pointer' }}
                    data-toggle='tooltip'
                    data-original-title="Red"
                    onClick={() => setState({ showDecline: true, showDeclineNumber: item.Id })}
                    className='CellWithComment'
                  >
                    <i className="fa fa-thumbs-down" style={{ color: "red", width: "20px" }}></i>
                    <span className='CellComment'>Reddet</span>
                  </a>
                  {
                    <a
                      data-action-type='edit'
                      role='button'
                      style={{ cursor: 'pointer' }}
                      data-toggle='tooltip'
                      data-original-title="Onayla"
                      onClick={() => getOrderReturnRed(item.Id, 2)}
                      className='CellWithComment ml-auto'
                    >
                      <i className="fa fa-thumbs-up" style={{ color: "#29db7f", width: "20px" }}></i>
                      <span className='CellComment'>Onayla</span>
                    </a>
                  }

                </div >
              ) :
              (
                <span
                  style={{
                    color:
                      item.Durum === 1 ? 'red' : item.Durum === 3 ? 'green' : 'blue',
                  }}
                >
                  {item.Durum === 1
                    ? 'Reddedildi'
                    : item.Durum === 3
                      ? 'Tamamlandı'
                      : 'Onaylandı'}
                </span>
              ),
          col11: <div>
            <IPCheckbox
              onClick={() => setDeleted(item.Id, item.Deleted)}
              checked={item.Deleted}
            />
          </div>,
          MT: item.MT,
          Tarih: <a
            data-action-type='copy'
            role='button'
            style={{ cursor: 'pointer' }}
            data-toggle='tooltip'
            data-original-title='Tarihçe'
            onClick={() => {
              showHistory(item.Id)
            }}
          >
            <i className='icon-clock' style={{ fontSize: '1.2rem', color: 'blue' }}></i>
          </a>,
        }
        return data
      }),
    [returnList]
  )

  const showHistory = async (id: number) => {
    props.showLoading()
    const campaignHistory: GetOrderReturnHistoryApi = new GetOrderReturnHistoryApi({})
    try {
      await campaignHistory.getOrderReturnHistory(id).then(res => {
        setState({ showHistory: true, historyData: res })
      })
      props.hideLoading()
    } catch (err: any) {
      props.hideLoading()
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  useEffect(() => {
    getOrderReport()
  }, [])

  const getOrderReport = async () => {
    const req = {
      StartDate: startDate ? getDateByTimezoneOffset(startDate) : undefined,
      EndDate: endDate ? getDateByTimezoneOffset(endDate) : undefined,
      DocNo: state.trackNo ? state.trackNo : undefined,
      Type: state.selectedType?.value,
      Status: state.selectedStatus?.value,
    }
    props.showLoading()
    const getOrderReportApi: GetOrderReportApi = new GetOrderReportApi({})
    getOrderReportApi
      .getOrderReport(req)
      .then((result: IReturnList[]) => {
        let data = result
        if (state.selectedCompany)
          data = result.filter(a => a.TableOrderRetunFormDetails[0].Company === state.selectedCompany?.value)
        setReturnList(data)
        props.hideLoading()
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const setDeleted = async (id: number, type: boolean) => {
    Swal.fire({
      icon: 'question',
      title: `${type === false ? "Silindi olarak işaretlemek" : "Silindi bilgisini kaldırmak"} istediğinize emin misiniz?`,
      showConfirmButton: true,
      confirmButtonText: "Evet",
      showDenyButton: true,
      denyButtonText: "Hayır",
      allowOutsideClick: false,
    }).then((res) => {
      if (res.isConfirmed) {
        props.showLoading()
        const setDeletedApi: SetDeletedApi = new SetDeletedApi({})
        setDeletedApi
          .setDeleted(id)
          .then((result: any) => {
            if (result) {
              props.hideLoading()
              getOrderReport()
            }
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          })
      }
    })
  }

  const getOrderReturnRed = async (id: number, type: number) => {
    Swal.fire({
      icon: 'question',
      title: `${type === 1 ? "Reddetmek" : "Onaylamak"} istediğinize emin misiniz?`,
      showConfirmButton: true,
      confirmButtonText: "Evet",
      showDenyButton: true,
      denyButtonText: "Hayır",
      allowOutsideClick: false,
    }).then((res) => {
      if (res.isConfirmed) {
        props.showLoading()
        const req: IOrderReturn = {
          DetailId: id,
          Durum: type,
          Reason: state.reason ? state.reason : state.selectedReason?.label
        }
        const orderReturnStateChangeApi: OrderReturnStateChangeApi = new OrderReturnStateChangeApi({})
        orderReturnStateChangeApi
          .orderReturnStateChange(req)
          .then((result: any) => {
            setReturnList(prevState => {
              return prevState.map((prev: IReturnList) => {
                if (prev.Id === id) {
                  prev = {
                    BelgeNo: prev.BelgeNo,
                    CreatedDate: prev.CreatedDate,
                    DealerCode: prev.DealerCode,
                    MT: prev.MT,
                    DealerName: prev.DealerName,
                    Deleted: prev.Deleted,
                    Durum: type,
                    IadeFaturaNo: prev.IadeFaturaNo,
                    Id: prev.Id,
                    PONumber: prev.PONumber,
                    IslemTipi: prev.IslemTipi,
                    RedNedeni: (state.reason ? state.reason : state.selectedReason?.label) || prev.RedNedeni,
                    SiparisNo: prev.SiparisNo,
                    TableOrderRetunFormDetails: prev.TableOrderRetunFormDetails,
                    TakipFormNo: prev.TakipFormNo,
                    UpdatedDate: prev.UpdatedDate,
                    UserId: prev.UserId,
                    MTPersonelNumber: prev.MTPersonelNumber
                  }
                }
                return prev
              })
            })
            setState({ selectedReason: undefined, reason: undefined, showDecline: false })
            props.hideLoading()
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          })
      }
    })
  }

  const companies = [
    { label: 'Index', value: "1000" },
    { label: 'Despec', value: "1200" },
    { label: 'Netex', value: "2300" },
    { label: 'Datagate', value: "1100" },
  ]

  const types = [
    { label: 'İptal', value: 0 },
    { label: 'İade', value: 1 },
  ]

  const status = [
    { label: 'Onaylananlar', value: 2 },
    { label: 'Reddedilenler', value: 1 },
    { label: 'Silinenler', value: 3 },
    { label: 'İşlem Görmemişler', value: 0 },
  ]

  const redNedenleri = [
    { label: 'Ambalaj Problemi', value: 0 },
    { label: 'Arızalı/Hasarlı Ürün İadesi', value: 1 },
    { label: 'Hatalı Sipariş', value: 2 },
    { label: 'Teslim Alınmadan Geri Dönen Ürün İadesi', value: 3 },
    { label: 'Diğer', value: 4 },
  ]


  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  const toDateTimeLocal = date => {
    if (date != null) {
      return date
        .toLocaleString('sv-SE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        .replace(' ', 'T')
    }
    return undefined
  }

  return (
    <div className=''>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <div className='col-lg-10 d-flex align-items-center'>
              <h4>İptal / İade Durum İzleme</h4>
            </div>
            <div className='col-lg-1 d-flex justify-content-end'>
              <IPButton text='Göster' className='btn btn-danger' onClick={getOrderReport} />
            </div>
          </div>

          <div className='row ml-2 mr-2'>
            <div className='col-lg-2'>
              <label htmlFor='company' className='form-label'>
                Şirket
              </label>
              <IPSelectBox
                isClearable
                id='Şirket'
                name='Şirket'
                options={companies}
                value={state.selectedCompany}
                onChangeSingle={(option) => setState({ selectedCompany: option })}
              />
            </div>
            <div className='col-lg-2'>
              <label htmlFor='trackNo' className='form-label'>
                Takip No
              </label>
              <IPInput
                type='text'
                className='form-control'
                name='TakipNo'
                placeholder='Takip Numarası'
                onChange={(e) => setState({ trackNo: String(e.target.value).trim() })}
                value={state.trackNo}
              />
            </div>
            <div className='col-lg-2 mt-5'>
              <InputCampaign
                type='date'
                style={{ width: '100%' }}
                id='StartDate'
                name='StartDate'
                label={'Başlangıç Tarihi'}
                value={toDateTimeLocal(startDate)}
                onChange={e => getDate(e.target.value, 'Start')}
                isActive
              />
            </div>
            <div className='col-lg-2 mt-5'>
              <InputCampaign
                style={{ width: '100%' }}
                type='date'
                id='EndDate'
                name='EndDate'
                label={'Bitiş Tarihi'}
                value={toDateTimeLocal(endDate)}
                onChange={e => getDate(e.target.value, 'End')}
                isActive
              />
            </div>
            <div className='col-lg-2 mt-3'>
              <label htmlFor='trackNo' className='form-label'>
                İşlem Tipi
              </label>
              <IPSelectBox
                isClearable
                placeholder='İşlem Tipi'
                options={types}
                value={state.selectedType}
                onChangeSingle={option => setState({ selectedType: option })}
              />
            </div>
            <div className='col-lg-2 mt-3'>
              <label htmlFor='trackNo' className='form-label'>
                Durum
              </label>
              <IPSelectBox
                isClearable
                placeholder='Durum'
                options={status}
                value={state.selectedStatus}
                onChangeSingle={option => setState({ selectedStatus: option })}
              />
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <Table columns={tableColumns} data={tableData} currentPage={0} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <IPModal
        width={1200}
        show={state.showModal}
        onClose={() =>
          setState({
            showModal: false,
            tableOrderRetunFormDetails: []
          })
        }
        closeOnEscapeKey={true}
        hideConfirm={true}
        title="İptal / İade Detay"
      >
        <table className='campaignTable table table-bordered table-hover'>
          <thead>
            <tr>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>SİPARİŞ NO</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>ÜRÜN KODU</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>ÜRÜN ADI</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>ADET</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>BİRİM TUTAR</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>TOPLAM TUTAR</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>PB</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>ÜRÜN DURUMU</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>TESLİMAT ŞEKLİ</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>İADE NEDENİ</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>AÇIKLAMA</th>

            </tr>
          </thead>

          <tbody>
            {
              state.tableOrderRetunFormDetails && state.tableOrderRetunFormDetails.length > 0 && state.tableOrderRetunFormDetails.map((list) => (
                <tr key={list.Id}>
                  <td style={{ fontSize: "12px" }}>{list.SiparisNo}</td>
                  <td style={{ fontSize: "12px" }}>{list.MalzemeNo}</td>
                  <td style={{ fontSize: "12px" }}>{list.MalzemeAdi}</td>
                  <td style={{ fontSize: "12px" }}>{list.Miktar}</td>
                  <td style={{ fontSize: "12px" }}>{list.BirimFiyati.toFixed(2)}</td>
                  <td style={{ fontSize: "12px" }}>{(list.Miktar * list.BirimFiyati).toFixed(2)}</td>
                  <td style={{ fontSize: "12px" }}>{list.Pb === 0 ? "TRY" : (list.Pb === 1 ? "USD" : "EUR")}</td>
                  <td style={{ fontSize: "12px" }}>{productStatus(list.UrunDurumu)}</td>
                  <td style={{ fontSize: "12px" }}>{modeOfDelivery(list.TeslimatSekli)}</td>
                  <td style={{ fontSize: "12px" }}>{reasonForReturn(list.IadeNedeni)}</td>
                  <td style={{ fontSize: "12px" }}>{list.Aciklama}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </IPModal>
      <IPModal
        show={state.showHistory}
        onClose={() => setState({ showHistory: false, historyData: [] })}
        hideConfirm={true}
        width={1200}
        title='Tarihçe'
      >
        <table className='table'>
          <thead>
            <tr role='row' className='odd'>
              <th>#</th>
              <th>Güncellenme Tarihi</th>
              <th>Güncelleyen Bayi </th>
              <th>Güncelleyen Kullanıcı</th>
              <th>Sipariş No</th>
              <th>Takip No</th>
              <th>PO Numarası</th>
              <th>Iade Fatura No</th>
              <th>Durum</th>
              <th>Silindi</th>
            </tr>
          </thead>

          <tbody>
            {state.historyData &&
              state.historyData.length > 0 &&
              state.historyData.map(his => (
                <tr role='row' className='odd' key={his.Id}>
                  <td>
                    {his.Id}
                  </td>
                  <td>{his.HistoryTime && moment(his.HistoryTime).format('DD.MM.YYYY HH:mm:ss')}</td>
                  <td>{his.HistoryUserDealer && his.HistoryUserDealer.replace('0000', '')}</td>
                  <td>{his.HistoryUserName}</td>
                  <td>{his.SiparisNo}</td>
                  <td>{his.TakipFormNo}</td>
                  <td>{his.PONumber}</td>
                  <td>{his.IadeFaturaNo}</td>
                  <td>{
                    his.Durum === 0
                      ? (
                        <div>
                          <i className="fa fa-thumbs-down" style={{ color: "red", width: "20px" }}></i>
                          <span className='CellComment'>Reddet</span>
                          {
                            <>
                              <i className="fa fa-thumbs-up" style={{ color: "#29db7f", width: "20px" }}></i>
                              <span className='CellComment'>Onayla</span>
                            </>
                          }

                        </div >
                      ) :
                      (
                        <span
                          style={{
                            color:
                              his.Durum === 1 ? 'red' : his.Durum === 3 ? 'green' : 'blue',
                          }}
                        >
                          {his.Durum === 1
                            ? 'Reddedildi'
                            : his.Durum === 3
                              ? 'Tamamlandı'
                              : 'Onaylandı'}
                        </span>
                      )}</td>
                  <td>{his.Deleted ? "Evet" : "Hayır"}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </IPModal>
      <IPModal
        show={state.showDecline === true}
        onClose={() => setState({ showDecline: false, selectedReason: undefined, reason: undefined })}
        hideConfirm={false}
        width={800}
        height={500}
        title='Red Nedeni Seçiniz'
        onConfirm={() => {
          if (state.showDeclineNumber != null)
            getOrderReturnRed(state.showDeclineNumber, 1)
        }}
      >
        <table className='table'>
          <tbody>
            <tr role='row' className='odd'>
              <td>
                <IPSelectBox
                  isClearable
                  placeholder='Durum'
                  options={redNedenleri}
                  value={state.selectedReason}
                  onChangeSingle={option => setState({ selectedReason: option })}
                />
              </td>
            </tr>
            {
              state.selectedReason?.value === 4 && (
                <>
                  <br></br>
                  <tr>
                    <td>
                      <IPInput
                        type='text'
                        className='form-control'
                        name='Reason'
                        placeholder='Red nedeni yazınız.'
                        onChange={(e) => setState({ reason: e.target.value })}
                        value={state.reason}
                      />
                    </td>
                  </tr>
                </>
              )
            }
          </tbody>
        </table>
      </IPModal>
    </div>
  )
}

export default componentWithContext(Index)
