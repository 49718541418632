import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { CheckUsedRequestModel } from '../../../ProductOperation/CampaignBundleCrud/services/InsertFcvService'

export class CheckUsedTotal extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerPaymentOperation/checkUsedTotal',
      requesterInfo,
    })
  }

  public async checkUsedTotal(request: CheckUsedRequestModel): Promise<any> {
    //this.props.url = `${this.props.url}/${Id}`
    return await this.postAsync<CheckUsedRequestModel>(request)
  }
}
