import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { ISuccessFullRequest, ISuccessFullResponse } from '../models/models'

export class GetSuccessFullApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/GetSuccesPos',
      requesterInfo,
    })
  }

  public async getSuccessFull(request: ISuccessFullRequest): Promise<ISuccessFullResponse[]> {
    return await this.postAsync<ISuccessFullResponse[]>(request)
  }
}
