import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import IPImage from '../../../components/IPImage'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'
import Swal from 'sweetalert2'
import { IMenu } from '../models/models'
import { useCustomReducer } from '../../../base/customHooks'
import './style.scss'
import { downloadFileFromUrl } from '../../../base/utils/dataHelper'
import { GetNetChannelContentApi } from '../services/getNetChannelContentApi'
import classes from '../../../components/IPButton/IPButton.module.scss'
import { NetLoginCheckApi } from '../services/netLoginCheckApi'

interface INetChannelState {
  contentData?: IMenu[]
}

function Index(props: BaseComponentWithContextProps) {
  useEffect(() => {
    netChannelLoginCheck()
  }, [])

  const getInitialState = (): INetChannelState => {
    return {}
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<INetChannelState>(intialState)
  const [productType, setProductType] = useState<any>(null)
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()

  const getNetChannelContent = async () => {
    props.showLoading()

    const contentapi: GetNetChannelContentApi = new GetNetChannelContentApi({})
    try {
      await contentapi
        .getDataList()
        .then(result => {
          if (result) {
            setState({ contentData: result })
          }
          props.hideLoading()
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
    } catch {
      props.hideLoading()
    }
  }

  const netChannelLoginCheck = async () => {
    const check: NetLoginCheckApi = new NetLoginCheckApi({})
    try {
      const response = await check.netLoginCheck()
      if (!response) {
        navigate('/homepage')
      } else {
        pageControl()
        getNetChannelContent()
      }
    } catch (error) {
      console.error('Error checking net login:', error)
    }
  }

  const changePage = async (param: string) => {
    navigate(`?page=${param}`)
    setProductType(param)
    const element = document.getElementById(param) // Replace 'elementId' with the ID of the element you want to scroll to
    // Scroll the page to the element
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const pageControl = async () => {
    const param = searchParams.get('page')
    setProductType(param ? param : '')
  }

  const locations = [
    { label: 'Banner', value: 1 },
    { label: 'Ürünler', value: 2 },
    { label: 'Eğitim Dokümanları', value: 3 },
    { label: 'Görseller', value: 4 },
    { label: 'Dokümanlar', value: 5 },
  ]

  return (
    <>
      <div className='container current-account'>
        <div className='content col-lg-12 m-auto'>
          <div className=''>
            <div className='card-body'>
              <br></br>
              {/* EĞİTİM */}
              {
                <>
                  <br></br>
                  <div className='row m-t-10 mb-5'>
                    <div className='col d-flex card-mobile-resize justify-content-center'>
                      {/* <div className='room' onClick={() => changePage("Docs")}>
                      <div className='room ms-5 text-center image-hover cursor-pointer'>
                        <div className='text-center position-relative'>
                          <IPImage className='Iphone' style={{ padding: '30px' }} src='' alt='' />
                          <IPImage className='Iphone-hover' style={{ padding: '30px' }} src='' alt='' />
                        </div>
                        <div className='row' style={{ textAlign: 'center' }}>
                          <h3 style={{ marginTop: '-50px' }}>iPhone Ürün Serisi</h3>
                        </div>
                      </div>
                    </div> */}
                      <div className='room' onClick={() => changePage('Education')}>
                        <div className='room ms-5 text-center image-hover cursor-pointer'>
                          <div className='text-center position-relative'>
                            <IPImage className='Iphone' style={{ padding: '30px' }} src='' alt='' />
                            <IPImage className='Iphone-hover' style={{ padding: '30px' }} src='' alt='' />
                          </div>
                          <div className='row' style={{ textAlign: 'center' }}>
                            <h3 style={{ marginTop: '-50px' }}>Eğitim Dokümanları</h3>
                          </div>
                        </div>
                      </div>
                      {/* <div className='room' onClick={() => changePage("Pics")}>
                      <div className='room ms-5 text-center image-hover cursor-pointer'>
                        <div className='text-center position-relative'>
                          <IPImage className='Iphone' style={{ padding: '30px' }} src='' alt='' />
                          <IPImage className='Iphone-hover' style={{ padding: '30px' }} src='' alt='' />
                        </div>
                        <div className='row' style={{ textAlign: 'center' }}>
                          <h3 style={{ marginTop: '-50px' }}>Görseller</h3>
                        </div>
                      </div>
                    </div> */}
                    </div>
                  </div>
                  {(
                    <>
                      <div className='row m-t-10'>
                        {state.contentData &&
                          state.contentData
                            .filter(a => a.Location === 3)
                            .map((iPhone, index) => (
                              <div className='col-lg-4 card-mobile-resize m-t-20' key={index}>
                                <div
                                  className='most-popular-products'
                                  style={{
                                    minHeight: '300px',
                                    height: '300px',
                                  }}
                                >
                                  <div className='room'>
                                    <div className='room-image text-center'>
                                      {iPhone.Link ? (
                                        <a href={iPhone.Link} target='_blank' rel='noreferrer'>
                                          <IPImage
                                            src={iPhone.Image}
                                            alt=''
                                            style={{ maxHeight: '125%' }}
                                            className='popular_product'
                                          />
                                        </a>
                                      ) : iPhone.Image ? (
                                        <IPImage
                                          src={iPhone.Image}
                                          alt=''
                                          style={{ maxHeight: '125%' }}
                                          className='popular_product'
                                        />
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                      </div>
                      <br></br>
                    </>
                  )}
                  {/* PAZARLAMA */}
                  {productType && productType === 'Pics' && (
                    <>
                      {state.contentData && (
                        <div className='row m-t-10'>
                          {state.contentData
                            .filter(a => a.Location === 4)
                            .map(item => (
                              <>
                                {item.Link && item.Name ? (
                                  <div
                                    className='col-md-4'
                                    onClick={() =>
                                      downloadFileFromUrl(item.Name, item.Link != '' ? item.Link : item.Image)
                                    }
                                    style={{ cursor: 'pointer', textAlign: 'center' }}
                                  >
                                    {item.Image ? (
                                      <IPImage
                                        className='dpp_V1'
                                        src={item.Image}
                                        alt=''
                                        style={{ objectFit: 'cover', height: '250px' }}
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                ) : item.Image ? (
                                  <div
                                    className='col-md-4'
                                    onClick={() =>
                                      downloadFileFromUrl(item.Name, item.Link != '' ? item.Link : item.Image)
                                    }
                                    style={{ cursor: 'pointer', textAlign: 'center' }}
                                  >
                                    <IPImage
                                      className='dpp_V1'
                                      src={item.Image}
                                      alt=''
                                      style={{ objectFit: 'cover', height: '250px' }}
                                    />
                                  </div>
                                ) : (
                                  ''
                                )}
                              </>
                            ))}
                          <br></br>
                        </div>
                      )}
                    </>
                  )}
                  {productType && productType === 'Docs' && state.contentData && (
                    <div className='row m-t-10'>
                      {state.contentData
                        .filter(a => a.Location === 5)
                        .map(item => (
                          <>
                            <div
                              className='col-md-4 mt-5'
                              onClick={() => downloadFileFromUrl(item.Name, item.Link != '' ? item.Link : item.Image)}
                              style={{ cursor: 'pointer' }}
                            >
                              <button className={`${classes.Button} button buttonWide`}>
                                <span>{item.Name}</span>
                              </button>
                            </div>
                          </>
                        ))}
                      <br></br>
                    </div>
                  )}
                </>
              }
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
    </>
  )
}
export default componentWithContext(Index)
