import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IPosRequest, IPosResponse } from '../models/models'

export class GetFailurePosApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/GetFailurePos',
      requesterInfo,
    })
  }

  public async getFailurePos(request: IPosRequest): Promise<IPosResponse[]> {
    return await this.postAsync<IPosResponse[]>(request)
  }
}
