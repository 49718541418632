/* eslint-disable no-restricted-globals */
import { css } from '@emotion/css'
import { useEffect, useMemo, useState } from 'react'
import Swal from 'sweetalert2'
import IPButton from '../../../components/IPButton'
import IPSelectBox from '../../../components/IPSelectBox'
import { ISelectOption } from '../../../components/IPSelectBox/IPSelectBox'
import { GetCompaniesApi } from '../../../layouts/components/client/services/getCompaniesApi'
import {
  CreditCardPrintPdfDetailRequest,
  CreditCardTransactionsRequest,
  CreditCardTransactionsResponse,
  SendCreditCardCancellationEmailRequest,
} from './creditCardOperationsModel'
import { creditCardOperationsService } from './creditCardOperationsService'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'
import InputCampaign from '../../../components/InputCampaign'
import Table from '../../../components/Table'

function CreditCardOperations(props: BaseComponentWithContextProps) {
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const [transactions, setTransactions] = useState<CreditCardTransactionsResponse[]>([])
  const [optionList, setOptionList] = useState<any>({
    companyOptions: [],
  })
  const [companyValue, setCompanyValue] = useState<ISelectOption | null>(null)
  const getCompanies = async () => {
    const res = await new GetCompaniesApi({}).getCompanyApi()
    if (res) {
      const data: any = []
      data.push({ value: '', label: 'Hepsi' })
      res.forEach(item => {
        if (item.Name === 'Index Group') return
        data.push({ value: item.Code, label: item.Name })
      })
      setOptionList({ companyOptions: data })
    }
  }

  useEffect(() => {
    getCompanies()
    creditCardTransactions()
  }, [])

  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  const dateToISO = date => new Date(new Date(date).getTime() + 3 * 3600 * 1000)

  const popupCenter = ({ url, title, w, h, data }) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height

    const systemZoom = width / window.screen.availWidth
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(
      url,
      title,
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    )
    newWindow?.document.write(data)
    // @ts-ignore
    if (window.focus) newWindow.focus()
  }

  const creditCardTransactions = async () => {
    props.showLoading()
    const req: CreditCardTransactionsRequest = {
      Company: companyValue ? companyValue.value.toString() : '',
      StartDate: dateToISO(startDate),
      EndDate: dateToISO(endDate),
    }
    await new creditCardOperationsService({})
      .creditCardTransactions(req)
      .then(async result => {
        if (result) {
          setTransactions(result)
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
    props.hideLoading()
  }

  const sendCreditCardCancellationEmail = async (docNumber: string, company: string, tkNumber: string) => {
    Swal.fire({
      icon: 'question',
      title: 'İşlemi iptal etmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        const req: CreditCardPrintPdfDetailRequest = {
          Company: company,
          TransactionNumber: docNumber,
          OrderNumber: tkNumber,
          Type: 2,
        }
        try {
          const res = await new creditCardOperationsService({}).sendCreditCardCancellationEmail(req)
          if (res) {
            Swal.fire('Bilgi', 'İptal maili iletilmiştir.', 'info')
            setTransactions(prev =>
              prev.map(x => {
                if (x.DocumentNumber === docNumber) {
                  x.CancelEmail = true
                }
                return x
              })
            )
          }
        } catch (err: any) {
          Swal.fire('Hata', err.description, 'error')
        }
      }
    })
  }

  const creditCardPrintPdfDetail = async (docNumber: string, company: string, tkNumber: string) => {
    const req: CreditCardPrintPdfDetailRequest = {
      Company: company,
      TransactionNumber: docNumber,
      OrderNumber: tkNumber,
      Type: 2,
    }
    try {
      const res = await new creditCardOperationsService({}).creditCardPrintPdfDetail(req)
      popupCenter({ url: '', title: 'Yazdır', w: 900, h: 500, data: res })
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
    }
  }

  const columns = useMemo(
    () => [
      { Header: 'İşlemler', accessor: 'col1', width: 100 },
      { Header: 'İşlem Tarihi', accessor: 'col2' },
      { Header: 'İşlem No', accessor: 'col3', width: 150 },
      { Header: 'İşlem Tipi', accessor: 'col4', width: 165 },
      { Header: 'Banka', accessor: 'col5' },
      { Header: 'Adı Soyadı', accessor: 'col6' },
      { Header: 'Toplam Tutar', accessor: 'col7' },
      { Header: 'Taksit', accessor: 'col8', width: 80 },
      { Header: 'Taksit Tutar', accessor: 'col9' },
      { Header: 'Doküman No', accessor: 'col10' },
      { Header: '3D', accessor: 'col11', width: 70 },
      { Header: 'Kullanıcı Adı', accessor: 'col12' },
      { Header: 'Şirket', accessor: 'col13', width: 165 },
      { Header: 'Silindi', accessor: 'col14', width: 165 },
      //{ Header: 'Sipariş No', accessor: 'col14'},
    ],
    []
  )

  const data = useMemo(
    () =>
      transactions.map((t: CreditCardTransactionsResponse) => ({
        col1: (
          <div className='d-flex gap-1'>
            <div
              className='tableIcon edit'
              title='Yazdır'
              onClick={() => creditCardPrintPdfDetail(t.DocumentNumber, t.Company, t.TransactionNumber)}
            >
              <i className='fa fa-file-pdf'></i>
            </div>
            <div
              className='tableIcon delete'
              title={
                t.CancelEmail && !t.IsDeleted
                  ? 'İşlem iptal Maili Gönderildi'
                  : t.IsDeleted
                    ? 'İşleminiz iptal edilmiştir'
                    : 'İşlemi İptal Et'
              }
              style={{ backgroundColor: t.IsDeleted ? 'gray' : '', cursor: t.IsDeleted ? 'default' : '' }}
              onClick={() => {
                if (t.IsDeleted) {
                  return Swal.fire({
                    icon: 'warning',
                    title: 'Uyarı',
                    text: 'İşleminiz zaten iptal edildi!',
                    confirmButtonText: 'Tamam',
                  })
                }
                if (t.CancelEmail)
                  return Swal.fire({
                    icon: 'warning',
                    title: 'Uyarı',
                    text: 'İptal maili zaten gönderildi! Tekrar göndermek istediğinize emin misiniz?',
                    confirmButtonText: 'Evet',
                    showCancelButton: true,
                    cancelButtonText: 'İptal',
                  }).then(a => {
                    if (a.isConfirmed) {
                      sendCreditCardCancellationEmail(t.DocumentNumber, t.Company, t.TransactionNumber)
                    }
                  })
                sendCreditCardCancellationEmail(t.DocumentNumber, t.Company, t.TransactionNumber)
              }}
            >
              {t.CancelEmail && (
                <span
                  className={css`
                    position: absolute;
                    top: 0;
                    right: -3px;
                    font-size: 12px;
                    line-height: 10px;
                    z-index: 5;
                  `}
                >
                  ✅
                </span>
              )}
              <i className='icon-mail'></i>
            </div>
          </div>
        ),
        col2: new Intl.DateTimeFormat('tr-TR').format(new Date(t.TransactionDate)),
        col3: t.TransactionNumber,
        col4: t.TransactionType,
        col5: t.BankName,
        col6: t.CCName,
        col7: t.TotalPrice,
        col8: t.Installment,
        col9: t.InstallmentPrice,
        col10: t.DocumentNumber,
        col11: t.ThreedPaymentState ? 'Evet' : 'Hayır',
        col12: t.UserName,
        col14: t.IsDeleted ? 'Evet' : 'Hayır',
        col13: optionList.companyOptions.find(com => {
          return +com.value === +t.Company
        })?.label,
        // col14: t.OrderNumber,
      })),
    [transactions]
  )

  const placeholder = (value: string) => (
    <div
      style={{
        color: '#000',
        fontSize: '12px',
        fontWeight: '600',
      }}
    >
      {value}
    </div>
  )

  return (
    <div className='container-fluid'>
      <div className='row mb-3'>
        <div className='col-12 col-md-3 mb-3'>
          <InputCampaign
            type='date'
            id='StartDate'
            name='StartDate'
            label={'Başlangıç Tarihi'}
            value={toDateTimeLocal(startDate)}
            onChange={e => getDate(e.target.value, 'Start')}
            isActive
          />
        </div>
        <div className='col-12 col-md-3 mb-3'>
          <InputCampaign
            type='date'
            id='EndDate'
            name='EndDate'
            label={'Bitiş Tarihi'}
            value={toDateTimeLocal(endDate)}
            onChange={e => getDate(e.target.value, 'End')}
            isActive
          />
        </div>
        <div className='col-12 col-md-3 mb-3'>
          <IPSelectBox
            options={optionList.companyOptions}
            value={companyValue}
            onChangeSingle={option => {
              setCompanyValue(option)
            }}
            placeholder={placeholder('Firma')}
            isClearable
          />
        </div>

        <div className='col-12 col-md-3 mb-3'>
          <IPButton text='Göster' onClick={creditCardTransactions} />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          {transactions.length > 0 ? <Table columns={columns} data={data} currentPage={0} /> : 'Veri bulunamadı.'}
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(CreditCardOperations)
